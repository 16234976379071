import { FC } from 'react';
import { Header } from '../../organisms';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { RootRoutes } from '../../../router';

type DefaultLayoutProps = {
  className?: string;
  children: React.ReactNode;
};

export const DefaultLayout: FC<DefaultLayoutProps> = ({ children, className }) => {
  const { pathname } = useLocation();
  const isHeaderVisible = pathname !== RootRoutes.REPORT;

  return (
    <div className={clsx('flex flex-col h-screen', className)}>
      {isHeaderVisible && <Header />}
      <main className='flex flex-col flex-grow overflow-y-hidden'>{children}</main>
    </div>
  );
};
