import { FC, ReactNode, ReactChild } from 'react';
import { Autocomplete, GoogleMap as ReactGoogleMap, Marker } from '@react-google-maps/api';

// COMPONENTS:
import { Loader } from '../../atoms';

type GoogleMapType = {
  onLoad?: (autocomplete: google.maps.places.Autocomplete) => void;
  onPlaceChanged?: () => void;
  onMarkerChanged?: (e: google.maps.MapMouseEvent) => void;
  visibleMarker?: boolean;
  isLoaded: boolean;
  position?: google.maps.LatLngLiteral;
  input?: ReactNode & ReactChild;
  className?: string;
  draggable?: boolean;
};

export const GoogleMap: FC<GoogleMapType> = ({
  className,
  isLoaded,
  position,
  onLoad,
  onPlaceChanged,
  onMarkerChanged,
  visibleMarker = true,
  draggable = true,
  input,
}) => {
  return isLoaded ? (
    <>
      {input && (
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          {input}
        </Autocomplete>
      )}
      <ReactGoogleMap
        id='searchbox'
        onClick={onMarkerChanged}
        mapContainerClassName={className}
        center={position}
        mapTypeId='hybrid'
        zoom={15}
      >
        {position && (
          <Marker
            visible={visibleMarker}
            onDragEnd={onMarkerChanged}
            draggable={draggable}
            position={position}
          />
        )}
      </ReactGoogleMap>
    </>
  ) : (
    <div className='flex justify-center w-full'>
      <Loader type='Oval' />
    </div>
  );
};
