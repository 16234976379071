import clsx from 'clsx';
import { Field } from 'formik';
import { FC, InputHTMLAttributes, ReactNode } from 'react';

// STYLES
import './radio-button.css';

type RadioButtonType = {
  label: string | ReactNode;
} & InputHTMLAttributes<HTMLInputElement>;

const RadioButton: FC<RadioButtonType> = ({ className, name, label, ...props }) => {
  return (
    <div className={clsx('flex items-center justify-center', className)}>
      <label className='flex items-center text-primary-evinyGreen4 font-secondaryRegular text-body cursor-pointer'>
        <Field
          type='radio'
          name={name}
          {...props}
          className='inline-block mr-2 w-4 h-4 border-2 border-white rounded-full'
        />
        <span
          tabIndex={0}
          className='hidden inline-block mr-2 w-4 h-4 border-2 border-white rounded-full'
        />
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
