import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const LeftArrow: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='14'
      height='25'
      viewBox='0 0 14 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.61947 12.506L13.4832 1.64235C13.8587 1.26673 13.8587 0.657747 13.4832 0.282122C13.1075 -0.0933897 12.4986 -0.0933897 12.1229 0.282122L0.579119 11.8259C0.203607 12.2016 0.203607 12.8105 0.579119 13.1862L12.1229 24.73C12.5051 25.0991 13.1141 25.0885 13.4832 24.7063C13.8432 24.3335 13.8432 23.7425 13.4832 23.3697L2.61947 12.506Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen2}
      />
    </svg>
  );
};
