import { FC } from 'react';
import { IconPropsType } from '../../../types';
import tailwindConfig from '../../../../tailwind.config';

export const SaveEnvironmentIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='135'
      height='126'
      viewBox='0 0 135 126'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M28.2 124.45V115.717L5.15273 88.8289C3.11787 86.4543 1.99959 83.4302 2 80.303V40.3917C2 38.365 2.8051 36.4213 4.23819 34.9882C5.67128 33.5551 7.61497 32.75 9.64167 32.75V32.75C11.6684 32.75 13.6121 33.5551 15.0451 34.9882C16.4782 36.4213 17.2833 38.365 17.2833 40.3917V65.7838'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M54.4036 124.45V94.9418C54.4034 91.4681 53.0236 88.1366 50.5675 85.6801L29.2953 64.4079C28.5785 63.6909 27.7276 63.1222 26.791 62.7342C25.8545 62.3462 24.8507 62.1465 23.8369 62.1465C22.8232 62.1465 21.8194 62.3462 20.8828 62.7342C19.9462 63.1222 19.0953 63.6909 18.3786 64.4079V64.4079C17.6616 65.1246 17.0929 65.9755 16.7049 66.9121C16.3169 67.8487 16.1172 68.8525 16.1172 69.8662C16.1172 70.88 16.3169 71.8838 16.7049 72.8203C17.0929 73.7569 17.6616 74.6078 18.3786 75.3246L34.7536 91.6996'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M106.801 124.45V115.717L129.848 88.8289C131.883 86.4543 133.001 83.4302 133.001 80.303V40.3917C133.001 38.365 132.196 36.4213 130.763 34.9882C129.329 33.5551 127.386 32.75 125.359 32.75C123.332 32.75 121.389 33.5551 119.956 34.9882C118.523 36.4213 117.717 38.365 117.717 40.3917V65.7838'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M80.6016 124.45V94.9418C80.6017 91.4681 81.9816 88.1366 84.4377 85.6801L105.71 64.4079C106.427 63.6909 107.278 63.1222 108.214 62.7342C109.151 62.3462 110.154 62.1465 111.168 62.1465C112.182 62.1465 113.186 62.3462 114.122 62.7342C115.059 63.1222 115.91 63.6909 116.627 64.4079V64.4079C117.344 65.1246 117.912 65.9755 118.3 66.9121C118.688 67.8487 118.888 68.8525 118.888 69.8662C118.888 70.88 118.688 71.8838 118.3 72.8203C117.912 73.7569 117.344 74.6078 116.627 75.3246L100.252 91.6996'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
        strokeWidth='3'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <g clipPath='url(#clip0)'>
        <circle
          cx='65.5'
          cy='30.5'
          r='30.5'
          fill={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M67.5311 38.5004C67.5329 35.5513 68.7047 32.7233 70.7895 30.6373C72.8742 28.5513 75.7014 27.3777 78.6505 27.3741H83.2899C82.8669 30.567 81.2991 33.4978 78.8779 35.6217C76.4566 37.7456 73.3466 38.9182 70.1258 38.9216H67.5311V38.5004ZM64.0872 33.6505H61.4925C58.2722 33.6438 55.1638 32.469 52.7441 30.3442C50.3244 28.2193 48.7579 25.2887 48.3352 22.0963H52.9677C55.9163 22.1017 58.7425 23.2759 60.8268 25.3615C62.9112 27.4471 64.0836 30.274 64.0872 33.2226V33.6505ZM67.5311 57.5944V42.3654H70.1258C74.5565 42.36 78.8043 40.598 81.9379 37.4657C85.0715 34.3334 86.8352 30.0863 86.8424 25.6556V23.9303H78.6505C76.4151 23.9326 74.2102 24.4504 72.2075 25.4435C70.2047 26.4367 68.458 27.8783 67.1031 29.6565C66.3036 26.5148 64.481 23.7286 61.9228 21.7374C59.3646 19.7462 56.2164 18.6632 52.9745 18.6593H44.8234V20.3778C44.8288 24.8008 46.5854 29.0417 49.7091 32.1731C52.8329 35.3044 57.0695 37.0714 61.4925 37.0876H64.0872V57.6623C57.0371 57.354 50.3836 54.3148 45.5345 49.1878C40.6855 44.0608 38.0214 37.2484 38.106 30.192C38.1906 23.1357 41.0174 16.3891 45.988 11.3799C50.9586 6.37073 57.6831 3.49197 64.7386 3.35281C71.7941 3.21365 78.6269 5.82501 83.7912 10.6343C88.9555 15.4436 92.046 22.0735 92.4089 29.121C92.7717 36.1685 90.3783 43.0807 85.7352 48.3949C81.0921 53.7091 74.5635 57.0083 67.5311 57.5944ZM65.3167 0C59.2712 0 53.3614 1.7927 48.3348 5.15141C43.3081 8.51012 39.3903 13.284 37.0768 18.8693C34.7632 24.4546 34.1579 30.6006 35.3373 36.5299C36.5168 42.4593 39.428 47.9057 43.7028 52.1806C47.9776 56.4554 53.4241 59.3666 59.3534 60.546C65.2828 61.7254 71.4287 61.1201 77.014 58.8066C82.5994 56.4931 87.3732 52.5753 90.7319 47.5486C94.0906 42.5219 95.8834 36.6122 95.8834 30.5667C95.8744 22.4626 92.6511 14.6931 86.9207 8.9627C81.1902 3.23228 73.4207 0.0089895 65.3167 0V0Z'
          fill={tailwindConfig.theme.extend.colors.primary.darkBackground}
        />
      </g>
      <defs>
        <clipPath id='clip0'>
          <rect
            width='61.1333'
            height='61.1333'
            fill={tailwindConfig.theme.extend.colors.other.white}
            transform='translate(34.75)'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
