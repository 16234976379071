export default {
  content: ['./src/**/*.{js,jsx,ts,tsx}', './public/index.html'],
  darkMode: 'media', // or 'media' or 'class'
  theme: {
    fill: (theme) => ({
      white: theme('colors.other.white'),
      evinyGreen4: theme('colors.primary.evinyGreen4'),
    }),
    screens: {
      xs: '375px',

      '2xs': '425px',

      sm: '480px',

      '2sm': '560px',

      md: '640px',

      lg: '768px',

      xl: '1024px',

      '2xl': '1280px',

      '3xl': '1440px',

      '4xl': '1580px',

      '5xl': '2560px',
    },
    extend: {
      inset: {
        '11/25': '44%',
      },
      zIndex: {
        '-1': '-1',
        5: 5,
        80: 80,
        100: 100,
        101: 101,
        102: 102,
        103: 103,
        104: 104,
        9999: 9999,
      },
      spacing: {
        8.5: '2.05rem',
        26: '6.5rem',
        112: '28rem',
        120: '30rem',
        160: '40rem',
      },
      maxWidth: {
        10: '2.5rem',
        28: '7rem',
        72: '18rem',
        112: '28rem',
        128: '32rem',
        144: '36rem',
        160: '40rem',
        192: '48rem',
        '1300px': '1300px',
      },
      minWidth: {
        10: '2.5rem',
      },
      maxHeight: {
        '51px': '51px',
      },
      minHeight: {
        '350px': '350px',
        '580px': '580px',
        '660px': '660px',
      },
      boxShadow: {
        standard: '10px 24px 48px rgba(0, 74, 139, 0.3)',
        modal: '0px 0px 48px rgba(0, 0, 0, 0.12)',
        modalButton: '0px 8px 16px 0px rgba(0, 0, 0, 0.1)',
        popover: '0px 0px 16px 5px rgba(255, 255, 255, 0.25)',
      },
      fontSize: {
        largeText1: ['64px', '74px'],
        largeText2: ['50px', '58px'],
        mobileLargeText1: ['42px', '48px'],
        mobileLargeText2: ['36px', '42px'],
        h1: ['32px', '40px'],
        h2: ['24px', '32px'],
        h3: ['20px', '28px'],
        h4: ['18px', '27px'],
        h5: ['16px', '24px'],
        body: ['18px', '27px'],
        inputLabel: ['12px', '14px'],
        menuLink: ['16px', '18px'],
        button: ['16px', '19px'],
      },
      fontFamily: {
        primary: ['Atyp-Display-Medium'],
        secondaryRegular: ['Atyp-Text-Regular'],
        secondaryMedium: ['Atyp-Text-Medium'],
      },
      borderRadius: {
        small: '5px',
        large: '12px',
        extraLarge: '25px',
      },
      borderWidth: {
        1: '1px',
        3: '3px',
      },
      ringOffsetWidth: {
        3: '3px',
      },
      rotate: {
        '-56': '-56deg',
        32: '32deg',
      },
      scale: {
        80: '.8',
        85: '.85',
      },
      keyframes: {
        scale: {
          '0%': { transform: 'scaleX(1)' },
          '100%': { transform: 'scaleX(0)' },
        },
        show: {
          '0%': { opacity: 0, transition: 'opacity 0.5s linear' },
          '100%': { opacity: 1, transition: 'opacity 0.5s linear' },
        },
        collapse: {
          '0%': { opacity: 0.2 },
          '100%': { opacity: 1 },
        },
      },
      animation: {
        'track-progress': 'scale linear 1 forwards',
        show: 'show 0.5s ease-in-out',
        collapse: 'collapse 0.3s ease',
      },
      colors: {
        primary: {
          evinyGreen1: '#76E691',
          evinyGreen2: '#55B077',
          evinyGreen3: '#337A5C',
          evinyGreen4: '#124442',
          lightBackground: '#FAFDFA',
          darkBackground: '#1B5B59',
        },
        status: {
          info: '#004085',
          infoLight: '#cce5ff',
        },
        secondary: {
          evinyCold1: '#E1C3FF',
          evinyCold2: '#554CFD',
          evinyCold3: '#2519FF',
          evinyCold4: '#2C3D5A',
          evinyWarm1: '#FCD300',
          evinyWarm2: '#FCA50D',
          evinyWarm3: '#FC6D19',
          evinyWarm4: '#ED3F26',
        },
        other: {
          black: '#000000',
          white: '#FFFFFF',
          gray: '#666666',
          grayLight: '#F6F6F6',
          errorRed: '#D22417',
          errorRedLight: '#EAA69F', // errorRed with opacity 40%;
          warningOrange: '#FF9900',
        },
      },
    },
  },
  variants: {
    extend: {
      fill: ['hover', 'group-hover'],
      backgroundColor: ['disabled'],
      animation: ['group-hover'],
      transform: ['group-hover'],
      display: ['hover', 'group-hover'],
    },
  },
  plugins: [],
}