import { useState, useEffect } from 'react';

// TYPES:
import { EquipmentScheduleItemType } from '../../store/project-info';

// HOOKS
import useWindowDimensions from './use-window-dimensions';

export type TimelineGroup = {
  id: number;
  title: string;
};

type UseTimelineGroups = {
  timelineGroups: TimelineGroup[];
};

export const useTimelineGroups = (
  equipmentSchedule: EquipmentScheduleItemType[],
  defaultAmountOfGroups: number
): UseTimelineGroups => {
  const [timelineGroups, setTimelineGroups] = useState<TimelineGroup[]>([]);

  const { height } = useWindowDimensions();

  useEffect(() => {
    if (equipmentSchedule) {
      if (equipmentSchedule.length >= defaultAmountOfGroups) {
        setTimelineGroups(
          equipmentSchedule.map((singleEquipment) => {
            return {
              id: singleEquipment.id,
              title: 'Equipment ' + singleEquipment.id,
            } as TimelineGroup;
          })
        );
      } else {
        setTimelineGroups(
          Array.from(Array(defaultAmountOfGroups - 1).keys()).map((index) => {
            return {
              id: index + 1,
              title: 'Equipment ' + (index + 1),
            } as TimelineGroup;
          })
        );
      }
    }
  }, [equipmentSchedule, defaultAmountOfGroups, height]);

  return { timelineGroups };
};
