import Modal from '../../atoms/primitive/Modal';
import { FormattedMessage } from 'react-intl';
import Button from '../../atoms/primitive/Button';
import { FC } from 'react';

type UpdateProjectModalProps = {
  onModalClose: () => void;
  onConfirmButtonClick: () => void;
  isOpen: boolean;
};

export const UpdateProjectModal: FC<UpdateProjectModalProps> = ({
  isOpen,
  onModalClose,
  onConfirmButtonClick,
}) => {
  return (
    <Modal
      withBackground
      isOpen={isOpen}
      onModalClose={onModalClose}
      className='pb-8 pt-16 px-8 w-120'
    >
      <p className='text-primary-evinyGreen4 font-secondaryRegular text-body'>
        <FormattedMessage id='TIMELINE.RESET.CHANGES.MODAL.WARNING' />
      </p>
      <div className='flex justify-between pb-4 pt-8'>
        <Button
          className='now block py-3 w-48 text-primary-evinyGreen4 border-2 border-primary-evinyGreen4'
          variant='secondary'
          onClick={onModalClose}
        >
          <FormattedMessage id='TIMELINE.RESET.CHANGES.MODAL.CANCEL' />
        </Button>
        <Button
          className='block py-3 w-48 text-other-white whitespace-nowrap bg-secondary-evinyCold3'
          variant='primary'
          onClick={onConfirmButtonClick}
        >
          <FormattedMessage id='TIMELINE.RESET.CHANGES.MODAL.CONFIRM' />
        </Button>
      </div>
    </Modal>
  );
};
