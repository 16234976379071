import { useContext, useMemo } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

// STORE
import { useConfigStore } from '../../../store';

// TYPES
import { HeatingStepInterface, ProjectInfoContext } from '../../../store/project-info';
import {
  ConfigActionType,
  DataCarryingFormSteps,
  AdditionalFormSteps,
} from '../../../store/config';

export interface UseHeatingStep {
  formik: FormikProps<HeatingStepInterface>;
  goToNextStep: () => void;
  backToPreviousStep: () => void;
}

export const useHeatingStep = (): UseHeatingStep => {
  const { formatMessage } = useIntl();
  const { projectInfo } = useContext(ProjectInfoContext);
  const { updateConfig } = useConfigStore();

  const validationSchema = Yup.object().shape({
    thermalHeating: Yup.string().required(formatMessage({ id: 'ERROR.OPTION.REQUIRED' })),
  });

  const initialValues = useMemo(
    () => ({
      thermalHeating: projectInfo[DataCarryingFormSteps.HEATING].thermalHeating,
      completed: projectInfo[DataCarryingFormSteps.HEATING].completed,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const formik = useFormik<HeatingStepInterface>({
    initialValues,
    validationSchema,
    onSubmit: () => {
      updateConfig({
        type: ConfigActionType.UPDATE_FORM_STEP,
        payload: AdditionalFormSteps.OVERVIEW,
      });
    },
    validateOnMount: true,
  });

  const goToNextStep = () => {
    formik.submitForm();
  };

  const backToPreviousStep = () => {
    if (formik.isValid) {
      updateConfig({
        type: ConfigActionType.UPDATE_FORM_STEP,
        payload: DataCarryingFormSteps.AREA,
      });
    } else {
      formik.submitForm();
    }
  };

  return { formik, goToNextStep, backToPreviousStep };
};
