import { FC, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { Form, FormikProps, FormikProvider } from 'formik';
import moment from 'moment';

// TRANSLATIONS:
import { FormattedMessage, useIntl } from 'react-intl';

// COMPONENTS:
import { Label } from '../../atoms';
import { Counter, StepHeader, BannerMessage } from '../../molecules';
import DatePicker from '../../molecules/Datepicker';
import Select from '../../molecules/Select';

// TYPES:
import { BannerMessageTypes } from '../../molecules/BannerMessage';

//UTILS:
import { AutosaveListener } from '../../../pages/Guide/utils';

// TYPES:
import {
  DateStepInterface,
  ProjectInfoActionType,
  ProjectInfoContext,
} from '../../../store/project-info';
import { DataCarryingFormSteps } from '../../../store/config';
import { SingleDropdownOptionType } from '../../../types';

// HELPERS:
import { useDebounce } from '../../../helpers/hooks';

// CONSTANTS
import { AVAILABLE_WORKING_HOURS } from '../../../constants';

type DateStepProps = {
  formik: FormikProps<DateStepInterface>;
};

export const DateStep: FC<DateStepProps> = ({ formik }) => {
  const { formatMessage } = useIntl();

  const { values, setFieldValue } = formik;

  const { projectInfo, updateProjectInfo } = useContext(ProjectInfoContext);

  const isWarningVisible = projectInfo.equipmentSchedule.length > 0;

  const debouncedUpdateProjectInfo = useDebounce(updateProjectInfo);

  useEffect(() => {
    debouncedUpdateProjectInfo({
      payload: {
        visibleTimelineEndDate: moment(projectInfo[DataCarryingFormSteps.DATE].startDate)
          .add(10, 'month')
          .endOf('day'),
        projectEndDate: moment(projectInfo[DataCarryingFormSteps.DATE].startDate)
          .add(values.numberOfWeeks, 'weeks')
          .endOf('day'),
      },
      type: ProjectInfoActionType.CheckForEveryTimelineItemOutOfRange,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.numberOfWeeks]);

  return (
    <div className='2xl:pl-3.5'>
      <StepHeader
        className={clsx('pb-6 text-center', 'lg:text-left')}
        label={<FormattedMessage id='GUIDE.DATE.TITLE' />}
        subLabel={<FormattedMessage id='GUIDE.DATE.SUBTITLE' />}
      />
      <div className={clsx('max-w-5xl text-center', 'lg:text-left')}>
        <FormikProvider value={formik}>
          <Form>
            <div className='flex mb-14'>
              <div className={clsx('relative', 'lg:mr-28')}>
                <h3 className='mb-4 text-primary-evinyGreen4 text-h4'>
                  <FormattedMessage id='GUIDE.DATE.DATEPICKER' />
                </h3>
                <Label
                  className={clsx(
                    'flex items-center justify-center mb-2 font-secondaryMedium text-inputLabel',
                    'lg:justify-start'
                  )}
                  htmlFor='startDate'
                >
                  <FormattedMessage id='GUIDE.DATE.DATEPICKER.LABEL' />
                </Label>
                <DatePicker
                  selected={(values.startDate && new Date(values.startDate)) || null}
                  onChange={(
                    val: Date | React.ChangeEvent<HTMLInputElement> | [Date, Date] | null
                  ) => {
                    if (val instanceof Date) {
                      const dateDifference = moment(val).diff(
                        moment(projectInfo[DataCarryingFormSteps.DATE].startDate),
                        'days'
                      );
                      updateProjectInfo({
                        payload: dateDifference,
                        type: ProjectInfoActionType.MoveAllEquipmentsStartTime,
                      });
                      setFieldValue('startDate', val);
                    }
                  }}
                  placeholderText={formatMessage({ id: 'GUIDE.DATE.DATEPICKER.PLACEHOLDER' })}
                  name='startDate'
                  id='startDate'
                  minDate={new Date()}
                />
              </div>
              <div className='relative'>
                <h3 className='mb-4 text-primary-evinyGreen4 text-h4'>
                  <FormattedMessage id='GUIDE.DATE.COUNTER' />
                </h3>
                <Label
                  className={clsx(
                    'flex items-center justify-center mb-2 mt-16 font-secondaryMedium text-inputLabel',
                    'lg:justify-start lg:mt-0'
                  )}
                  htmlFor='numberOfWeeks'
                >
                  <FormattedMessage id='GUIDE.DATE.COUNTER.LABEL' />
                </Label>
                <Counter
                  containerClassName='w-32 h-12'
                  name='numberOfWeeks'
                  id='numberOfWeeks'
                  onDecrementClick={() => {
                    if (values.numberOfWeeks <= 0) return;
                    if (values.numberOfWeeks % 1 === 0) {
                      setFieldValue('numberOfWeeks', values.numberOfWeeks - 1);
                    } else {
                      setFieldValue('numberOfWeeks', Math.floor(values.numberOfWeeks));
                    }
                  }}
                  onIncrementClick={() => {
                    if (values.numberOfWeeks % 1 === 0) {
                      setFieldValue('numberOfWeeks', values.numberOfWeeks + 1);
                    } else {
                      setFieldValue('numberOfWeeks', Math.ceil(values.numberOfWeeks));
                    }
                  }}
                  value={values.numberOfWeeks}
                />
                {isWarningVisible && (
                  <BannerMessage type={BannerMessageTypes.WARNING} className='max-w-xs'>
                    <FormattedMessage id='GUIDE.DATE.CHANGING.PROJECT.DURATION.WARNING' />
                  </BannerMessage>
                )}
              </div>
            </div>

            <h3 className='mb-4 text-primary-evinyGreen4 text-h3'>
              <FormattedMessage id='GUIDE.DATE.WORKING.HOURS.TITLE' />
            </h3>
            <div className='flex'>
              <div className='relative mr-20'>
                <Label
                  htmlFor='startWorkingHour'
                  className='flex items-center justify-start mb-2 font-secondaryMedium text-inputLabel'
                >
                  <FormattedMessage id='GUIDE.DATE.START.WORKING.HOUR.TITLE' />
                </Label>
                <Select
                  showArrow={false}
                  scrollBottom
                  options={Object.values(AVAILABLE_WORKING_HOURS)
                    .map((avaliableWorkingHour) => {
                      if (avaliableWorkingHour !== AVAILABLE_WORKING_HOURS['24:00']) {
                        return {
                          id: avaliableWorkingHour,
                          label: avaliableWorkingHour,
                        } as SingleDropdownOptionType;
                      }
                    })
                    .filter((aviableWorkingHour): aviableWorkingHour is SingleDropdownOptionType =>
                      Boolean(aviableWorkingHour)
                    )}
                  containerClassName='block float-left'
                  name='startWorkingHour'
                  className='px-4 py-3 w-48 text-left text-primary-evinyGreen4 font-secondaryRegular text-body border-b border-primary-evinyGreen4'
                  dropDownClassName='max-h-48 text-primary-evinyGreen4 text-body font-secondaryRegular border-primary-evinyGreen4 rounded leading-10'
                  onChange={(val) => {
                    if (!val) setFieldValue('startWorkingHour', AVAILABLE_WORKING_HOURS['07:00']);
                    else setFieldValue('startWorkingHour', val);
                  }}
                />
              </div>
              <div className='relative mr-20'>
                <Label
                  htmlFor='endWorkingHour'
                  className='flex items-center justify-start mb-2 font-secondaryMedium text-inputLabel'
                >
                  <FormattedMessage id='GUIDE.DATE.END.WORKING.HOUR.TITLE' />
                </Label>
                <Select
                  showArrow={false}
                  scrollBottom
                  options={Object.values(AVAILABLE_WORKING_HOURS)
                    .map((avaliableWorkingHour) => {
                      if (
                        Date.parse(`1/1/2000 ${avaliableWorkingHour}`) >
                          Date.parse(`1/1/2000 ${values.startWorkingHour}`) &&
                        avaliableWorkingHour !== AVAILABLE_WORKING_HOURS['00:00']
                      )
                        return {
                          id: avaliableWorkingHour,
                          label: avaliableWorkingHour,
                        } as SingleDropdownOptionType;
                    })
                    .filter((aviableWorkingHour): aviableWorkingHour is SingleDropdownOptionType =>
                      Boolean(aviableWorkingHour)
                    )}
                  containerClassName='block float-left'
                  name='endWorkingHour'
                  className='px-4 py-3 w-48 text-left text-primary-evinyGreen4 font-secondaryRegular text-body border-b border-primary-evinyGreen4'
                  dropDownClassName='max-h-48 text-primary-evinyGreen4 text-body font-secondaryRegular border-primary-evinyGreen4 rounded leading-10'
                  onChange={(val) => {
                    if (!val) setFieldValue('endWorkingHour', AVAILABLE_WORKING_HOURS['15:00']);
                    else setFieldValue('endWorkingHour', val);
                  }}
                />
              </div>
            </div>
            <AutosaveListener<DateStepInterface> formStep={DataCarryingFormSteps.DATE} />
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};
