import { FC, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { Form, FormikProvider, FormikProps } from 'formik';

// TRANSLATIONS:
import { FormattedMessage } from 'react-intl';

// ICONS:
import { BulbIcon, InputArrowIcon, QuestionIcon } from '../../../assets/images';

// COMPONENTS:
import { Label } from '../../atoms';
import { InputWithUnit, StepHeader, PopOver } from '../../molecules';

// TYPES
import {
  AreaStepInterface,
  ProjectInfoActionType,
  ProjectInfoContext,
} from '../../../store/project-info';
import { DataCarryingFormSteps } from '../../../store/config';

//UTILS
import { AutosaveListener } from '../../../pages/Guide/utils';

type AreaStepProps = {
  formik: FormikProps<AreaStepInterface>;
};

export const AreaStep: FC<AreaStepProps> = ({ formik }) => {
  const { updateProjectInfo } = useContext(ProjectInfoContext);

  const { values } = formik;

  {
    /* there is no required field in this step,
     so we want to force the step update to set this step as completed when user opens that step*/
  }
  useEffect(() => {
    updateProjectInfo({
      payload: values,
      type: ProjectInfoActionType.UpdateStepInfo,
      step: DataCarryingFormSteps.AREA,
    });
  }, [updateProjectInfo, values]);

  return (
    <div className='2xl:pl-3.5'>
      <StepHeader
        className={clsx('pb-6 text-center', 'lg:text-left')}
        label={<FormattedMessage id='GUIDE.AREA.TITLE' />}
        subLabel={<FormattedMessage id='GUIDE.AREA.SUBTITLE' />}
      />
      <div className={clsx('mb-14 max-w-5xl text-center', 'lg:text-left')}>
        <FormikProvider value={formik}>
          <Form>
            <h3
              className={clsx(
                'flex items-center justify-center mb-4 text-primary-evinyGreen4 text-h3',
                'lg:justify-start'
              )}
            >
              <FormattedMessage id='GUIDE.AREA.INPUT.TITLE' />
            </h3>

            <Label htmlFor='areaSize' className='font-secondaryMedium text-inputLabel'>
              <FormattedMessage id='GUIDE.AREA.INPUT.LABEL' />
            </Label>
            <InputWithUnit
              unit='kvm'
              name='areaSize'
              type='number'
              placeholder='000'
              icon={<InputArrowIcon />}
              className='mt-2'
              data-testid='area-input'
            />

            <StepHeader
              className={clsx('pb-6 text-center', 'lg:text-left')}
              label={<FormattedMessage id='GUIDE.AREA.EFFECT.TITLE' />}
              subLabel={<FormattedMessage id='GUIDE.AREA.EFFECT.SUBTITLE' />}
            />

            <div className='flex'>
              <div className='pr-10'>
                <div className='flex mb-2'>
                  <Label
                    className={clsx(
                      'flex items-center justify-center pt-1.5 font-secondaryMedium text-inputLabel',
                      'lg:justify-start'
                    )}
                    htmlFor='intakeEffect'
                  >
                    <FormattedMessage id='GUIDE.AREA.INTAKE.EFFECT.INPUT' />
                  </Label>
                  <PopOver
                    description={
                      <FormattedMessage id='GUIDE.AREA.INTAKE.EFFECT.POPOVER.DESCRIPTION' />
                    }
                    icon={<BulbIcon />}
                    className='relative top-1.5 ml-3'
                    positions={['top']}
                  >
                    <QuestionIcon className='p-0.5' />
                  </PopOver>
                </div>
                <InputWithUnit id='intakeEffect' unit='kW' name='intakeEffect' type='number' />
              </div>

              <div>
                <div className='flex mb-2'>
                  <Label
                    className={clsx(
                      'flex items-center justify-center pt-1.5 font-secondaryMedium text-inputLabel',
                      'lg:justify-start'
                    )}
                    htmlFor='topLoad'
                  >
                    <FormattedMessage id='GUIDE.AREA.TOP.LOAD.INPUT' />
                  </Label>
                  <PopOver
                    icon={<BulbIcon />}
                    description={<FormattedMessage id='GUIDE.AREA.TOP.LOAD.POPOVER.DESCRIPTION' />}
                    className='relative top-1.5 ml-3'
                    positions={['top']}
                  >
                    <QuestionIcon className='p-0.5' />
                  </PopOver>
                </div>
                <InputWithUnit
                  unit='kW'
                  name='topLoad'
                  id='topLoad'
                  type='number'
                  className='text-primary-evinyGreen4 text-h4 bg-primary-lightBackground border border-primary-evinyGreen4 rounded-lg'
                />
              </div>
            </div>

            <AutosaveListener<AreaStepInterface> formStep={DataCarryingFormSteps.AREA} />
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};
