import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const ThumbUpIcon: FC<IconPropsType> = ({
  className,
  color = tailwindConfig.theme.extend.colors.primary.evinyGreen4,
}) => {
  return (
    <svg
      className={className}
      width='23'
      height='24'
      viewBox='0 0 23 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.31641 20.4627L7.69912 21.6718C8.94382 22.2764 10.3308 22.5965 11.7177 22.5965H18.8303C20.0039 22.5965 21.1063 21.743 21.3197 20.4627L22.3866 11.9276C22.3866 10.754 21.4264 9.79382 20.2528 9.79382H14.5628V6.23753C14.5628 4.21044 13.3181 2.00554 11.2198 1.40097C10.7575 1.29428 10.2952 1.61435 10.2952 2.07666V6.23753L5.31641 12.6388'
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M5.31442 10.5049H1.04688V22.5963H5.31442V10.5049Z'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  );
};
