import { useState, useEffect, Dispatch } from 'react';
import { useIntl } from 'react-intl';
import moment, { Moment } from 'moment';

// TYPES:
import {
  ProjectInfoActionType,
  EquipmentScheduleItemType,
  StepActionType,
  PowerType,
} from '../../store/project-info';
import { isEmpty } from 'lodash';

const parseContextToTimelineProps = (items: EquipmentScheduleItemType[]) => {
  return items.map((singleItem) => {
    return {
      id: singleItem.id,
      group: singleItem.id,
      start_time: moment(singleItem.startTime),
      end_time: moment(singleItem.endTime),
      isItemOutdated: singleItem.isItemOutdated,
    };
  });
};

export type TimelineItem = {
  id: number;
  group: number;
  start_time: Moment;
  end_time: Moment;
  isItemOutdated: boolean;
};

type UseTimelineItems = {
  timelineItems: TimelineItem[];
};

export const useTimelineItems = (
  equipmentSchedule: EquipmentScheduleItemType[],
  startDate: string | Date,
  updateProjectInfo: Dispatch<StepActionType>,
  numberOfWeeks: number,
  projectId: string | null
): UseTimelineItems => {
  const { formatMessage } = useIntl();
  const [timelineItems, setTimelineItems] = useState<TimelineItem[]>([]);

  useEffect(() => {
    if (!equipmentSchedule.length && startDate !== '' && isEmpty(projectId)) {
      setTimelineItems([
        {
          id: 1,
          group: 1,
          start_time: moment(startDate),
          end_time: moment(startDate)
            .add(numberOfWeeks < 12 ? numberOfWeeks : 12, 'weeks')
            .endOf('day'),
          isItemOutdated: false,
        },
      ]);

      updateProjectInfo({
        type: ProjectInfoActionType.AddEquipment,
        payload: {
          id: 1,
          startTime: moment(startDate),
          endTime: moment(startDate)
            .add(numberOfWeeks < 12 ? numberOfWeeks : 12, 'weeks')
            .endOf('day'),
          powerType: PowerType.Electric,
          equipmentTypeId: '',
          equipmentModel: {
            id: '',
            equipmentTypeId: '',
            additionalInformation: 0,
            additionalInformationUnit: '',
            powerUsageBattery: null,
            powerUsageGrid: null,
            powerFastCharging: null,
            powerNormalCharging: null,
          },
          numberOfMachines: 1,
          numberOfDaysInUse: 5,
          isItemOutdated: false,
        },
      });
    } else setTimelineItems(parseContextToTimelineProps(equipmentSchedule));
  }, [equipmentSchedule, formatMessage, numberOfWeeks, startDate, updateProjectInfo, projectId]);

  return { timelineItems };
};
