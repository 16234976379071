import { useState, useEffect, useContext, PropsWithChildren } from 'react';
import { useFormikContext } from 'formik';
import isEqual from 'lodash.isequal';

// HELPERS
import { useDebounce } from '../../../helpers/hooks';

// TYPES
import {
  AddressStepInterface,
  AreaStepInterface,
  DateStepInterface,
  ProjectInfoActionType,
  HeatingStepInterface,
  ProjectInfoContext,
  ProjectStepInterface,
  isAddressStepInterface,
  LocationType,
} from '../../../store/project-info';
import {
  locationAddressEmptyObject,
  locationCadastreEmptyObject,
} from '../../../api/project-info/project-info-utils';

export type Props = {
  formStep: number;
};

export const AutosaveListener = <
  T extends
    | ProjectStepInterface
    | DateStepInterface
    | AddressStepInterface
    | AreaStepInterface
    | HeatingStepInterface
>({
  formStep,
}: PropsWithChildren<Props>): JSX.Element | null => {
  const { updateProjectInfo } = useContext(ProjectInfoContext);
  const { values, isValid } = useFormikContext<T>();
  const [lastValues, updateState] = useState(values);
  const valuesEqualLastValues = isEqual(lastValues, values);

  const debouncedUpdateProjectInfo = useDebounce(updateProjectInfo);

  useEffect(() => {
    if (formStep == 2 && isAddressStepInterface(values)) {
      const { locationType } = values;
      if (locationType === LocationType.Address)
        values.locationCadastre = locationCadastreEmptyObject;
      else {
        values.locationAddress = locationAddressEmptyObject;

        // these values return empty string when they are cleared, so we have to transform it to null
        if (values.locationCadastre.leaseNumber === '') values.locationCadastre.leaseNumber = null;

        if (values.locationCadastre.unitNumber === '') {
          values.locationCadastre.unitNumber = null;
        }
      }
    }

    if (!valuesEqualLastValues && isValid) {
      debouncedUpdateProjectInfo({
        payload: values,
        type: ProjectInfoActionType.UpdateStepInfo,
        step: formStep,
      });
      updateState(values);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valuesEqualLastValues, isValid]);

  return null;
};
