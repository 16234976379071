import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const StepperDotIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='8'
      height='8'
      viewBox='0 0 8 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='4.47519'
        cy='4.04995'
        r='3.525'
        transform='rotate(-90 4.47519 4.04995)'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
      />
    </svg>
  );
};
