import { Dispatch } from 'react';

export enum DataCarryingFormSteps {
  PROJECT = 0,
  DATE = 1,
  ADDRESS = 2,
  AREA = 3,
  HEATING = 4,
}

export enum AdditionalFormSteps {
  OVERVIEW = 5,
}

export type FormSteps = DataCarryingFormSteps | AdditionalFormSteps;

export enum ConfigActionType {
  UPDATE_FORM_STEP,
}

export type ConfigAction = { type: ConfigActionType.UPDATE_FORM_STEP; payload: FormSteps };

export type ConfigState = { activeFormStep: FormSteps };

export type ConfigProviderProps = { children: React.ReactNode };

export type ConfigContext = {
  activeFormStep: FormSteps;
  updateConfig: Dispatch<ConfigAction>;
};
