import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const ThumbDownIcon: FC<IconPropsType> = ({
  className,
  color = tailwindConfig.theme.extend.colors.primary.evinyGreen4,
}) => {
  return (
    <svg
      className={className}
      width='24'
      height='23'
      viewBox='0 0 24 23'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.3047 2.77608L15.922 1.56694C14.6773 0.962369 13.2903 0.642303 11.9034 0.642303H4.79078C3.61721 0.642303 2.51475 1.49581 2.30138 2.77608L1.23449 11.3112C1.23449 12.4848 2.19469 13.445 3.36826 13.445H9.05833V17.0012C9.05833 19.0283 10.303 21.2332 12.4012 21.8378C12.8636 21.9445 13.3259 21.6244 13.3259 21.1621V17.0012L18.3047 10.5999'
        stroke={color}
        strokeMiterlimit='10'
      />
      <path
        d='M18.3067 12.7339H22.5742V0.642496H18.3067V12.7339Z'
        stroke={color}
        strokeMiterlimit='10'
        strokeLinecap='square'
      />
    </svg>
  );
};
