import request, { APICall } from '../request';
import { EquipmentModelFromApiType } from './types';

export const getEquipmentTypesList = (): APICall<string[]> =>
  request({
    url: `/equipment/types`,
    method: 'GET',
  });

export const getEquipmentModelsList = (): APICall<EquipmentModelFromApiType[]> =>
  request({
    url: `/equipment`,
    method: 'GET',
  });
