import { Fragment, useRef, FC } from 'react';
import { Dialog, Transition, TransitionEvents } from '@headlessui/react';
import clsx from 'clsx';
import { CloseIcon } from '../../../../assets/images';
import Button from '../Button';

interface ModalProps extends TransitionEvents {
  className?: string;
  withBackground?: boolean;
  isOpen: boolean;
  onModalClose: () => void;
  children: React.ReactNode;
}

const Modal: FC<ModalProps> = ({
  className,
  withBackground,
  isOpen = false,
  onModalClose,
  children,
  ...rest
}) => {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={isOpen} as={Fragment} {...rest}>
      <Dialog
        as='div'
        aria-label='dialog'
        className='fixed z-104 inset-0 overflow-y-auto'
        initialFocus={cancelButtonRef}
        open={isOpen}
        onClose={onModalClose}
      >
        <div className='block items-end justify-center p-0 min-h-screen text-center'>
          <Dialog.Overlay
            className={clsx(
              'fixed inset-0 transition-opacity',
              withBackground && 'opacity-50 bg-black'
            )}
          />

          <span className='inline-block align-middle h-screen' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 translate-y-0 scale-95'
            enterTo='opacity-100 translate-y-0 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 translate-y-0 scale-100'
            leaveTo='opacity-0 translate-y-0 scale-95'
          >
            <div
              className={clsx(
                'inline-block align-middle bg-other-white rounded overflow-hidden transform transition-all',
                className
              )}
            >
              <Button
                onClick={onModalClose}
                className='absolute right-6 top-3 p-2'
                aria-label='close-modal'
              >
                <CloseIcon />
              </Button>
              {children}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
