import { FC } from 'react';
import { IconPropsType } from '../../types';

export const UndoIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='18'
      height='18'
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M0.421875 0.53125C0.175781 0.53125 0 0.742188 0 0.953125V7.70312C0 7.94922 0.175781 8.125 0.421875 8.125H7.17188C7.38281 8.125 7.59375 7.94922 7.59375 7.70312V6.75391C7.59375 6.50781 7.38281 6.33203 7.13672 6.33203L2.70703 6.4375C3.79688 4.04688 6.1875 2.35938 9 2.35938C12.7969 2.35938 15.8906 5.45312 15.8906 9.25C15.8906 13.082 12.7969 16.1406 9 16.1406C7.20703 16.1406 5.625 15.5078 4.39453 14.418C4.21875 14.2773 3.97266 14.2773 3.83203 14.418L3.12891 15.1211C2.95312 15.2969 2.95312 15.5781 3.12891 15.7539C4.67578 17.125 6.75 17.9688 9 17.9688C13.7812 17.9688 17.6836 14.1016 17.7188 9.28516C17.7188 4.50391 13.8164 0.566406 9 0.566406C5.97656 0.53125 3.26953 2.07812 1.72266 4.43359L1.79297 0.988281C1.79297 0.742188 1.61719 0.53125 1.37109 0.53125H0.421875Z' />
    </svg>
  );
};
