import { FC } from 'react';
import clsx from 'clsx';
import { Form, FormikProps, FormikProvider } from 'formik';

// TRANSLATIONS:
import { FormattedMessage, useIntl } from 'react-intl';

// ICONS:
import { BulbIcon, InputArrowIcon, QuestionIcon } from '../../../assets/images';

// COMPONENTS:
import { InputWithLabel } from '../../molecules';
import { StepHeader, RadioGroup, PopOver } from '../../molecules';
import Select from '../../molecules/Select';

// TYPES
import { ProjectStepInterface } from '../../../store/project-info';
import { DataCarryingFormSteps } from '../../../store/config';
import { SingleDropdownOptionType } from '../../../types';

// STYLES
import tailwindConfig from '../../../../tailwind.config';

//UTILS
import { AutosaveListener } from '../../../pages/Guide/utils';

// API
import { useProjectTypeCategories } from '../../../helpers/hooks/use-project-type-categories';
import {
  mapProjectTypeFromString,
  mapProjectTypeToString,
} from '../../../api/project-info/project-info-utils';

type ProjectStepProps = {
  formik: FormikProps<ProjectStepInterface>;
};

export const ProjectStep: FC<ProjectStepProps> = ({ formik }) => {
  const { formatMessage } = useIntl();
  const { values } = formik;

  const { isFetching: isProjectTypesFetching, data: projectTypeCategories } =
    useProjectTypeCategories();

  return (
    <div className='2xl:pl-3.5'>
      <StepHeader
        className={clsx('pb-6 text-center', 'lg:text-left')}
        label={<FormattedMessage id='GUIDE.PROJECT.TITLE' />}
      />
      <div className={clsx('max-w-5xl text-center', 'lg:text-left')}>
        <FormikProvider value={formik}>
          <Form>
            <div className='flex'>
              <h3
                className={clsx(
                  'flex items-center justify-center mb-8 text-primary-evinyGreen4 text-h3',
                  'lg:justify-start'
                )}
              >
                <FormattedMessage id='GUIDE.PROJECT.SUBTITLE' />
              </h3>
              <PopOver
                icon={<BulbIcon />}
                description={<FormattedMessage id='GUIDE.PROJECT.POPOVER.DESCRIPTION' />}
                className='relative top-1.5 ml-3'
              >
                <QuestionIcon />
              </PopOver>
            </div>
            <RadioGroup name='projectType' className='lg:flex'>
              <Select
                options={(projectTypeCategories?.building || [])
                  .map((projectType) => {
                    return {
                      id: mapProjectTypeToString({
                        domain: 'building',
                        type: projectType,
                      }),
                      label: formatMessage({
                        id: `GUIDE.PROJECT.DROPDOWN.${projectType.toUpperCase()}`,
                      }),
                    } as SingleDropdownOptionType;
                  })
                  .filter((projectType): projectType is SingleDropdownOptionType =>
                    Boolean(projectType)
                  )}
                showError={false}
                containerClassName={clsx(
                  'm-auto mt-8 w-64 max-w-max',
                  'lg:inline-block lg:m-0 lg:mr-8'
                )}
                placeholder={formatMessage({ id: 'GUIDE.PROJECT.DROPDOWN.BUILDING.TITLE' })}
                className={clsx(
                  mapProjectTypeFromString(values.projectType).domain === 'construction' ||
                    mapProjectTypeFromString(values.projectType).type === ''
                    ? ' text-primary-evinyGreen4 bg-transparent'
                    : ' text-other-white bg-primary-evinyGreen4',
                  'inline-flex items-center justify-between px-6 py-5 w-64 text-h4 border border-primary-evinyGreen4 rounded-lg'
                )}
                dropDownClassName='mb-8 max-h-48 text-primary-evinyGreen4 text-body font-secondaryRegular border-primary-evinyGreen4 rounded-lg'
                activeArrowColor={tailwindConfig.theme.extend.colors.other.white}
                name='projectType'
                isDataLoading={isProjectTypesFetching}
              />
              <Select
                options={(projectTypeCategories?.construction || [])
                  .map((projectType) => {
                    return {
                      id: mapProjectTypeToString({
                        domain: 'construction',
                        type: projectType,
                      }),
                      label: formatMessage({
                        id: `GUIDE.PROJECT.DROPDOWN.${projectType.toUpperCase()}`,
                      }),
                    } as SingleDropdownOptionType;
                  })
                  .filter((projectType): projectType is SingleDropdownOptionType =>
                    Boolean(projectType)
                  )}
                showError={false}
                containerClassName={clsx('m-auto mt-8 w-64 max-w-max', 'lg:inline-block lg:m-0')}
                placeholder={formatMessage({ id: 'GUIDE.PROJECT.DROPDOWN.CONSTRUCTION.TITLE' })}
                className={clsx(
                  mapProjectTypeFromString(values.projectType).domain === 'building' ||
                    mapProjectTypeFromString(values.projectType).type === ''
                    ? ' text-primary-evinyGreen4 bg-transparent'
                    : ' text-other-white bg-primary-evinyGreen4',
                  'inline-flex items-center justify-between px-6 py-5 w-64 text-h4 border border-primary-evinyGreen4 rounded-lg'
                )}
                dropDownClassName='mb-8 max-h-48 text-primary-evinyGreen4 text-body font-secondaryRegular border-primary-evinyGreen4 rounded-lg'
                activeArrowColor={tailwindConfig.theme.extend.colors.other.white}
                name='projectType'
                isDataLoading={isProjectTypesFetching}
              />
            </RadioGroup>

            <div className='relative'>
              <h3 className='pt-8 text-primary-evinyGreen4 text-h3'>
                <FormattedMessage id='GUIDE.PROJECT.NAME.INPUT.TITLE' />
              </h3>
              <InputWithLabel
                id='projectName'
                name='projectName'
                label={<FormattedMessage id='GUIDE.PROJECT.NAME.INPUT.LABEL' />}
                placeholder={formatMessage({ id: 'GUIDE.PROJECT.NAME.INPUT.PLACEHOLDER' })}
                icon={<InputArrowIcon />}
              />
            </div>

            <AutosaveListener<ProjectStepInterface> formStep={DataCarryingFormSteps.PROJECT} />
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};
