import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const UpArrow: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='18'
      height='10'
      viewBox='0 0 18 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17 8.28166L9 1L1 8.28166'
        stroke={tailwindConfig.theme.extend.colors.other.white}
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
