import { useIntl } from 'react-intl';
import PowerCalculations from '../../../assets/images/report/powerCalculations.png';
import Installation from '../../../assets/images/report/installation.png';
import FastCharging from '../../../assets/images/report/fastCharging.png';
import Heat from '../../../assets/images/report/heat.png';
import Training from '../../../assets/images/report/training.png';
import Normallading from '../../../assets/images/report/normallading.png';
import Data from '../../../assets/images/report/data.png';
import Battery from '../../../assets/images/report/battery.png';

export type ItemType = {
  id: number;
  photo: string;
  title: string;
  description: string;
};

export const useFlexibleSolutions = () => {
  const { formatMessage } = useIntl();

  const numberOfListElements = 8;

  const flexibleSolutionsList = [];

  for (let i = 1; i <= numberOfListElements; i++) {
    const element = formatMessage({ id: `REPORT.FLEXIBLE.SOLUTIONS.LIST.ELEMENT${i}` });
    flexibleSolutionsList.push(element);
  }

  const availableItems: ItemType[] = [
    {
      id: 1,
      photo: PowerCalculations,
      title: formatMessage({ id: 'REPORT.POWER.CALCULATIONS.HEADER' }),
      description: formatMessage({ id: 'REPORT.POWER.CALCULATIONS.DESCRIPTION' }),
    },
    {
      id: 2,
      photo: Installation,
      title: formatMessage({ id: 'REPORT.INSTALLATION.HEADER' }),
      description: formatMessage({ id: 'REPORT.INSTALLATION.DESCRIPTION' }),
    },
    {
      id: 3,
      photo: FastCharging,
      title: formatMessage({ id: 'REPORT.FAST.CHARGING.HEADER' }),
      description: formatMessage({ id: 'REPORT.FAST.CHARGING.DESCRIPTION' }),
    },
    {
      id: 4,
      photo: Heat,
      title: formatMessage({ id: 'REPORT.HEAT.HEADER' }),
      description: formatMessage({ id: 'REPORT.HEAT.DESCRIPTION' }),
    },
    {
      id: 5,
      photo: Training,
      title: formatMessage({ id: 'REPORT.TRAINING.HEADER' }),
      description: formatMessage({ id: 'REPORT.TRAINING.DESCRIPTION' }),
    },
    {
      id: 6,
      photo: Normallading,
      title: formatMessage({ id: 'REPORT.NORMALLADING.HEADER' }),
      description: formatMessage({ id: 'REPORT.NORMALLADING.DESCRIPTION' }),
    },
    {
      id: 7,
      photo: Data,
      title: formatMessage({ id: 'REPORT.DATA.HEADER' }),
      description: formatMessage({ id: 'REPORT.DATA.DESCRIPTION' }),
    },
    {
      id: 8,
      photo: Battery,
      title: formatMessage({ id: 'REPORT.BATTERY.HEADER' }),
      description: formatMessage({ id: 'REPORT.BATTERY.DESCRIPTION' }),
    },
  ];

  return { flexibleSolutionsList, availableItems };
};
