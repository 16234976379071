import { useState, useEffect } from 'react';

// TYPES:
import { EquipmentScheduleItemType } from '../../store/project-info';

export type TimelineGroup = {
  id: number;
  title: string;
};

type UseTimelineGroups = {
  timelineGroups: TimelineGroup[];
};

export const useTimelineGroupsForTheReport = (
  equipmentSchedule: EquipmentScheduleItemType[],
  defaultAmountOfGroups: number
): UseTimelineGroups => {
  const [timelineGroups, setTimelineGroups] = useState<TimelineGroup[]>([]);

  useEffect(() => {
    if (equipmentSchedule) {
      if (equipmentSchedule.length >= defaultAmountOfGroups) {
        setTimelineGroups(
          equipmentSchedule.map((singleEquipment) => {
            return {
              id: singleEquipment.id,
              title: 'Equipment ' + singleEquipment.id,
            } as TimelineGroup;
          })
        );
      } else {
        setTimelineGroups(
          Array.from(Array(defaultAmountOfGroups - 1).keys()).map((index) => {
            return {
              id: index + 1,
              title: 'Equipment ' + (index + 1),
            } as TimelineGroup;
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { timelineGroups };
};
