import { FormattedMessage } from 'react-intl';
import tailwindConfig from '../../../tailwind.config';
import { MobilEnergiLogo } from '../../assets/images';
import { MENU_URLS } from '../../constants';

export const ProjectErrorHeader = () => {
  return (
    <div className='px-28 py-20 bg-other-white'>
      <div className='mx-auto max-w-1300px'>
        {/* Project details header */}
        <div className='flex flex-col text-primary-evinyGreen4'>
          <div className='flex justify-between'>
            <a href={MENU_URLS.LANDING_HOMEPAGE} aria-label='Open main website'>
              <MobilEnergiLogo color={tailwindConfig.theme.extend.colors.primary.evinyGreen4} />
            </a>
            <div className='inline-block align-middle'>
              <h1 className='text-primary-evinyGreen2 text-largeText1'>
                <FormattedMessage id='REPORT' />
              </h1>
            </div>
          </div>
          <div className='pt-14'>
            <h1 className='text-primary-evinyGreen4 text-h1'>
              <FormattedMessage id='REPORT.ERROR.NOT.VALID.UUID' />
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
};
