import React from 'react';
import tailwindConfig from '../../../../tailwind.config';

// CONSTANTS:
import { MENU_URLS } from '../../../constants';

// ICONS:
import { MobilEnergiLogo } from '../../../assets/images';

export const LogoLabel = () => {
  return (
    <div className={'self-start'}>
      <a
        href={MENU_URLS.LANDING_HOMEPAGE}
        aria-label='Open main website'
        target='_blank'
        rel='noreferrer'
      >
        <div className='text-primary-evinyGreen4 text-h5'>
          <MobilEnergiLogo color={tailwindConfig.theme.extend.colors.primary.evinyGreen4} />
        </div>
      </a>
    </div>
  );
};
