import { FC, ReactNode } from 'react';

// UTILS:
import clsx from 'clsx';

type StepHeaderProps = {
  label: ReactNode | string;
  subLabel?: ReactNode | string;
  className?: string;
};

export const StepHeader: FC<StepHeaderProps> = ({ className, label, subLabel }) => {
  return (
    <div className={clsx('lg:flex', className)}>
      <div className='pt-11'>
        <h2 className='block text-primary-evinyGreen4 text-h2'>{label}</h2>
        <p
          className={clsx(
            ' block pt-5 text-primary-evinyGreen4 font-secondaryRegular text-body',
            !subLabel && 'hidden'
          )}
        >
          {subLabel}
        </p>
      </div>
    </div>
  );
};
