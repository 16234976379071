import { FC } from 'react';
import clsx from 'clsx';
import { FormikProps, FormikProvider } from 'formik';

// STYLES:
import './address-step.css';
import tailwindConfig from '../../../../tailwind.config';

// TRANSLATIONS:
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import { StepHeader } from '../../molecules';
import ConstructionTypeField from './ConstructionTypeField';
import AddressFields from './AddressFields';
import CadastreInformationFields from './CadastreInformationFields';
import { Loader } from '../../atoms';

// TYPES
import { AddressStepInterface, LocationType } from '../../../store/project-info';
import { DataCarryingFormSteps } from '../../../store/config';

//UTILS
import { AutosaveListener } from '../../../pages/Guide/utils';

// HOOKS
import { usePosition } from '../../../helpers/hooks';

type AddressStepProps = {
  formik: FormikProps<AddressStepInterface>;
};

export const AddressStep: FC<AddressStepProps> = ({ formik }) => {
  const { values } = formik;

  const { isUserPositionLoaded } = usePosition(values.locationAddress.position, formik);

  return (
    <div className='2xl:pl-3.5'>
      <StepHeader
        className={clsx('pb-6 text-center', 'lg:text-left')}
        label={<FormattedMessage id='GUIDE.ADDRESS.TITLE' />}
        subLabel={<FormattedMessage id='GUIDE.ADDRESS.SUBTITLE' />}
      />
      <div className={clsx('max-w-5xl text-center', 'lg:text-left')}>
        <FormikProvider value={formik}>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <ConstructionTypeField />

            {values.locationType === LocationType.Address && (
              <>
                {isUserPositionLoaded ? (
                  <AddressFields />
                ) : (
                  <div className='flex justify-center w-full'>
                    <Loader
                      type='Oval'
                      color={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
                    />
                  </div>
                )}
              </>
            )}
            {values.locationType === LocationType.Cadastre && <CadastreInformationFields />}
          </form>

          <AutosaveListener<AddressStepInterface> formStep={DataCarryingFormSteps.ADDRESS} />
        </FormikProvider>
      </div>
    </div>
  );
};
