import { FC } from 'react';
import { IconPropsType } from '../../../types';
import tailwindConfig from '../../../../tailwind.config';

export const BrakkeEquipmentIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='25'
      height='25'
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M57.7901 7H6.20014C5.7769 7.0013 5.3581 7.08629 4.96784 7.25006C4.57757 7.41384 4.22354 7.65319 3.92613 7.95431C3.62872 8.25544 3.3938 8.61241 3.23488 9.00468C3.07596 9.39695 2.99618 9.81678 3.00014 10.24V53.37C3.00278 54.2179 3.34077 55.0303 3.94031 55.6298C4.53986 56.2294 5.35226 56.5674 6.20014 56.57H57.7901C58.638 56.5674 59.4504 56.2294 60.05 55.6298C60.6495 55.0303 60.9875 54.2179 60.9901 53.37V10.24C60.9941 9.81678 60.9143 9.39695 60.7554 9.00468C60.5965 8.61241 60.3616 8.25544 60.0641 7.95431C59.7667 7.65319 59.4127 7.41384 59.0224 7.25006C58.6322 7.08629 58.2134 7.0013 57.7901 7V7ZM59.0001 10.24V30.79H33.0001V9H57.7901C57.952 8.99856 58.1125 9.02988 58.2619 9.09207C58.4113 9.15426 58.5466 9.24604 58.6597 9.36188C58.7727 9.47772 58.8611 9.61523 58.9197 9.76614C58.9782 9.91705 59.0055 10.0782 59.0001 10.24V10.24ZM6.20014 9H31.0001V30.79H5.00014V10.24C4.99477 10.0791 5.02185 9.91873 5.07974 9.76849C5.13764 9.61825 5.22518 9.4812 5.33715 9.36549C5.44912 9.24979 5.58323 9.15781 5.73149 9.09502C5.87975 9.03222 6.03913 8.99991 6.20014 9V9ZM5.00014 53.37V32.79H31.0001V54.57H6.20014C5.88188 54.57 5.57666 54.4436 5.35161 54.2185C5.12657 53.9935 5.00014 53.6883 5.00014 53.37V53.37ZM57.7901 54.57H33.0001V32.79H59.0001V53.37C59.0001 53.5284 58.9688 53.6853 58.9078 53.8315C58.8469 53.9778 58.7576 54.1105 58.6451 54.2221C58.5326 54.3336 58.3992 54.4218 58.2524 54.4815C58.1057 54.5413 57.9486 54.5713 57.7901 54.57V54.57Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M16.4298 35.8H8.0498C7.78459 35.8 7.53023 35.9053 7.3427 36.0929C7.15516 36.2804 7.0498 36.5348 7.0498 36.8V44.8C7.0498 45.0652 7.15516 45.3196 7.3427 45.5071C7.53023 45.6946 7.78459 45.8 8.0498 45.8H16.4298C16.695 45.8 16.9494 45.6946 17.1369 45.5071C17.3244 45.3196 17.4298 45.0652 17.4298 44.8V36.8C17.4298 36.5348 17.3244 36.2804 17.1369 36.0929C16.9494 35.9053 16.695 35.8 16.4298 35.8ZM15.4298 43.8H9.0498V37.8H15.4298V43.8Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M27.84 35.8H19.46C19.1947 35.8 18.9404 35.9053 18.7529 36.0929C18.5653 36.2804 18.46 36.5348 18.46 36.8V44.8C18.46 45.0652 18.5653 45.3196 18.7529 45.5071C18.9404 45.6946 19.1947 45.8 19.46 45.8H27.84C28.1052 45.8 28.3595 45.6946 28.5471 45.5071C28.7346 45.3196 28.84 45.0652 28.84 44.8V36.8C28.84 36.6687 28.8141 36.5386 28.7638 36.4173C28.7136 36.296 28.6399 36.1857 28.5471 36.0929C28.4542 36 28.344 35.9264 28.2226 35.8761C28.1013 35.8259 27.9713 35.8 27.84 35.8ZM26.84 43.8H20.46V37.8H26.84V43.8Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M8.0498 22.02H16.4298C16.695 22.02 16.9494 21.9146 17.1369 21.7271C17.3244 21.5396 17.4298 21.2852 17.4298 21.02V13.02C17.4298 12.7548 17.3244 12.5004 17.1369 12.3129C16.9494 12.1253 16.695 12.02 16.4298 12.02H8.0498C7.78459 12.02 7.53023 12.1253 7.3427 12.3129C7.15516 12.5004 7.0498 12.7548 7.0498 13.02V21.02C7.0498 21.2852 7.15516 21.5396 7.3427 21.7271C7.53023 21.9146 7.78459 22.02 8.0498 22.02ZM9.0498 14.02H15.4298V20.02H9.0498V14.02Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M19.46 22.02H27.84C28.1052 22.02 28.3595 21.9146 28.5471 21.7271C28.7346 21.5396 28.84 21.2852 28.84 21.02V13.02C28.84 12.7548 28.7346 12.5004 28.5471 12.3129C28.3595 12.1253 28.1052 12.02 27.84 12.02H19.46C19.1947 12.02 18.9404 12.1253 18.7529 12.3129C18.5653 12.5004 18.46 12.7548 18.46 13.02V21.02C18.46 21.2852 18.5653 21.5396 18.7529 21.7271C18.9404 21.9146 19.1947 22.02 19.46 22.02ZM20.46 14.02H26.84V20.02H20.46V14.02Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M44.59 35.8H36.21C35.9447 35.8 35.6904 35.9053 35.5029 36.0929C35.3153 36.2804 35.21 36.5348 35.21 36.8V44.8C35.21 45.0652 35.3153 45.3196 35.5029 45.5071C35.6904 45.6946 35.9447 45.8 36.21 45.8H44.59C44.8552 45.8 45.1095 45.6946 45.2971 45.5071C45.4846 45.3196 45.59 45.0652 45.59 44.8V36.8C45.59 36.5348 45.4846 36.2804 45.2971 36.0929C45.1095 35.9053 44.8552 35.8 44.59 35.8ZM43.59 43.8H37.21V37.8H43.59V43.8Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M56.0001 35.8H47.6201C47.3549 35.8 47.1005 35.9053 46.913 36.0929C46.7255 36.2804 46.6201 36.5348 46.6201 36.8V44.8C46.6201 45.0652 46.7255 45.3196 46.913 45.5071C47.1005 45.6946 47.3549 45.8 47.6201 45.8H56.0001C56.2653 45.8 56.5197 45.6946 56.7072 45.5071C56.8948 45.3196 57.0001 45.0652 57.0001 44.8V36.8C57.0001 36.5348 56.8948 36.2804 56.7072 36.0929C56.5197 35.9053 56.2653 35.8 56.0001 35.8ZM55.0001 43.8H48.6201V37.8H55.0001V43.8Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M36.21 22.02H44.59C44.8552 22.02 45.1095 21.9146 45.2971 21.7271C45.4846 21.5396 45.59 21.2852 45.59 21.02V13.02C45.59 12.7548 45.4846 12.5004 45.2971 12.3129C45.1095 12.1253 44.8552 12.02 44.59 12.02H36.21C35.9447 12.02 35.6904 12.1253 35.5029 12.3129C35.3153 12.5004 35.21 12.7548 35.21 13.02V21.02C35.21 21.2852 35.3153 21.5396 35.5029 21.7271C35.6904 21.9146 35.9447 22.02 36.21 22.02ZM37.21 14.02H43.59V20.02H37.21V14.02Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M47.6201 22.02H56.0001C56.2653 22.02 56.5197 21.9146 56.7072 21.7271C56.8948 21.5396 57.0001 21.2852 57.0001 21.02V13.02C57.0001 12.7548 56.8948 12.5004 56.7072 12.3129C56.5197 12.1253 56.2653 12.02 56.0001 12.02H47.6201C47.3549 12.02 47.1005 12.1253 46.913 12.3129C46.7255 12.5004 46.6201 12.7548 46.6201 13.02V21.02C46.6201 21.2852 46.7255 21.5396 46.913 21.7271C47.1005 21.9146 47.3549 22.02 47.6201 22.02ZM48.6201 14.02H55.0001V20.02H48.6201V14.02Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
    </svg>
  );
};
