import { useContext, useMemo, useRef } from 'react';
import clsx from 'clsx';
import { PopOver } from '../../components/molecules';
import { FormattedMessage } from 'react-intl';
import { BulbIcon, QuestionIcon } from '../../assets/images';
import tailwindConfig from '../../../tailwind.config';
import 'react-calendar-timeline/lib/Timeline.css';
import '../../components/organisms/Timeline/timeline.css';
import {
  useMaxPotentialChartData,
  useMaxRequiredPowerChartData,
  useTheMaxEffectPeak,
} from '../../helpers/hooks';
import { parseProjectInfoData } from '../../api/project-info/project-info-utils';
import { PotentialEffectChart } from '../../components/organisms/PotentialEffectChart';
import { Loader } from '../../components/atoms';

// COMPONENTS
import { parseEquipmentModelOptions } from '../../api/equipment/equipment-utils';
import { TimelineForReport } from './TimelineForReport';
import { DailyReportChart } from '../../components/organisms';
import { ProjectInfoContext } from '../../store/project-info';
import { DataCarryingFormSteps } from '../../store/config';

export const ChartAndTimelineSection = () => {
  const timelineRef = useRef<HTMLDivElement>(null);

  const { projectInfo, equipmentModels, contactUserData } = useContext(ProjectInfoContext);

  const parsedProjectInfoData = useMemo(
    () => parseProjectInfoData(contactUserData, projectInfo, equipmentModels),
    [contactUserData, equipmentModels, projectInfo]
  );

  const { data: chartSeriesData, isFetching: isMaxPotentialEffectFetching } =
    useMaxPotentialChartData(parsedProjectInfoData);

  const { data: powerChartSeriesData, isFetching: isMaxRequiredPowerFetching } =
    useMaxRequiredPowerChartData(parsedProjectInfoData);

  const modelOptions = parseEquipmentModelOptions(equipmentModels);

  const { data: maxPotentialEffect } = useTheMaxEffectPeak(parsedProjectInfoData);

  return (
    <div className='px-28 py-20 bg-primary-darkBackground'>
      <div className='mx-auto max-w-1300px'>
        <div className={clsx('flex flex-row items-center justify-between', '2xl:items-end')}>
          <div className='pr-20'>
            <h2 className='pt-14 text-h2'>
              <FormattedMessage id='REPORT.MAX.EFFECT.HEADER' />
            </h2>
            <div className='inline-block pt-4 font-secondaryRegular text-body'>
              <FormattedMessage id='REPORT.MAX.EFFECT.DESCRIPTION.FIRST.LINE' />
              <span className='relative inline pl-2 text-secondary-evinyWarm1'>
                {maxPotentialEffect?.toFixed()} kW
                <PopOver
                  description={
                    <FormattedMessage id='PROJECT.DETAILS.MAX.EFFECT.POPOVER.DESCRIPTION' />
                  }
                  icon={<BulbIcon />}
                  className='absolute -right-6 top-0 ml-1'
                >
                  <QuestionIcon
                    className='px-0.5'
                    color={tailwindConfig.theme.extend.colors.other.white}
                  />
                </PopOver>
              </span>
            </div>

            <p className='pt-3 font-secondaryRegular text-body'>
              <FormattedMessage id='REPORT.MAX.EFFECT.DESCRIPTION.SECOND.LINE' />
            </p>
          </div>

          <div className='px-8 py-6 min-w-max text-center text-secondary-evinyWarm1 text-h1 bg-secondary-evinyCold4 rounded-xl'>
            {maxPotentialEffect?.toFixed(0)} kW
          </div>
        </div>

        {/* CHART */}
        <div className='mt-14 p-8 min-h-660px bg-other-white border-3 border-primary-evinyGreen3 rounded-3xl'>
          <h2 className='pl-2 pt-2 text-primary-evinyGreen4 text-h2'>
            <FormattedMessage id='REPORT.MAX.EFFECT.CHART.HEADER' />
          </h2>
          <div className='relative mt-6 pl-8 h-3'>
            <p className='absolute text-primary-evinyGreen4 font-secondaryRegular text-body'>kW</p>
          </div>
          {isMaxPotentialEffectFetching || !chartSeriesData ? (
            <div className='flex justify-center w-full'>
              <Loader type='Oval' color={tailwindConfig.theme.extend.colors.primary.evinyGreen4} />
            </div>
          ) : (
            <PotentialEffectChart chartSeriesData={chartSeriesData} />
          )}
        </div>

        <p className='py-16 font-secondaryRegular text-body'>
          <FormattedMessage id='REPORT.TIMELINE' />
        </p>

        <TimelineForReport
          projectInfo={projectInfo}
          equipmentSchedule={projectInfo.equipmentSchedule}
          timelineRef={timelineRef}
          modelOptions={modelOptions}
        />

        <h2 className='pb-4 pt-14 text-h2'>
          <FormattedMessage id='REPORT.DAILY.REPORTS.HEADER' />
        </h2>
        <p className='font-secondaryRegular text-body'>
          <FormattedMessage
            id='REPORT.DAILY.REPORTS.DESCRIPTION'
            values={{ projectName: projectInfo[DataCarryingFormSteps.PROJECT].projectName }}
          />
        </p>
        <div className={clsx('grid grid-cols-1', '2xl:gap-x-6 2xl:grid-cols-2')}>
          <div>
            <div className='mt-14 p-8 max-w-screen-2xl min-h-580px bg-other-white border-3 border-primary-evinyGreen3 rounded-3xl'>
              <h2 className='pl-2 pt-2 text-primary-evinyGreen4 text-h2'>
                <FormattedMessage
                  id='REPORT.DAILY.REPORT.MAX.POWER.HEADER'
                  values={{
                    dayValue: powerChartSeriesData ? powerChartSeriesData.peakPower.day : '-',
                  }}
                />
              </h2>
              <div className='relative mt-6 pl-8 h-3'>
                <p className='absolute text-primary-evinyGreen4 font-secondaryRegular text-body'>
                  kW
                </p>
              </div>
              {!powerChartSeriesData || isMaxRequiredPowerFetching ? (
                <div className='flex justify-center w-full'>
                  <Loader
                    type='Oval'
                    color={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
                  />
                </div>
              ) : (
                <DailyReportChart chartData={powerChartSeriesData.peakPower} />
              )}
            </div>
            <div className='flex flex-col items-center mt-4 py-8 bg-secondary-evinyCold4 rounded-2xl'>
              <span className='text-center text-primary-evinyGreen1 text-h3'>
                <FormattedMessage
                  id='REPORT.DAILY.REPORT.MAX.POWER.INFO'
                  values={{
                    value: (
                      <p className='py-4 text-secondary-evinyWarm1 text-h1'>
                        {powerChartSeriesData
                          ? powerChartSeriesData.peakPower.value.toFixed()
                          : '-'}{' '}
                        kW
                      </p>
                    ),
                    weekValue: powerChartSeriesData ? powerChartSeriesData.peakPower.week : '-',
                  }}
                />
              </span>
            </div>
          </div>

          <div>
            <div className='mt-14 p-8 max-w-screen-2xl min-h-580px bg-other-white border-3 border-primary-evinyGreen3 rounded-3xl'>
              <h2 className='pl-2 pt-2 text-primary-evinyGreen4 text-h2'>
                <FormattedMessage
                  id='REPORT.DAILY.REPORT.MAX.TOTAL.POWER.HEADER'
                  values={{
                    dayValue: powerChartSeriesData ? powerChartSeriesData.peakEnergy.day : '-',
                  }}
                />
              </h2>
              <div className='relative mt-6 pl-8 h-3'>
                <p className='absolute text-primary-evinyGreen4 font-secondaryRegular text-body'>
                  kW
                </p>
              </div>
              {!powerChartSeriesData || isMaxRequiredPowerFetching ? (
                <div className='flex justify-center w-full'>
                  <Loader
                    type='Oval'
                    color={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
                  />
                </div>
              ) : (
                <DailyReportChart chartData={powerChartSeriesData.peakEnergy} chartType='area' />
              )}
            </div>
            <div className='flex flex-col items-center mt-4 py-8 bg-secondary-evinyCold4 rounded-2xl'>
              <span className='text-center text-primary-evinyGreen1 text-h3'>
                <FormattedMessage
                  id='REPORT.DAILY.REPORT.MAX.TOTAL.POWER.INFO'
                  values={{
                    value: (
                      <p className='py-4 text-secondary-evinyWarm1 text-h1'>
                        {powerChartSeriesData
                          ? powerChartSeriesData.peakEnergy.value.toFixed()
                          : '-'}{' '}
                        kWh
                      </p>
                    ),
                    weekValue: powerChartSeriesData ? powerChartSeriesData.peakEnergy.week : '-',
                  }}
                />
              </span>
            </div>
          </div>
        </div>

        <p className='pt-14 font-secondaryRegular text-body'>
          <FormattedMessage id='REPORT.DAILY.REPORTS.BELOW.TEXT' />
        </p>
      </div>
    </div>
  );
};
