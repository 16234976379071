import { FC } from 'react';
import clsx from 'clsx';
import { ErrorMessage } from 'formik';

type RadioGroupType = {
  className?: string;
  onChange?: () => void;
  name: string;
  children: React.ReactNode;
};

export const RadioGroup: FC<RadioGroupType> = ({ children, className, onChange, name }) => {
  return (
    <div className={clsx('relative', className)} role='group' onChange={onChange}>
      {children}
      <ErrorMessage
        component='div'
        className='absolute bottom-0 left-0 items-center -mb-4 text-other-errorRed whitespace-nowrap text-inputLabel'
        name={name}
      />
    </div>
  );
};
