import clsx from 'clsx';

// ICONS:
import { ElectricEquipmentIcon } from '../../../assets/images';

// HELPERS:
import {
  defineTimelineItemStyle,
  getGreatestValueFromArray,
  getLabelFromId,
} from '../../../helpers/functions';

// TYPES:
import { PowerType } from '../../../store/project-info';
import { EQUIPMENT_TYPE } from '../../../constants';
import { TimelineItemComponent } from '../../../types';

// STYLES:
import tailwindConfig from '../../../../tailwind.config';
import './timeline-item-with-shorten-name.css';
import { FormattedMessage } from 'react-intl';

export const TimelineItemWithShortenName: TimelineItemComponent = (
  itemRendererProps,
  singleTimelineItem,
  modelOptions,
  defaultTitle
) => {
  const { itemContext, getItemProps } = itemRendererProps;
  const isItemOtherType = singleTimelineItem.equipmentTypeId === EQUIPMENT_TYPE.OTHER;
  const isItemElectricPowerType = singleTimelineItem.powerType === PowerType.Electric;
  const isItemDefined = Boolean(singleTimelineItem.equipmentTypeId);
  const { style, equipmentIcon, leftResizeProps, rightResizeProps } = defineTimelineItemStyle(
    singleTimelineItem,
    itemRendererProps
  );

  const equipmentTitle = getLabelFromId(
    modelOptions,
    singleTimelineItem.equipmentModel.id,
    defaultTitle
  );

  return (
    <div
      {...getItemProps({
        style,
      })}
      className={clsx(
        'container',
        !itemContext.selected && ' hover:shadow-md',
        'tooltip-container flex transition-all duration-75 ease-linear'
      )}
    >
      {isItemDefined && (
        <div className='tooltip animate-show'>
          {isItemOtherType || !isItemElectricPowerType
            ? singleTimelineItem.equipmentModel.id
            : equipmentTitle}
        </div>
      )}
      <div {...leftResizeProps} />
      <div
        className={clsx(
          'flex',
          isItemOtherType || !isItemDefined ? 'items-start' : 'justify-between'
        )}
      >
        <div className='col-1 cell'>
          <div
            className={clsx(
              singleTimelineItem.isItemOutdated
                ? 'bg-other-errorRed bg-opacity-40'
                : isItemDefined
                ? 'bg-other-white'
                : 'bg-other-gray bg-opacity-20',
              'relative ml-1 mr-3 mt-1 min-w-10 max-w-10 h-10 rounded-full'
            )}
          >
            {isItemElectricPowerType && (
              <ElectricEquipmentIcon
                className='absolute z-30 right-0'
                color={
                  singleTimelineItem.isItemOutdated
                    ? tailwindConfig.theme.extend.colors.other.white
                    : tailwindConfig.theme.extend.colors.primary.evinyGreen4
                }
              />
            )}
            {equipmentIcon}
          </div>
        </div>
        <div
          className={clsx(
            isItemOtherType || !isItemElectricPowerType || !isItemDefined ? 'col-other' : 'col-2',
            'cell'
          )}
        >
          <p className='text'>
            {singleTimelineItem.powerType === PowerType.Other || isItemOtherType
              ? singleTimelineItem.equipmentModel.id
              : equipmentTitle}
          </p>
        </div>
        {!isItemOtherType && isItemDefined && (
          <div className='col-3 flex justify-end'>
            {singleTimelineItem.equipmentTypeId &&
              isItemElectricPowerType &&
              !isItemOtherType &&
              singleTimelineItem.equipmentModel.additionalInformation && (
                <span className='pl-2'>
                  {singleTimelineItem.equipmentModel.additionalInformation}{' '}
                  <FormattedMessage
                    id={`TIMELINE_EQUIPMENT_ADDITIONAL_INFO_UNIT_${singleTimelineItem.equipmentModel.additionalInformationUnit?.toUpperCase()}`}
                    defaultMessage={singleTimelineItem.equipmentModel.additionalInformationUnit}
                  />
                </span>
              )}
            {isItemElectricPowerType && !isItemOtherType && (
              <span className={clsx('flex mr-4 pl-2', isItemDefined && 'underline')}>
                {getGreatestValueFromArray([
                  singleTimelineItem.equipmentModel.powerFastCharging ?? 0,
                  singleTimelineItem.equipmentModel.powerNormalCharging ?? 0,
                  singleTimelineItem.equipmentModel.powerUsageBattery ?? 0,
                  singleTimelineItem.equipmentModel.powerUsageGrid ?? 0,
                ])}
                &nbsp;
                <p className='font-secondaryRegular'>kW</p>
              </span>
            )}
          </div>
        )}
      </div>
      <div {...rightResizeProps} />
    </div>
  );
};
