import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { Form, FormikProps, FormikProvider } from 'formik';

// COMPONENTS:
import { RadioGroup, StepHeader, PopOver } from '../../molecules';
import { Label } from '../../atoms';
import RadioButton from '../../atoms/primitive/RadioButton';

// TYPES:
import { HeatingStepInterface, ThermalHeatingType } from '../../../store/project-info';
import { DataCarryingFormSteps } from '../../../store/config';

// ICONS:
import { BulbIcon, QuestionIcon } from '../../../assets/images';

//UTILS
import { AutosaveListener } from '../../../pages/Guide/utils';

type HeatingStepProps = {
  formik: FormikProps<HeatingStepInterface>;
};

export const HeatingStep: FC<HeatingStepProps> = ({ formik }) => {
  return (
    <div className='2xl:pl-3.5'>
      <StepHeader
        className={clsx('pb-6 text-center', 'lg:text-left')}
        label={<FormattedMessage id='GUIDE.HEATING.TITLE' />}
        subLabel={<FormattedMessage id='GUIDE.HEATING.SUBTITLE' />}
      />
      <div className={clsx('max-w-5xl text-center', 'lg:text-left')}>
        <FormikProvider value={formik}>
          <Form>
            <div className='flex'>
              <Label
                className={clsx(
                  'flex items-center justify-center mb-4 text-primary-evinyGreen4 text-h3',
                  'lg:justify-start'
                )}
                htmlFor='thermalHeating'
              >
                <FormattedMessage id='GUIDE.HEATING.RADIO.LABEL' />
              </Label>
              <PopOver
                icon={<BulbIcon />}
                description={<FormattedMessage id='GUIDE.HEATING.POPOVER.DESCRIPTION' />}
                className='relative top-1.5 ml-3'
              >
                <QuestionIcon />
              </PopOver>
            </div>
            <RadioGroup
              name='thermalHeating'
              className={clsx('flex flex-col items-start justify-center mb-12', 'lg:justify-start')}
            >
              <RadioButton
                label={<FormattedMessage id='GUIDE.HEATING.RADIO.FIRST.OPTION' />}
                name='thermalHeating'
                value={ThermalHeatingType.Yes}
              />
              <RadioButton
                label={<FormattedMessage id='GUIDE.HEATING.RADIO.SECOND.OPTION' />}
                className='pt-4'
                name='thermalHeating'
                value={ThermalHeatingType.No}
              />
              <RadioButton
                label={<FormattedMessage id='GUIDE.HEATING.RADIO.THIRD.OPTION' />}
                className='pt-4'
                name='thermalHeating'
                value={ThermalHeatingType.Unknown}
              />
            </RadioGroup>

            <AutosaveListener<HeatingStepInterface> formStep={DataCarryingFormSteps.HEATING} />
          </Form>
        </FormikProvider>
      </div>
    </div>
  );
};
