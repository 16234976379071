import { FC } from 'react';
import clsx from 'clsx';

export enum BannerMessageTypes {
  WARNING = 'warning',
  ERROR = 'error',
}

type BannerMessageProps = {
  type: BannerMessageTypes;
  className?: string;
  children: React.ReactNode;
};

const renderTypeStyles = (type: BannerMessageTypes) => {
  switch (type) {
    case BannerMessageTypes.WARNING:
      return 'bg-other-warningOrange';
    case BannerMessageTypes.ERROR:
      return 'bg-other-errorRed bg-opacity-40';
    default:
      return '';
  }
};

export const BannerMessage: FC<BannerMessageProps> = ({ type, className, children }) => {
  const typeStyles = renderTypeStyles(type);

  return (
    <div className={clsx('p-4 text-primary-evinyGreen4 rounded-lg', typeStyles, className)}>
      {children}
    </div>
  );
};
