import { FC } from 'react';

import clsx from 'clsx';
import { Divider } from '../../atoms';

type TabElementProps = {
  tabNumber: string;
  isActive?: boolean;
  className?: string;
  children: React.ReactNode;
};

export const TabElement: FC<TabElementProps> = ({ children, className, tabNumber, isActive }) => {
  return (
    <div
      className={clsx(
        isActive ? 'text-primary-evinyGreen4 ' : 'text-other-gray',
        'max-w-min',
        className
      )}
    >
      <span className='pr-2'>{tabNumber}</span>
      <span className={clsx(isActive ? '' : '')}>{children}</span>
      {isActive && <Divider className='my-2 border-primary-evinyGreen4' />}
    </div>
  );
};
