export enum EQUIPMENT_TYPE {
  DUMPER = 'dumper',
  EXCAVATOR = 'excavator',
  WHEELDUMPER = 'wheel dumper',
  LOADER = 'loader',
  COMPRESSOR = 'compressor',
  MOBILECRANE = 'mobile crane',
  SHREDDER = 'shredder',
  SORTINGMACHINE = 'sorting machine',
  STAMPER = 'stamper',
  TRANSPORTER = 'transporter',
  DRYINGSYSTEM = 'drying system',
  TOWERCRANE = 'tower crane',
  VIBRATORPLATE = 'vibrator plate',
  BARRACKS = 'barracks',
  CONCRETE = 'concrete',
  TRUCK = 'truck',
  OTHER = 'other',
}
