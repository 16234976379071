import { ProjectInfoPayloadType } from '../../api/project-info/types';
import { useQuery } from '@tanstack/react-query';
import { QueryKeysNames } from '../../api/types';
import { getMaxRequiredPower } from '../../api/chart';

export const useMaxRequiredPowerChartData = (projectInfo: ProjectInfoPayloadType) => {
  return useQuery([QueryKeysNames.MAX_REQUIRED_POWER], async () => {
    const res = await getMaxRequiredPower(projectInfo);
    return res.data;
  });
};
