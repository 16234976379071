import { useContext, useMemo } from 'react';
import clsx from 'clsx';
import { MobilEnergiLogo, MachineIcon, SaveEnvironmentIcon } from '../../assets/images';
import { LocationType, ProjectInfoContext, ThermalHeatingType } from '../../store/project-info';
import {
  calculateNumberOfMachines,
  getMapPinPosition,
  prepareCadastralInformationForDisplay,
} from '../../helpers/functions';
import { GoogleMap } from '../../components/molecules';
import { FormattedMessage, useIntl } from 'react-intl';
import { MENU_URLS } from '../../constants';
import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useLoadScript';
import Geocode from 'react-geocode';
import { useJsApiLoader } from '@react-google-maps/api';
import { parseProjectInfoData } from '../../api/project-info/project-info-utils';
import { useQuery } from '@tanstack/react-query';
import { QueryKeysNames } from '../../api/types';
import { getProjectEnvironmentSavings } from '../../api/project-info';
import { locale, months } from '../../translations';
import { DataCarryingFormSteps } from '../../store/config';
import { googleMapsApiKey } from '../../utils/env';

const GOOGLE_MAPS_API_KEY = googleMapsApiKey ?? '';

const libraries: UseLoadScriptOptions['libraries'] = ['places'];
const jsApiLoaderOptions: UseLoadScriptOptions = {
  id: 'google-map-script',
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  libraries,
};

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);

const prepareThermalHeatingForDisplay = (thermalHeating: ThermalHeatingType | '') => {
  switch (thermalHeating) {
    case ThermalHeatingType.Yes:
      return (
        <>
          <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING' />
          &nbsp;
          <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING.REQUIRED' />
        </>
      );
    case ThermalHeatingType.No:
      return (
        <>
          <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING' />
          &nbsp;
          <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING.NOT.REQUIRED' />
        </>
      );
    case ThermalHeatingType.Unknown:
      return (
        <>
          <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING.UNKNOWN.REQUIRED.UNDERLINE' />
          &nbsp;
          <FormattedMessage id='GUIDE.OVERVIEW.THERMAL.HEATING.UNKNOWN.REQUIRED.TEXT' />
        </>
      );
    default:
      return '';
  }
};

export const ProjectInformationSection = ({ projectId }: { projectId: string }) => {
  const { projectInfo, equipmentModels, contactUserData } = useContext(ProjectInfoContext);

  const { isLoaded } = useJsApiLoader(jsApiLoaderOptions);

  const { locationCadastre, locationAddress, locationType } =
    projectInfo[DataCarryingFormSteps.ADDRESS];

  const intl = useIntl();

  const isAddressDefined = locationType === LocationType.Address;

  const { numberOfMachines, numberOfElectricMachines } = calculateNumberOfMachines(
    projectInfo.equipmentSchedule
  );

  const { data: environmentSavings } = useQuery(
    [QueryKeysNames.ENVIRONMENT_SAVINGS],
    async () => await getProjectEnvironmentSavings(parsedProjectInfoData)
  );

  const parsedProjectInfoData = useMemo(
    () => parseProjectInfoData(contactUserData, projectInfo, equipmentModels),
    [contactUserData, projectInfo, equipmentModels]
  );

  const literOfPetrolSaved = Math.round(
    environmentSavings?.data.fossilFuelSavings ?? 0
  ).toLocaleString(locale);

  const co2Saved = Number((environmentSavings?.data.co2Savings ?? 0) * 0.001)
    .toFixed(1)
    .replace('.', ',');

  return (
    <div className='px-28 py-20 bg-primary-evinyGreen4'>
      <div className='mx-auto max-w-1300px'>
        {/* Project details header */}
        <div className='flex flex-col text-other-white'>
          <div className='flex justify-between'>
            <a href={MENU_URLS.LANDING_HOMEPAGE} aria-label='Open main website'>
              <MobilEnergiLogo />
            </a>
            <div className='flex items-center'>
              <h1 className='text-primary-evinyGreen2 text-largeText1'>
                <FormattedMessage id='REPORT' />
              </h1>
            </div>
          </div>
          <div className='pt-14'>
            <h1 className='text-other-white text-h1' data-testid='report-project-name'>
              {projectInfo[DataCarryingFormSteps.PROJECT].projectName}
            </h1>
            <p className='pt-3 text-primary-evinyGreen2 font-secondaryRegular text-body'>
              {projectInfo.persistenceTime && (
                <>
                  <FormattedMessage id='REPORT.GENERATED' />
                  {projectInfo.persistenceTime}. &nbsp;
                </>
              )}
              <FormattedMessage id='REPORT.PROJECT.ID' />
              {projectId}
            </p>
          </div>
        </div>

        <div className='grid gap-x-10 grid-cols-2 pt-14'>
          <div className='flex flex-col justify-between p-8 text-primary-evinyGreen4 bg-primary-lightBackground rounded-2xl'>
            <h2 className='pb-6 text-h2'>
              <FormattedMessage id='REPORT.PROJECT.DETAILS' />
            </h2>
            <p className='font-secondaryRegular text-body'>
              <FormattedMessage id='REPORT.PROJECT.DETAILS.DATE' />
              {new Date(projectInfo[DataCarryingFormSteps.DATE].startDate || '').toLocaleDateString(
                'nb-NO'
              )}
            </p>
            <p className='font-secondaryRegular text-body'>
              <FormattedMessage id='REPORT.PROJECT.DETAILS.NUMBER.OF.WEEKS' />
              <FormattedMessage
                values={{ numberOfWeeks: projectInfo[DataCarryingFormSteps.DATE].numberOfWeeks }}
                {...months[intl.locale]}
              />
            </p>
            <p className='font-secondaryRegular text-body'>
              <FormattedMessage id='REPORT.PROJECT.DETAILS.WORKING.HOURS' />
              {projectInfo[DataCarryingFormSteps.DATE].startWorkingHour} -{' '}
              {projectInfo[DataCarryingFormSteps.DATE].endWorkingHour}
            </p>

            {isAddressDefined ? (
              <p className='font-secondaryRegular text-body' data-testid='report-address'>
                <FormattedMessage id='REPORT.PROJECT.DETAILS.ADDRESS' />
                {locationAddress.address}
              </p>
            ) : (
              <p className='font-secondaryRegular text-body' data-testid='report-cadastre'>
                <FormattedMessage id='REPORT.PROJECT.DETAILS.CADASTRAL' />
                {locationCadastre && prepareCadastralInformationForDisplay(locationCadastre)}
              </p>
            )}

            <p className='font-secondaryRegular text-body'>
              <FormattedMessage
                id='REPORT.PROJECT.DETAILS.AREA.SIZE'
                values={{ value: projectInfo[DataCarryingFormSteps.AREA].areaSize }}
              />
            </p>
            <p className='font-secondaryRegular text-body'>
              <FormattedMessage
                id='REPORT.PROJECT.DETAILS.INTAKE.EFFECT'
                values={{ value: projectInfo[DataCarryingFormSteps.AREA].intakeEffect }}
              />
            </p>
            <p className='font-secondaryRegular text-body'>
              <FormattedMessage
                id='REPORT.PROJECT.DETAILS.TOP.LOAD'
                values={{ value: projectInfo[DataCarryingFormSteps.AREA].topLoad }}
              />
            </p>
            <p className='font-secondaryRegular text-body'>
              {prepareThermalHeatingForDisplay(
                projectInfo[DataCarryingFormSteps.HEATING].thermalHeating || ''
              )}
            </p>
            <p className='font-secondaryRegular text-body'>
              <FormattedMessage id='REQUEST.QUOTE.MODAL.COMMENT' />:{' '}
              {parsedProjectInfoData.contactUserData.comment}
            </p>
          </div>

          {isAddressDefined && (
            <div data-testid='report-map'>
              <GoogleMap
                position={getMapPinPosition(locationAddress.position)}
                isLoaded={isLoaded}
                draggable={false}
                className='w-full h-96 rounded-2xl'
              />
            </div>
          )}
        </div>

        <div className='flex justify-between pt-24'>
          {/* Machines quantity box */}
          <div
            className={clsx(
              'flex flex-col items-center p-10 bg-secondary-evinyCold4 rounded-3xl',
              '3xl:p-14'
            )}
          >
            <div
              className={clsx(
                'max-w-60 flex justify-center p-4 bg-primary-evinyGreen1 rounded-full',
                '3xl:p-6 3xl:max-w-112'
              )}
            >
              <div
                className={clsx(
                  'flex flex-col items-center justify-center w-52 h-52 bg-secondary-evinyCold4 rounded-full',
                  '3xl:w-72 3xl:h-72'
                )}
              >
                <p
                  className={clsx(
                    'max-w-28 text-center text-primary-evinyGreen1 text-h1 ',
                    'px-10 max-w-none 3xl:text-largeText2'
                  )}
                >
                  <FormattedMessage id='PROJECT.DETAILS.MACHINES.QUANTITY' />
                </p>
                <span className='flex items-center'>
                  <p className={clsx('text-largeText2 ', '3xl:text-largeText1')}>
                    {numberOfMachines}
                  </p>
                  <MachineIcon
                    className={clsx(
                      numberOfMachines > 9 ? 'ml-2' : 'ml-4',
                      ' w-16 h-16',
                      '3xl:w-20 3xl:h-20'
                    )}
                  />
                </span>
              </div>
            </div>
            <span
              className={clsx(
                ' flex justify-center pt-8 text-center text-other-white font-secondaryRegular text-body',
                '2xl:text-h3'
              )}
            >
              <p>
                <FormattedMessage
                  values={{
                    value: <strong className=''>{numberOfElectricMachines}</strong>,
                  }}
                  id='PROJECT.DETAILS.MACHINES.EMISSION.FREE.QUANTITY'
                />
              </p>
            </span>
          </div>

          {/* Save environment box */}
          <div className='ml-20 p-1 bg-primary-evinyGreen1 rounded-3xl'>
            <div className='flex items-center px-8 py-16 h-full bg-primary-evinyGreen4 rounded-3xl'>
              <SaveEnvironmentIcon className='h-44' />
              <div className='pl-8'>
                <p className='max-w-sm text-primary-evinyGreen1 font-secondaryRegular text-body'>
                  <FormattedMessage id='PROJECT.DETAILS.SAVE.ENVIRONMENT.DESCRIPTION' />
                </p>

                <span
                  className={clsx(
                    'flex items-center pt-8 text-secondary-evinyWarm1 text-mobileLargeText2',
                    '4xl:text-largeText2'
                  )}
                >
                  <FormattedMessage
                    values={{
                      value: literOfPetrolSaved,
                    }}
                    id='PROJECT.DETAILS.LITER.OF.PETROL.SAVED'
                  />
                </span>

                <span
                  className={clsx(
                    'flex items-center pt-8 text-secondary-evinyWarm1 text-mobileLargeText2',
                    '4xl:text-largeText2'
                  )}
                >
                  <FormattedMessage
                    values={{
                      value: co2Saved,
                      subscript: <sub>2</sub>,
                    }}
                    id='PROJECT.DETAILS.TONS.OF.CO2.SAVED'
                  />
                </span>

                <p className='pt-4 max-w-sm text-primary-evinyGreen1 text-h4'>
                  <FormattedMessage id='PROJECT.DETAILS.SAVE.ENVIRONMENT.SUBTITLE' />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
