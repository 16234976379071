import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';

// API
import { getEquipmentTypesList } from '../../api/equipment';

// TYPES:
import { SingleDropdownOptionType } from '../../types';
import { QueryKeysNames } from '../../api/types';

export const useEquipmentTypeOptions = () => {
  const { formatMessage } = useIntl();

  return useQuery(
    [QueryKeysNames.EQUIPMENT_TYPES_LIST],
    async () => await getEquipmentTypesList(),
    {
      select: (res) =>
        res.data
          .map(
            (equipmentOptionId) =>
              ({
                id: equipmentOptionId,
                label: formatMessage({
                  id: `EQUIPMENT.TYPES.${equipmentOptionId.replace(/\s/g, '').toUpperCase()}`,
                }),
              } as SingleDropdownOptionType)
          )
          .sort((t1, t2) => {
            if (t1.label < t2.label) return -1;
            if (t1.label > t2.label) return 1;
            return 0;
          }),
    }
  );
};
