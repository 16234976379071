import { DataCarryingFormSteps } from '../../store/config';
import {
  EquipmentModelInterface,
  EquipmentScheduleItemType,
  LocationType,
  PowerType,
  ProjectCategoryType,
  ProjectStepsInfoType,
} from '../../store/project-info';
import {
  ContactUserDataType,
  CustomEquipmentModelType,
  CustomEquipmentScheduleItemPayloadType,
  EquipmentScheduleItemPayloadType,
  ProjectInfoPayloadType,
  ProjectPhaseType,
} from './types';
import moment from 'moment';
import { EquipmentModelFromApiType } from '../equipment/types';

export const locationAddressEmptyObject = { address: '', position: { lat: null, lng: null } };

export const locationCadastreEmptyObject = {
  municipality: '',
  cadastralUnitNumber: null,
  propertyUnitNumber: null,
  leaseNumber: null,
  unitNumber: null,
};

export const parseProjectInfoData = (
  contactUserData: ContactUserDataType,
  projectInfoWithEmptyItems: ProjectStepsInfoType,
  equipmentModels: EquipmentModelFromApiType[]
): ProjectInfoPayloadType => {
  const projectInfo = {
    ...projectInfoWithEmptyItems,
    equipmentSchedule: projectInfoWithEmptyItems.equipmentSchedule.filter(
      (machine) => machine.equipmentTypeId
    ),
  };

  const locationType = projectInfo[DataCarryingFormSteps.ADDRESS].locationType;

  return {
    contactUserData: {
      ...contactUserData,
    },
    projectInfo: {
      projectType: mapProjectTypeFromString(projectInfo[DataCarryingFormSteps.PROJECT].projectType),
      projectName: projectInfo[DataCarryingFormSteps.PROJECT].projectName,
      startDate: projectInfo[DataCarryingFormSteps.DATE].startDate,
      numberOfWeeks: projectInfo[DataCarryingFormSteps.DATE].numberOfWeeks,
      startWorkingHour: projectInfo[DataCarryingFormSteps.DATE].startWorkingHour,
      endWorkingHour: projectInfo[DataCarryingFormSteps.DATE].endWorkingHour,
      locationAddress:
        locationType === LocationType.Address
          ? projectInfo[DataCarryingFormSteps.ADDRESS].locationAddress
          : null,
      locationCadastre:
        locationType === LocationType.Cadastre
          ? projectInfo[DataCarryingFormSteps.ADDRESS].locationCadastre
          : null,
      areaSize: projectInfo[DataCarryingFormSteps.AREA].areaSize || 0,
      intakeEffect: projectInfo[DataCarryingFormSteps.AREA].intakeEffect || 0,
      topLoad: projectInfo[DataCarryingFormSteps.AREA].topLoad || 0,
      thermalHeating: projectInfo[DataCarryingFormSteps.HEATING].thermalHeating,
    },
    projectPhase: [mapProjectPhase(projectInfo.equipmentSchedule, equipmentModels)],
  };
};

export const parseProjectInfoPayload = (
  projectInfoPayload: ProjectInfoPayloadType,
  equipmentModels: EquipmentModelFromApiType[]
): ProjectStepsInfoType => {
  const { projectInfo, projectPhase, persistenceTime } = projectInfoPayload;

  const {
    projectType,
    projectName,
    startDate,
    numberOfWeeks,
    startWorkingHour,
    endWorkingHour,
    locationAddress,
    locationCadastre,
    areaSize,
    intakeEffect,
    topLoad,
    thermalHeating,
  } = projectInfo;

  const projectTypeString = mapProjectTypeToString(projectType);
  const { equipmentSchedule, customEquipmentSchedule } = projectPhase[0];

  const equipmentArray: EquipmentScheduleItemType[] = [];

  equipmentSchedule.map((equipment) => {
    const equipmentModel = equipmentModels.find((m) => m.uuid === equipment.equipmentId);
    if (equipmentModel) {
      equipmentArray.push({
        id: equipment.positionOrder + 1,
        startTime: moment(equipment.startDate),
        endTime: moment(equipment.endDate),
        numberOfDaysInUse: equipment.workingDays,
        numberOfMachines: equipment.quantity,
        equipmentTypeId: equipmentModel.type,
        isItemOutdated: false,
        powerType: PowerType.Electric,
        equipmentModel: mapEquipmentModel(equipmentModel),
      });
    }
  });

  customEquipmentSchedule.map((equipment) => {
    equipmentArray.push({
      id: equipment.positionOrder + 1,
      startTime: moment(equipment.startDate),
      endTime: moment(equipment.endDate),
      numberOfDaysInUse: equipment.workingDays,
      numberOfMachines: equipment.quantity,
      equipmentTypeId: equipment.customEquipment.type,
      isItemOutdated: false,
      powerType: PowerType.Other,
      equipmentModel: mapCustomEquipmentModel(equipment.customEquipment),
    });
  });

  equipmentArray.sort((eq1, eq2) => eq1.id - eq2.id);

  if (locationCadastre?.unitNumber === 0) locationCadastre.unitNumber = null;
  if (locationCadastre?.leaseNumber === 0) locationCadastre.leaseNumber = null;

  return {
    0: {
      projectType: projectTypeString,
      projectName,
      completed: true,
    },
    1: {
      startDate,
      numberOfWeeks,
      startWorkingHour,
      endWorkingHour,
      completed: true,
    },
    2: {
      locationType: !locationAddress ? LocationType.Cadastre : LocationType.Address,
      locationAddress: locationAddress || locationAddressEmptyObject,
      locationCadastre: locationCadastre || locationCadastreEmptyObject,
      completed: true,
    },
    3: {
      areaSize,
      intakeEffect,
      topLoad,
      completed: true,
    },
    4: {
      thermalHeating,
      completed: true,
    },
    equipmentSchedule: equipmentArray,
    persistenceTime: persistenceTime
      ? moment.unix(persistenceTime).format('DD.MM.YYYY h:mm:ss')
      : undefined,
  };
};

const mapProjectPhase = (
  equipment: EquipmentScheduleItemType[],
  equipmentModels: EquipmentModelFromApiType[]
): ProjectPhaseType => {
  const equipmentArray: EquipmentScheduleItemPayloadType[] = [];
  const customEquipmentArray: CustomEquipmentScheduleItemPayloadType[] = [];

  equipment.forEach((e, i) => {
    if (equipmentModels.find((m) => m.uuid === e.equipmentModel.id)) {
      equipmentArray.push({
        equipmentId: e.equipmentModel.id,
        startDate: e.startTime.toDate(),
        endDate: e.endTime.toDate(),
        positionOrder: i,
        workingDays: e.numberOfDaysInUse,
        quantity: e.numberOfMachines,
      });
    } else {
      customEquipmentArray.push({
        startDate: e.startTime.toDate(),
        endDate: e.endTime.toDate(),
        customEquipment: {
          type: e.equipmentTypeId,
          model: e.equipmentModel.id,
        },
        positionOrder: i,
        workingDays: e.numberOfDaysInUse,
        quantity: e.numberOfMachines,
      });
    }
  });
  return {
    name: 'default', // TODO: add project phases after MVP
    equipmentSchedule: equipmentArray,
    customEquipmentSchedule: customEquipmentArray,
  };
};

const mapEquipmentModel = (m: EquipmentModelFromApiType): EquipmentModelInterface => ({
  id: m.uuid,
  equipmentTypeId: m.type,
  additionalInformation: m.additionalInformation,
  additionalInformationUnit: m.additionalInformationUnit,
  powerUsageBattery: m.power_usage_battery,
  powerUsageGrid: m.power_usage_grid,
  powerFastCharging: m.power_fast_charging,
  powerNormalCharging: m.power_normal_charging,
});

const mapCustomEquipmentModel = (
  customEquipment: CustomEquipmentModelType
): EquipmentModelInterface => ({
  id: customEquipment.model,
  equipmentTypeId: customEquipment.type,
  additionalInformation: 0,
  additionalInformationUnit: '',
  powerUsageBattery: null,
  powerUsageGrid: null,
  powerFastCharging: null,
  powerNormalCharging: null,
});

export const mapProjectTypeToString = (object: ProjectCategoryType | '') => {
  if (object === '') return '';
  else return JSON.stringify(object);
};
export const mapProjectTypeFromString = (object: string) => {
  if (object === '')
    return {
      domain: '',
      type: '',
    };
  else return JSON.parse(object) as ProjectCategoryType;
};
