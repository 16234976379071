import { createIntl, createIntlCache } from 'react-intl';
import noMessages from './no.json';
import nb from 'date-fns/locale/nb'; // nb = Norwegian Bokmaal
import { registerLocale, setDefaultLocale } from 'react-datepicker';

const cache = createIntlCache();

// Set up date picker defaults
registerLocale('nb', nb);
setDefaultLocale('nb');

export const locale = 'no';
export const defaultLocale = 'no';
export const intl = createIntl({ locale, messages: noMessages }, cache);
