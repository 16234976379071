export enum QueryKeysNames {
  MAX_POTENTIAL_EFFECT = 'maxPotentialEffect',
  MAX_POTENTIAL_EFFECT_PEAK = 'maxPotentialEffectPeak',
  EQUIPMENT_MODELS_LIST = 'equipmentModelsList',
  EQUIPMENT_TYPES_LIST = 'equipmentTypesList',
  GET_PROJECT_INFO_DATA = 'getProjectInfoData',
  PROJECT_TYPES = 'projectTypes',
  ENVIRONMENT_SAVINGS = 'environmentSavings',
  MAX_REQUIRED_POWER = 'maxRequiredPower',
}
