import { FC } from 'react';
import ReactLoader, { LoaderProps } from 'react-loader-spinner';
import tailwindConfig from '../../../../tailwind.config';

export const Loader: FC<LoaderProps> = ({
  color = tailwindConfig.theme.extend.colors.other.white,
  ...props
}) => {
  return <ReactLoader color={color} {...props} />;
};
