import React from 'react';

// COMPONENTS:
import { StartContent } from '../../components/organisms';
import './home.css';

export const Home = () => {
  return (
    <div className='relative pt-12 px-40 h-full overflow-y-auto'>
      <div className='backgroundImage position-absolute absolute -z-1 left-0 top-0 2xl:block hidden w-full h-full bg-primary-lightBackground opacity-60'></div>
      <StartContent />
    </div>
  );
};
