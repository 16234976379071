import { Form, Formik } from 'formik';
import { FC, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';

// API
import { sendProjectInfoData } from '../../../api/project-info';
import { parseProjectInfoData } from '../../../api/project-info/project-info-utils';
import { ContactUserDataType } from '../../../api/project-info/types';
import { MENU_URLS } from '../../../constants';
import { ProjectInfoContext } from '../../../store';

// COMPONENTS:
import { FormCheckbox, Textarea } from '../../atoms';
import Button from '../../atoms/primitive/Button';
import ExternalLink from '../../atoms/primitive/ExternalLink';
import Modal from '../../atoms/primitive/Modal';

// CONSTANTS:
import { RootRoutes } from '../../../router';
import { InputWithLabel } from '../InputWithLabel';
import { ProjectInfoActionType } from '../../../store/project-info';

type RequestQuoteModalProps = {
  onModalClose: () => void;
  isOpen: boolean;
};

export const RequestQuoteModal: FC<RequestQuoteModalProps> = ({ isOpen, onModalClose }) => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { updateProjectInfo, projectInfo, equipmentModels } = useContext(ProjectInfoContext);

  const requestQuoteSchema = Yup.object().shape({
    firstName: Yup.string().required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    lastName: Yup.string().required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    email: Yup.string()
      .email(formatMessage({ id: 'REQUEST.QUOTE.MODAL.INVALID.FORMAT' }))
      .required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    companyNumber: Yup.string().required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    phoneNumber: Yup.string().required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    gdprAgreement: Yup.bool().oneOf([true], formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
  });

  return (
    <>
      <Modal
        withBackground
        isOpen={isOpen}
        onModalClose={onModalClose}
        className='relative pb-8 pt-16 px-8 w-160 text-left bg-primary-lightBackground'
      >
        <h2 className='flex items-center justify-start pb-2 text-primary-evinyGreen4 text-h2'>
          <FormattedMessage id='REQUEST.QUOTE.MODAL.TITLE' />
        </h2>
        <p className='block text-primary-evinyGreen4 font-secondaryRegular text-body'>
          <FormattedMessage id='REQUEST.QUOTE.MODAL.SUBTITLE' />
        </p>

        <Formik
          enableReinitialize
          validationSchema={requestQuoteSchema}
          initialValues={{
            customerContactAgreement: true,
            firstName: '',
            lastName: '',
            email: '',
            companyNumber: '',
            phoneNumber: '',
            comment: '',
            shareInformationAgreement: false,
            gdprAgreement: false,
          }}
          onSubmit={(values: ContactUserDataType) => {
            const parsedProjectInfoData = parseProjectInfoData(
              values,
              projectInfo,
              equipmentModels
            );
            sendProjectInfoData(parsedProjectInfoData).then(() => {
              history.push(RootRoutes.FEEDBACK);
              updateProjectInfo({ type: ProjectInfoActionType.ResetProjectInfo });
            });
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <FormCheckbox
                className='pb-4 pt-2'
                name='customerContactAgreement'
                label={formatMessage({ id: 'REQUEST.QUOTE.MODAL.CONTACTED.BY.ADVISOR.AGREEMENT' })}
              />
              <h3 className='text-primary-evinyGreen4 text-h3'>
                <FormattedMessage id='REQUEST.QUOTE.MODAL.FORM.TITLE' />
              </h3>

              <div className='grid gap-x-4 grid-cols-2'>
                <InputWithLabel
                  name='firstName'
                  label={<FormattedMessage id='REQUEST.QUOTE.MODAL.FIRST.NAME.LABEL' />}
                  placeholder={formatMessage({ id: 'REQUEST.QUOTE.MODAL.FIRST.NAME.PLACEHOLDER' })}
                  className='inline-flex px-4 py-3.5 max-h-12'
                />
                <InputWithLabel
                  name='lastName'
                  label={<FormattedMessage id='REQUEST.QUOTE.MODAL.LAST.NAME.LABEL' />}
                  placeholder={formatMessage({ id: 'REQUEST.QUOTE.MODAL.LAST.NAME.PLACEHOLDER' })}
                  className='inline-flex px-4 py-3.5 max-h-12'
                />
              </div>
              <InputWithLabel
                name='email'
                label={<FormattedMessage id='REQUEST.QUOTE.MODAL.EMAIL.LABEL' />}
                placeholder={formatMessage({ id: 'REQUEST.QUOTE.MODAL.EMAIL.PLACEHOLDER' })}
                className='inline-flex px-4 py-3.5 max-h-12'
              />
              <div className='grid gap-4 grid-cols-2'>
                <InputWithLabel
                  name='companyNumber'
                  label={<FormattedMessage id='REQUEST.QUOTE.MODAL.ORGANIZATION.NUMBER.LABEL' />}
                  placeholder={formatMessage({
                    id: 'REQUEST.QUOTE.MODAL.ORGANIZATION.NUMBER.PLACEHOLDER',
                  })}
                  className='inline-flex px-4 py-3.5 max-h-12'
                />
                <InputWithLabel
                  name='phoneNumber'
                  label={<FormattedMessage id='REQUEST.QUOTE.MODAL.YOUR.PHONE.NUMBER.LABEL' />}
                  placeholder={formatMessage({
                    id: 'REQUEST.QUOTE.MODAL.YOUR.PHONE.NUMBER.PLACEHOLDER',
                  })}
                  className='inline-flex px-4 py-3.5 max-h-12'
                />
              </div>
              <h3 className='block pb-2 pt-4 text-primary-evinyGreen4 text-h3'>
                <FormattedMessage id='REQUEST.QUOTE.MODAL.COMMENT.TITLE' />
              </h3>
              <div className='mb-8'>
                <Textarea
                  name='comment'
                  label={<FormattedMessage id='REQUEST.QUOTE.MODAL.COMMENT' />}
                  placeholder={formatMessage({ id: 'REQUEST.QUOTE.MODAL.COMMENT' })}
                  className='inline-flex px-4 py-3.5 w-full h-20 text-other-gray font-secondaryRegular text-body bg-transparent border border-primary-evinyGreen4 rounded'
                />
              </div>
              {/* Commented code below might be used again in the near future */}
              {/*<FormCheckbox*/}
              {/*  className='mb-3'*/}
              {/*  name='shareInformationAgreement'*/}
              {/*  label={formatMessage({ id: 'REQUEST.QUOTE.MODAL.SHARE.INFORMATION.AGREEMENT' })}*/}
              {/*/>*/}
              <FormCheckbox
                name='gdprAgreement'
                label={
                  <>
                    <FormattedMessage id='REQUEST.QUOTE.MODAL.GDPR.AGREEMENT' />{' '}
                    <ExternalLink
                      href={MENU_URLS.PRIVACY_POLICY}
                      className='text-primary-evinyGreen4 underline font-secondaryRegular text-body'
                    >
                      <FormattedMessage id='REQUEST.QUOTE.MODAL.PRIVACY.STATEMENT' />
                    </ExternalLink>
                  </>
                }
              />
              <Button type='submit' disabled={isSubmitting} variant='primary' className='my-6'>
                <FormattedMessage id='REQUEST.QUOTE.MODAL.SUBMIT.BUTTON' />
              </Button>
            </Form>
          )}
        </Formik>
        <Button
          className='mt-4 text-primary-evinyGreen4 underline font-secondaryRegular text-body'
          onClick={onModalClose}
        >
          <FormattedMessage id='REQUEST.QUOTE.MODAL.CANCEL.BUTTON' />
        </Button>
      </Modal>
    </>
  );
};
