import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const MobilEnergiLogo: FC<IconPropsType> = ({
  className,
  color = tailwindConfig.theme.extend.colors.other.white,
}) => {
  return (
    <svg
      width='200'
      height='30'
      viewBox='0 0 200 30'
      fill='none'
      className={className}
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2521_3)'>
        <path
          d='M36.6104 9.54288C38.2208 9.54288 39.529 10.1178 40.5365 11.2705C41.5453 12.4215 41.9571 13.8158 41.7737 15.453H33.4693C33.6128 16.2635 33.9848 16.9212 34.5881 17.4252C35.1897 17.9304 35.917 18.1822 36.7675 18.1822C37.4222 18.1822 38.0077 18.0208 38.5248 17.7007C39.042 17.3806 39.4449 16.945 39.7323 16.3956L41.46 17.1602C41.0154 18.0119 40.3798 18.6921 39.5561 19.2033C38.7307 19.7128 37.7814 19.9692 36.7085 19.9692C35.2033 19.9692 33.941 19.4716 32.9199 18.476C31.8975 17.4821 31.388 16.2448 31.388 14.766C31.388 13.286 31.8886 12.0458 32.8902 11.0442C33.8901 10.0435 35.1303 9.54288 36.6104 9.54288ZM36.6104 11.3295C35.8504 11.3295 35.1927 11.5622 34.6369 12.0258C34.0799 12.4911 33.7109 13.0953 33.5271 13.8422H39.692C39.523 13.0953 39.1587 12.4911 38.6029 12.0258C38.0463 11.5622 37.3823 11.3295 36.6104 11.3295Z'
          fill='#124442'
        />
        <path
          d='M47.1339 19.1434L50.2171 9.73816H52.318L48.8037 19.7714H45.3086L41.7944 9.73816H44.0315L47.1339 19.1434Z'
          fill='#124442'
        />
        <path
          d='M53.3878 7.75583C53.133 7.50703 53.0057 7.20601 53.0057 6.85191C53.0057 6.49908 53.133 6.19721 53.3878 5.94798C53.6425 5.70045 53.9478 5.57605 54.3006 5.57605C54.6547 5.57605 54.9553 5.70045 55.2046 5.94798C55.4534 6.19678 55.5778 6.49908 55.5778 6.85191C55.5778 7.20601 55.4534 7.50661 55.2046 7.75583C54.9558 8.00506 54.6547 8.12776 54.3006 8.12776C53.9478 8.12776 53.6425 8.00464 53.3878 7.75583ZM55.3209 19.7714H53.26V9.73819H55.3209V19.7714Z'
          fill='#124442'
        />
        <path
          d='M63.0953 9.54288C64.2344 9.54288 65.1634 9.94156 65.8835 10.7402C66.6036 11.5388 66.9636 12.5536 66.9636 13.7836V19.7714H64.9027V14.0969C64.9027 13.325 64.6539 12.6865 64.1559 12.1829C63.6583 11.6794 63.043 11.4272 62.3098 11.4272C61.4861 11.4272 60.8119 11.6789 60.2875 12.1829C59.7645 12.6865 59.5025 13.325 59.5025 14.0969V19.7714H57.4416V9.73818H59.5025V12.3294C59.778 11.4658 60.2328 10.7856 60.8671 10.2876C61.5027 9.79168 62.2448 9.54288 63.0953 9.54288Z'
          fill='#124442'
        />
        <path
          d='M72.7759 19.2024L75.9569 9.73816H78.0577L73.3448 23.6974H71.244L72.5806 19.7713H70.951L67.5344 9.73816H69.7715L72.7759 19.2024Z'
          fill='#124442'
        />
        <path
          d='M23.694 20.4142C24.5176 18.7507 24.8777 16.8571 24.6718 15.011C24.0549 9.78746 19.7331 5.24489 14.6182 4.19363C13.4973 3.96521 12.3365 3.92318 11.1944 3.94908C7.3439 4.02762 3.36603 5.66183 2.16575 9.62145C-0.225469 17.4783 9.12967 30.4534 17.769 26.6768C20.4774 25.4718 22.4062 23.0203 23.694 20.4142ZM23.9644 20.5514C22.4733 23.3748 20.606 26.3562 17.7091 27.9144C14.9337 29.4263 11.7009 29.0476 9.01462 27.5302C4.46569 25.0073 1.01472 19.3952 0.512447 14.2498C0.0891429 9.69575 2.07234 5.88982 6.33001 4.04758C7.84193 3.39245 9.46042 3.00482 11.0844 2.75516C12.9054 2.47706 14.7634 2.33101 16.5861 2.71822C20.713 3.51558 24.2179 6.85192 25.228 10.9173C26.0775 14.1347 25.4827 17.649 23.9644 20.5514Z'
          fill='#76E691'
        />
        <path
          d='M15.2017 24.9457C17.4982 24.6455 19.65 23.5102 21.2022 21.8C23.1744 19.6122 24.3127 16.776 24.7661 13.8922C25.2187 10.8531 24.8863 7.50491 22.9999 4.97995C21.6429 3.1755 19.6525 2.05376 17.4613 1.5846C15.3736 1.16894 13.1921 1.54087 11.2484 2.3654C7.62931 3.91596 4.36643 6.93513 3.37461 10.8192C2.49871 14.1139 3.27781 17.6897 5.28394 20.4329C7.5601 23.6274 11.2497 25.5112 15.2017 24.9457ZM15.2399 25.2467C9.37984 25.9736 3.4349 22.7999 1.72895 16.956C0.990181 14.5181 1.08232 11.8407 1.96926 9.45457C4.21952 3.58988 10.8035 -0.381613 17.0375 0.611049C21.6153 1.41775 25.355 4.65685 26.6462 9.14887C27.6129 12.472 27.0958 16.1862 25.2973 19.1383C23.1447 22.6869 19.292 24.789 15.2399 25.2467Z'
          fill='#55B077'
        />
        <path
          d='M6.49437 24.2032C9.10509 26.4959 12.4036 27.8762 15.906 27.6626C21.7146 27.3718 26.1574 22.4017 25.8012 16.5811C25.6997 14.7971 25.1592 13.0346 24.3703 11.4348C21.8492 6.06051 15.385 3.05238 9.82985 5.40709C8.47332 5.97942 7.217 6.80437 6.17636 7.84756C3.52318 10.4914 2.38489 14.5249 3.05954 18.1941C3.47775 20.5123 4.71709 22.6602 6.49437 24.2032ZM6.29269 24.4299C2.94406 21.423 0.9184 16.6847 1.8962 12.1855C2.64218 8.59609 5.24526 5.50899 8.59008 4.04164C12.3866 2.29875 17.0629 2.75262 20.4519 5.17314C22.2487 6.42522 23.6876 8.15665 24.6879 10.0932C27.1475 14.7049 27.9941 20.8231 24.8994 25.3457C23.5186 27.3595 21.3554 28.8328 18.9633 29.3393C14.1627 30.4084 9.68889 27.5272 6.29269 24.4299Z'
          fill='#337A5C'
        />
        <path
          d='M198.699 7.22001C198.553 7.07335 198.479 6.89335 198.479 6.68001C198.479 6.46668 198.553 6.28668 198.699 6.14001C198.846 5.99335 199.026 5.92001 199.239 5.92001C199.453 5.92001 199.633 5.99335 199.779 6.14001C199.926 6.28668 199.999 6.46668 199.999 6.68001C199.999 6.89335 199.926 7.07335 199.779 7.22001C199.633 7.36668 199.453 7.44001 199.239 7.44001C199.026 7.44001 198.846 7.36668 198.699 7.22001ZM199.799 19.8H198.699V9.74001H199.799V19.8Z'
          fill='#124442'
        />
        <path
          d='M194.906 12.7V9.74001H196.006V18.78C196.006 20.26 195.499 21.5 194.486 22.5C193.486 23.5 192.239 24 190.746 24C189.652 24 188.686 23.72 187.846 23.16C187.006 22.6133 186.379 21.88 185.966 20.96L186.986 20.54C187.319 21.2867 187.812 21.88 188.466 22.32C189.132 22.76 189.892 22.98 190.746 22.98C191.919 22.98 192.906 22.5733 193.706 21.76C194.506 20.96 194.906 19.9667 194.906 18.78V16.8C194.546 17.76 193.966 18.5267 193.166 19.1C192.379 19.6733 191.466 19.96 190.426 19.96C189.026 19.96 187.859 19.46 186.926 18.46C185.992 17.46 185.526 16.22 185.526 14.74C185.526 13.26 185.992 12.0267 186.926 11.04C187.859 10.04 189.026 9.54001 190.426 9.54001C191.466 9.54001 192.379 9.82668 193.166 10.4C193.966 10.9733 194.546 11.74 194.906 12.7ZM190.686 18.92C191.899 18.92 192.906 18.5267 193.706 17.74C194.506 16.94 194.906 15.94 194.906 14.74C194.906 13.5533 194.506 12.5667 193.706 11.78C192.906 10.98 191.899 10.58 190.686 10.58C189.552 10.58 188.592 10.98 187.806 11.78C187.019 12.58 186.626 13.5667 186.626 14.74C186.626 15.9267 187.019 16.92 187.806 17.72C188.592 18.52 189.552 18.92 190.686 18.92Z'
          fill='#124442'
        />
        <path
          d='M181.03 9.74001V12.3C181.79 10.2333 183.19 9.33334 185.23 9.6V10.68C184.07 10.48 183.076 10.7067 182.25 11.36C181.436 12.0133 181.03 12.9 181.03 14.02V19.8H179.93V9.74001H181.03Z'
          fill='#124442'
        />
        <path
          d='M172.463 9.54001C173.969 9.54001 175.216 10.0667 176.203 11.12C177.203 12.1733 177.669 13.4933 177.603 15.08H168.403C168.483 16.2 168.916 17.1333 169.703 17.88C170.489 18.6267 171.449 19 172.583 19C173.436 19 174.196 18.7867 174.863 18.36C175.529 17.92 176.029 17.3333 176.363 16.6L177.363 16.96C176.949 17.88 176.316 18.62 175.463 19.18C174.623 19.7267 173.656 20 172.563 20C171.069 20 169.823 19.5 168.823 18.5C167.823 17.5 167.323 16.26 167.323 14.78C167.323 13.2867 167.809 12.04 168.783 11.04C169.769 10.04 170.996 9.54001 172.463 9.54001ZM172.463 10.56C171.423 10.56 170.529 10.8933 169.783 11.56C169.036 12.2267 168.589 13.08 168.443 14.12H176.483C176.349 13.1067 175.909 12.26 175.163 11.58C174.416 10.9 173.516 10.56 172.463 10.56Z'
          fill='#124442'
        />
        <path
          d='M160.923 9.54001C162.123 9.54001 163.116 9.96001 163.903 10.8C164.703 11.64 165.103 12.6867 165.103 13.94V19.8H164.003V14.02C164.003 13.0467 163.676 12.2333 163.023 11.58C162.383 10.9133 161.596 10.58 160.663 10.58C159.65 10.58 158.81 10.9067 158.143 11.56C157.476 12.2133 157.143 13.0333 157.143 14.02V19.8H156.043V9.74001H157.143V12.28C157.436 11.4533 157.916 10.7933 158.583 10.3C159.263 9.79334 160.043 9.54001 160.923 9.54001Z'
          fill='#124442'
        />
        <path
          d='M148.576 9.54001C150.083 9.54001 151.329 10.0667 152.316 11.12C153.316 12.1733 153.783 13.4933 153.716 15.08H144.516C144.596 16.2 145.029 17.1333 145.816 17.88C146.603 18.6267 147.563 19 148.696 19C149.549 19 150.309 18.7867 150.976 18.36C151.643 17.92 152.143 17.3333 152.476 16.6L153.476 16.96C153.063 17.88 152.429 18.62 151.576 19.18C150.736 19.7267 149.769 20 148.676 20C147.183 20 145.936 19.5 144.936 18.5C143.936 17.5 143.436 16.26 143.436 14.78C143.436 13.2867 143.923 12.04 144.896 11.04C145.883 10.04 147.109 9.54001 148.576 9.54001ZM148.576 10.56C147.536 10.56 146.643 10.8933 145.896 11.56C145.149 12.2267 144.703 13.08 144.556 14.12H152.596C152.463 13.1067 152.023 12.26 151.276 11.58C150.529 10.9 149.629 10.56 148.576 10.56Z'
          fill='#124442'
        />
        <path d='M135.131 19.8H134.031V5H135.131V19.8Z' fill='#124442' />
        <path
          d='M130.223 7.22001C130.076 7.07335 130.003 6.89335 130.003 6.68001C130.003 6.46668 130.076 6.28668 130.223 6.14001C130.369 5.99335 130.549 5.92001 130.763 5.92001C130.976 5.92001 131.156 5.99335 131.303 6.14001C131.449 6.28668 131.523 6.46668 131.523 6.68001C131.523 6.89335 131.449 7.07335 131.303 7.22001C131.156 7.36668 130.976 7.44001 130.763 7.44001C130.549 7.44001 130.369 7.36668 130.223 7.22001ZM131.323 19.8H130.223V9.74001H131.323V19.8Z'
          fill='#124442'
        />
        <path
          d='M123.009 9.54C124.409 9.54 125.576 10.04 126.509 11.04C127.456 12.04 127.929 13.2867 127.929 14.78C127.929 16.26 127.456 17.5 126.509 18.5C125.576 19.5 124.409 20 123.009 20C121.983 20 121.069 19.7133 120.269 19.14C119.483 18.5667 118.909 17.8 118.549 16.84V19.8H117.449V5H118.549V12.7C118.909 11.74 119.483 10.9733 120.269 10.4C121.069 9.82667 121.983 9.54 123.009 9.54ZM122.769 18.96C123.903 18.96 124.856 18.56 125.629 17.76C126.416 16.96 126.809 15.9667 126.809 14.78C126.809 13.5933 126.416 12.6 125.629 11.8C124.856 10.9867 123.903 10.58 122.769 10.58C121.556 10.58 120.549 10.98 119.749 11.78C118.949 12.58 118.549 13.58 118.549 14.78C118.549 15.98 118.949 16.98 119.749 17.78C120.549 18.5667 121.556 18.96 122.769 18.96Z'
          fill='#124442'
        />
        <path
          d='M104.647 14.78C104.647 13.2867 105.147 12.04 106.147 11.04C107.147 10.04 108.394 9.54001 109.887 9.54001C111.38 9.54001 112.627 10.04 113.627 11.04C114.64 12.04 115.147 13.2867 115.147 14.78C115.147 16.26 114.64 17.5 113.627 18.5C112.627 19.5 111.38 20 109.887 20C108.394 20 107.147 19.5 106.147 18.5C105.147 17.5 104.647 16.26 104.647 14.78ZM106.927 11.8C106.14 12.6 105.747 13.5933 105.747 14.78C105.747 15.9667 106.14 16.96 106.927 17.76C107.727 18.56 108.714 18.96 109.887 18.96C111.06 18.96 112.04 18.56 112.827 17.76C113.627 16.96 114.027 15.9667 114.027 14.78C114.027 13.5933 113.627 12.6 112.827 11.8C112.04 10.9867 111.06 10.58 109.887 10.58C108.714 10.58 107.727 10.9867 106.927 11.8Z'
          fill='#124442'
        />
        <path
          d='M98.66 9.54001C99.74 9.54001 100.64 9.96001 101.36 10.8C102.08 11.64 102.44 12.6867 102.44 13.94V19.8H101.34V14.02C101.34 13.0467 101.053 12.2333 100.48 11.58C99.9067 10.9133 99.2133 10.58 98.4 10.58C97.4933 10.58 96.74 10.9067 96.14 11.56C95.5533 12.2133 95.26 13.0333 95.26 14.02V19.8H94.16V14.02C94.16 13.0467 93.8733 12.2333 93.3 11.58C92.74 10.9133 92.0467 10.58 91.22 10.58C90.3133 10.58 89.5667 10.9067 88.98 11.56C88.3933 12.2133 88.1 13.0333 88.1 14.02V19.8H87V9.74001H88.1V12.16C88.38 11.36 88.82 10.7267 89.42 10.26C90.0333 9.78001 90.7267 9.54001 91.5 9.54001C92.3533 9.54001 93.1 9.81334 93.74 10.36C94.38 10.8933 94.82 11.6 95.06 12.48C95.3 11.6 95.7467 10.8933 96.4 10.36C97.0533 9.81334 97.8067 9.54001 98.66 9.54001Z'
          fill='#124442'
        />
      </g>
      <defs>
        <clipPath id='clip0_2521_3'>
          <rect width='200' height='30' fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};
