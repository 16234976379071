import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const InteractiveElementClicked: FC<IconPropsType> = ({ className, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width='45'
      height='44'
      viewBox='0 0 45 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='6.02832'
        y='5.72852'
        width='32.8869'
        height='32.8869'
        rx='16.4435'
        fill={tailwindConfig.theme.extend.colors.secondary.evinyWarm1}
      />
      <path
        d='M28.1244 20.701H23.4997V16.0763C23.4997 15.8194 23.2427 15.5624 22.9858 15.5624H21.9581C21.6691 15.5624 21.4442 15.8194 21.4442 16.0763V20.701H16.8195C16.5305 20.701 16.3057 20.9579 16.3057 21.2149V22.2426C16.3057 22.5316 16.5305 22.7564 16.8195 22.7564H21.4442V27.3812C21.4442 27.6702 21.6691 27.895 21.9581 27.895H22.9858C23.2427 27.895 23.4997 27.6702 23.4997 27.3812V22.7564H28.1244C28.3813 22.7564 28.6383 22.5316 28.6383 22.2426V21.2149C28.6383 20.9579 28.3813 20.701 28.1244 20.701Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <rect
        x='6.02832'
        y='5.72852'
        width='32.8869'
        height='32.8869'
        rx='16.4435'
        strokeWidth='5.13858'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
      />
    </svg>
  );
};
