import { useContext, useMemo } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { BaseSchema } from 'yup';

// STORE
import { ProjectInfoContext, useConfigStore } from '../../../store';

// TYPES
import { DateStepInterface } from '../../../store/project-info';
import { ConfigActionType, DataCarryingFormSteps } from '../../../store/config';

export interface UseDateStep {
  formik: FormikProps<DateStepInterface>;
  goToNextStep: () => void;
  backToPreviousStep: () => void;
}

export const useDateStep = (): UseDateStep => {
  const { formatMessage } = useIntl();
  const { projectInfo } = useContext(ProjectInfoContext);
  const { updateConfig } = useConfigStore();

  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    numberOfWeeks: Yup.number()
      .positive(formatMessage({ id: 'ERROR.FIELD.MIN.VALUE' }))
      .integer(formatMessage({ id: 'ERROR.FIELD.NUMBER.INTEGER' }))
      .max(999, formatMessage({ id: 'ERROR.FIELD.MAX.VALUE' }))
      .required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    startWorkingHour: Yup.string().required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    endWorkingHour: Yup.string().when(
      'startWorkingHour',
      (startWorkingHour: string, schema: BaseSchema) => {
        return schema.test({
          test: (endWorkingHour: string) => !!startWorkingHour && endWorkingHour > startWorkingHour,
          message: formatMessage({ id: 'GUIDE.DATE.END.WORKING.HOUR.ERROR' }),
        });
      }
    ),
  });

  const initialValues = useMemo(
    () => ({
      startDate: projectInfo[DataCarryingFormSteps.DATE].startDate,
      numberOfWeeks: projectInfo[DataCarryingFormSteps.DATE].numberOfWeeks,
      startWorkingHour: projectInfo[DataCarryingFormSteps.DATE].startWorkingHour,
      endWorkingHour: projectInfo[DataCarryingFormSteps.DATE].endWorkingHour,
      completed: projectInfo[DataCarryingFormSteps.DATE].completed,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const formik = useFormik<DateStepInterface>({
    initialValues,
    validationSchema,
    onSubmit: () => {
      updateConfig({
        type: ConfigActionType.UPDATE_FORM_STEP,
        payload: DataCarryingFormSteps.ADDRESS,
      });
    },
    validateOnMount: true,
  });

  const goToNextStep = () => {
    formik.submitForm();
  };

  const backToPreviousStep = () => {
    if (formik.isValid) {
      updateConfig({
        type: ConfigActionType.UPDATE_FORM_STEP,
        payload: DataCarryingFormSteps.PROJECT,
      });
    } else {
      formik.submitForm();
    }
  };

  return { formik, goToNextStep, backToPreviousStep };
};
