import { FC } from 'react';
import clsx from 'clsx';
import ContactPerson from '../../assets/images/contact-person.png';
import { FormattedMessage, useIntl } from 'react-intl';

export const ContactSection: FC = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <div className='pb-28 px-28 bg-primary-evinyGreen4'>
        <div className='mx-auto max-w-1300px'>
          <div className='flex items-center justify-between'>
            <div className='pr-12'>
              <h2 className='text-primary-evinyGreen1 text-h2'>
                <FormattedMessage id='REPORT.CONTACT.HEADER' />
              </h2>
              <p className='pt-6 text-primary-evinyGreen1 whitespace-pre-line font-secondaryRegular text-body'>
                <FormattedMessage id='REPORT.CONTACT.DESCRIPTION' />
              </p>
            </div>

            <div className='flex flex items-center px-6 py-4 w-full max-w-max bg-primary-evinyGreen1 rounded-3xl'>
              <img
                src={ContactPerson}
                alt='contact-person'
                className={clsx('w-36 h-36', '2xl:w-48 2xl:h-48')}
              />
              <div className={clsx('flex flex-col justify-around pl-4 h-full', '2xl:pl-10')}>
                <h3 className='text-primary-evinyGreen4 text-h3'>
                  <FormattedMessage id='REPORT.CONTACT.PERSON.NAME' />
                </h3>
                <h4 className='text-primary-evinyGreen4 text-h4'>
                  <FormattedMessage id='REPORT.CONTACT.PERSON.ROLE' />
                </h4>
                <a
                  href={`mailto:${formatMessage({ id: 'REPORT.CONTACT.PERSON.EMAIL' })}`}
                  className={clsx(
                    'hover:opacity-70 hover:cursor-pointer',
                    'pt-3 text-secondary-evinyCold3 underline font-secondaryRegular text-body '
                  )}
                >
                  <FormattedMessage id='REPORT.CONTACT.PERSON.EMAIL' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col items-center px-28 py-20 bg-primary-darkBackground'>
        <div className='mx-auto max-w-1300px text-center'>
          <p className='text-primary-evinyGreen1'>
            <FormattedMessage id='REPORT.FOOTER.HEADER' />
          </p>
          <p className='py-1 text-primary-evinyGreen1'>
            <FormattedMessage id='REPORT.FOOTER.PHONE.NUMBER' />
          </p>
          <a
            className='text-primary-evinyGreen1 underline'
            href={`mailto:${formatMessage({ id: 'REPORT.FOOTER.EMAIL' })}`}
          >
            <FormattedMessage id='REPORT.FOOTER.EMAIL' />
          </a>
        </div>
      </div>
    </>
  );
};
