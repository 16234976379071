import { ButtonHTMLAttributes, FC } from 'react';
import { Loader } from '../../Loader';
import clsx from 'clsx';
import tailwindConfig from '../../../../../tailwind.config';
import './button.css';

type ButtonProps = {
  isLoading?: boolean;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'cancelPrimary' | 'cancelSecondary' | 'default';
};

const Button: FC<ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps> = ({
  className,
  onClick,
  children,
  disabled,
  isLoading = false,
  variant = 'default',
  ...props
}) => {
  const defaultStyle = 'text-button text-center hover:opacity-50 transition';

  return (
    <button
      disabled={disabled || isLoading}
      onClick={onClick}
      className={
        variant === 'default'
          ? clsx(defaultStyle, className)
          : clsx(
              'border-box flex items-center justify-center px-10 py-4 text-button border-2 rounded-full transition duration-300',
              'group focus:outline-none focus:ring-offset-2 focus:ring-2',
              `${variant}-button`,
              disabled && 'opacity-30 cursor-default',
              className
            )
      }
      {...props}
    >
      {isLoading ? (
        <Loader
          type='ThreeDots'
          color={tailwindConfig.theme.extend.colors.other.white}
          height={40}
          width={40}
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
