import { FC, useState, useContext, useEffect, RefObject } from 'react';
import moment from 'moment';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactGA from 'react-ga4';

// COMPONENTS
import ReactTimeline, {
  TimelineHeaders,
  TimelineMarkers,
  ReactCalendarItemRendererProps,
} from 'react-calendar-timeline';
import { Label } from '../../atoms';
import {
  TimelineItem,
  TimelineItemWithShortenName,
  TimelineYearHeader,
  TimelineMonthHeader,
  TimelineModal,
  BannerMessage,
  TimelineItemWithHiddenName,
  TimelineItemWithRightLabel,
  TimelineItemWithTooltip,
} from '../../molecules';
import Button from '../../atoms/primitive/Button';
import { BannerMessageTypes } from '../../molecules/BannerMessage';
import { Loader } from '../../atoms';
import { TitleWithText } from '../../molecules/TitleWithText';

// STYLES
import 'react-calendar-timeline/lib/Timeline.css';
import './timeline.css';

// STORE
import { ProjectInfoContext } from '../../../store';

// UTILS
import {
  TimelineItem as TimelineItemType,
  useDebounce,
  useEquipmentTypeOptions,
  useTimelineGroups,
  useTimelineItems,
} from '../../../helpers/hooks';
import { parseEquipmentModelOptions } from '../../../api/equipment/equipment-utils';
import { getNumberOfMonthsGrayedOut } from '../../../helpers/functions';
// TYPES:
import {
  handleItemMove,
  handleTimeChange,
  handleItemResize,
  ITEM_WIDTH_BREAKPOINTS,
  highlightActiveTimelineArea,
} from './timeline-item-utils';
import { DataCarryingFormSteps } from '../../../store/config';
import { PowerType, ProjectInfoActionType } from '../../../store/project-info';
import { EQUIPMENT_TYPE } from '../../../constants';

// TRANSLATIONS:
import { months } from '../../../translations';

// ROUTES
import { RootRoutes } from '../../../router';

type TimelineProps = {
  timelineRef: RefObject<HTMLDivElement>;
};

export const Timeline: FC<TimelineProps> = ({ timelineRef }) => {
  const intl = useIntl();
  const history = useHistory();
  const { formatMessage } = useIntl();

  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { projectInfo, equipmentModels, projectId, updateProjectInfo } =
    useContext(ProjectInfoContext);

  const { isFetching: isEquipmentTypeListFetching, data: equipmentData } =
    useEquipmentTypeOptions();

  const modelOptions = parseEquipmentModelOptions(equipmentModels);
  const equipmentOptions = equipmentData || [];

  const groupHeight = 70;
  const headersHeight = 147;
  const timelineWrapperHeight = timelineRef.current?.clientHeight;
  const computedTimelineWrapperHeight =
    (timelineWrapperHeight && timelineWrapperHeight - headersHeight) || 0;
  const defaultAmountOfGroups = Math.floor(computedTimelineWrapperHeight / groupHeight);
  const isTimelineLoading = isEquipmentTypeListFetching;

  const numberOfWeeks = projectInfo[DataCarryingFormSteps.DATE].numberOfWeeks;
  const startDate = projectInfo[DataCarryingFormSteps.DATE].startDate;
  const endDate = moment(projectInfo[DataCarryingFormSteps.DATE].startDate)
    .add(numberOfWeeks, 'weeks')
    .endOf('day');

  const { timelineItems } = useTimelineItems(
    projectInfo.equipmentSchedule,
    startDate,
    updateProjectInfo,
    numberOfWeeks,
    projectId
  );

  const outdatedTimelineItems = timelineItems.filter((item) => item.isItemOutdated);

  const { timelineGroups } = useTimelineGroups(
    projectInfo.equipmentSchedule,
    defaultAmountOfGroups
  );

  const numberOfMonthsAfterProjectEndGrayedOut = getNumberOfMonthsGrayedOut(numberOfWeeks);

  const defaultTimeStart = moment(startDate).add(-1, 'month');
  const defaultTimeEnd = moment(startDate)
    .add(numberOfMonthsAfterProjectEndGrayedOut, 'month')
    .endOf('day');
  const defaultTimeRange = defaultTimeEnd.valueOf() - defaultTimeStart.valueOf();
  const newItemId = timelineItems.length + 1;

  const numberOfProjectDays = endDate.diff(moment(startDate), 'days');

  const debouncedUpdateProjectInfo = useDebounce(updateProjectInfo, 250);

  const onModalClose = () => {
    setModalIsOpen(false);
  };

  const onSelectedItemRemove = () => {
    updateProjectInfo({ payload: selectedItemIndex, type: ProjectInfoActionType.RemoveEquipment });
    onModalClose();
  };

  const odAddItemClick = () => {
    setSelectedItemIndex(newItemId);
    ReactGA.event({
      category: 'stepper',
      label: `button_click`,
      action: `Adding equiptment`,
    });
    updateProjectInfo({
      type: ProjectInfoActionType.AddEquipment,
      payload: {
        id: newItemId,
        startTime: moment(startDate),
        endTime: moment(startDate)
          .add(numberOfWeeks < 12 ? numberOfWeeks : 12, 'weeks')
          .endOf('day'),
        powerType: PowerType.Electric,
        equipmentTypeId: '',
        equipmentModel: {
          id: '',
          equipmentTypeId: '',
          additionalInformation: 0,
          additionalInformationUnit: '',
          powerUsageBattery: null,
          powerUsageGrid: null,
          powerFastCharging: null,
          powerNormalCharging: null,
        },
        numberOfMachines: 1,
        numberOfDaysInUse: 5,
        isItemOutdated: false,
      },
    });
    setModalIsOpen(true);
  };

  const itemRenderer = (itemProps: ReactCalendarItemRendererProps<TimelineItemType>) => {
    const item = projectInfo.equipmentSchedule[itemProps.item.id - 1];
    if (typeof item !== 'undefined') {
      const equipmentItemWidth = itemProps.itemContext.dimensions.width;
      const defaultTitle = formatMessage({ id: 'TIMELINE.DEFAULT.ITEM.TITLE' });
      const isItemOtherType =
        item.equipmentTypeId === EQUIPMENT_TYPE.OTHER || item.powerType === PowerType.Other;
      const itemRenderRange = equipmentItemWidth > ITEM_WIDTH_BREAKPOINTS.WIDE;
      const itemWithShortenNameRenderRange = isItemOtherType
        ? equipmentItemWidth > ITEM_WIDTH_BREAKPOINTS.NARROW
        : equipmentItemWidth <= ITEM_WIDTH_BREAKPOINTS.WIDE &&
          equipmentItemWidth > ITEM_WIDTH_BREAKPOINTS.MEDIUM;
      const itemWithHiddenNameRenderRange =
        equipmentItemWidth <= ITEM_WIDTH_BREAKPOINTS.MEDIUM &&
        equipmentItemWidth > ITEM_WIDTH_BREAKPOINTS.NARROW;
      const itemWithRightLabelRenderRange =
        equipmentItemWidth <= ITEM_WIDTH_BREAKPOINTS.NARROW &&
        equipmentItemWidth > ITEM_WIDTH_BREAKPOINTS.TINY;

      if (itemRenderRange) {
        return TimelineItem(itemProps, item, modelOptions, defaultTitle);
      }
      if (itemWithShortenNameRenderRange) {
        return TimelineItemWithShortenName(itemProps, item, modelOptions, defaultTitle);
      }
      if (!isItemOtherType && itemWithHiddenNameRenderRange) {
        return TimelineItemWithHiddenName(itemProps, item, modelOptions, defaultTitle);
      }
      if (itemWithRightLabelRenderRange) {
        return TimelineItemWithRightLabel(itemProps, item, modelOptions, defaultTitle);
      }
      return TimelineItemWithTooltip(itemProps, item, modelOptions, defaultTitle);
    }
    return null;
  };

  useEffect(() => {
    if (
      projectInfo[DataCarryingFormSteps.DATE].startDate === '' ||
      projectInfo[DataCarryingFormSteps.PROJECT].projectName === ''
    )
      history.push(RootRoutes.GUIDE);
  }, [history, projectInfo]);

  return (
    <>
      <div className='flex flex-row'>
        <div className='flex-grow'>
          <TitleWithText
            className={'mb-10'}
            title={<FormattedMessage id='TIMELINE.TUTORIAL.TITLE' />}
            body={<FormattedMessage id='TIMELINE.TUTORIAL.BODY' />}
          />
        </div>
        <div className='flex-none'>
          <Button onClick={odAddItemClick} variant='primary'>
            <FormattedMessage id='TIMELINE.ADD.EQUIPMENT.BUTTON' />
          </Button>
        </div>
      </div>

      <div className='flex flex-col flex-grow'>
        <div className='flex items-center mb-4'>
          <Label
            className={clsx(
              'flex items-center justify-center text-primary-evinyGreen4 text-h4 ',
              'lg:justify-start'
            )}
          >
            {projectInfo[DataCarryingFormSteps.PROJECT].projectName}
          </Label>
          <Label className='ml-4 px-2 text-primary-evinyGreen4 font-secondaryRegular text-body'>
            <span className='mr-6'>
              {moment(startDate).format('DD.MM.YYYY')}&#8211;{endDate.format('DD.MM.YYYY')}
            </span>
            <span className='mr-6'>
              <FormattedMessage values={{ numberOfWeeks }} {...months[intl.locale]} />
            </span>
            <span>
              {projectInfo[DataCarryingFormSteps.DATE].startWorkingHour}&#8211;
              {projectInfo[DataCarryingFormSteps.DATE].endWorkingHour}
            </span>
          </Label>
        </div>

        <BannerMessage
          type={BannerMessageTypes.ERROR}
          className={clsx(
            outdatedTimelineItems.length === 0 ? 'opacity-0 py-0' : 'opacity-1',
            'transition duration-300'
          )}
        >
          <FormattedMessage
            id='TIMELINE.OUTDATED.ITEMS.ERROR'
            values={{
              outdatedItems: outdatedTimelineItems.length,
              allItems: timelineItems.length,
            }}
          />
        </BannerMessage>
        {isTimelineLoading ? (
          <div className='flex justify-center w-full'>
            <Loader type='Oval' />
          </div>
        ) : (
          <div className='mb-24'>
            <ReactTimeline
              selected={[selectedItemIndex]}
              onItemResize={(itemId, time, edge) => {
                handleItemResize(
                  Number(itemId),
                  time,
                  edge,
                  endDate,
                  startDate,
                  projectInfo,
                  updateProjectInfo
                );
                debouncedUpdateProjectInfo({
                  type: ProjectInfoActionType.CheckForSpecificTimelineItemOutOfRange,
                  payload: {
                    projectStartDate: moment(startDate),
                    projectEndDate: moment(startDate).add(numberOfWeeks, 'weeks').endOf('day'),
                    itemIndex: selectedItemIndex - 1,
                  },
                });
              }}
              onItemMove={(itemId, startTime) => {
                handleItemMove(
                  Number(itemId),
                  startTime,
                  endDate,
                  startDate,
                  projectInfo,
                  updateProjectInfo
                );
                debouncedUpdateProjectInfo({
                  type: ProjectInfoActionType.CheckForSpecificTimelineItemOutOfRange,
                  payload: {
                    projectStartDate: moment(startDate),
                    projectEndDate: moment(startDate).add(numberOfWeeks, 'weeks').endOf('day'),
                    itemIndex: selectedItemIndex - 1,
                  },
                });
              }}
              onItemClick={() => setModalIsOpen(true)}
              onItemSelect={(id) => setSelectedItemIndex(Number(id))}
              onItemDeselect={() => onModalClose()}
              itemHeightRatio={0.7}
              lineHeight={groupHeight}
              sidebarWidth={0}
              canResize='both'
              canMove={true}
              canChangeGroup={false}
              groups={timelineGroups}
              items={timelineItems}
              itemRenderer={itemRenderer}
              minZoom={defaultTimeRange}
              maxZoom={defaultTimeRange}
              defaultTimeStart={defaultTimeStart}
              defaultTimeEnd={defaultTimeEnd}
              resizeDetector={() => {
                return {
                  addEventListener: () => null,
                  removeEventListener: () => null,
                };
              }}
              onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) =>
                handleTimeChange(
                  visibleTimeStart,
                  visibleTimeEnd,
                  updateScrollCanvas,
                  endDate,
                  startDate,
                  defaultTimeRange
                )
              }
            >
              <TimelineHeaders>
                <TimelineYearHeader />
                <TimelineMonthHeader />
              </TimelineHeaders>
              <TimelineMarkers>
                {highlightActiveTimelineArea(numberOfProjectDays, moment(startDate))}
              </TimelineMarkers>
            </ReactTimeline>
          </div>
        )}

        <TimelineModal
          equipmentOptions={equipmentOptions}
          modelOptions={modelOptions}
          projectStartDate={moment(startDate)}
          projectEndDate={moment(endDate)}
          selectedItemIndex={selectedItemIndex - 1}
          isOpen={modalIsOpen}
          onModalClose={onModalClose}
          afterLeave={() => setSelectedItemIndex(0)}
          onSelectedItemRemove={onSelectedItemRemove}
        />
      </div>
    </>
  );
};
