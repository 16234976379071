import { FC } from 'react';
import { IconPropsType } from '../../../types';
import tailwindConfig from '../../../../tailwind.config';

export const MobilkranEquipmentIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.0625 14.1094V16.3867M8.46875 19.5195C8.46875 20.6651 7.54008 21.5937 6.39453 21.5937C5.24898 21.5937 4.32031 20.6651 4.32031 19.5195C4.32031 18.374 5.24898 17.4453 6.39453 17.4453C7.54008 17.4453 8.46875 18.374 8.46875 19.5195ZM8.46875 19.5195H16.8086H8.46875ZM16.8086 19.5195C16.8086 20.6651 17.7373 21.5937 18.8828 21.5937C20.0284 21.5937 20.957 20.6651 20.957 19.5195C20.957 18.374 20.0284 17.4453 18.8828 17.4453C17.7373 17.4453 16.8086 18.374 16.8086 19.5195ZM4.32422 19.5117H1.68359V16.3867H23.5586L4.32422 19.5117ZM23.5586 16.3867V16.918C23.5565 17.6059 23.2821 18.265 22.7952 18.7511C22.3084 19.2372 21.6489 19.5107 20.9609 19.5117L23.5586 16.3867ZM23.5586 16.3867V12.5C23.5592 11.7793 23.4173 11.0656 23.1411 10.3999C22.8649 9.73422 22.4598 9.12969 21.9492 8.62109C20.9268 7.59457 19.5387 7.01574 18.0898 7.01172C17.924 7.01121 17.7597 7.04348 17.6064 7.10672C17.4531 7.16992 17.3138 7.26285 17.1965 7.38012C17.0793 7.49738 16.9863 7.63668 16.9231 7.79C16.8599 7.94332 16.8276 8.10762 16.8281 8.27344V16.3867H23.5586ZM20.3438 7.50781V12.5156H23.5586L20.3438 7.50781ZM11.8437 9.04687H7.30078V14.1094H11.8437L13.5625 13.2422L11.8437 9.04687ZM13.5625 13.2617H14.5742C15.1563 13.2617 15.2383 12.7891 15.2383 12.2031V11.3281C15.2383 10.7461 15.1563 10.2695 14.5742 10.2695H13.5625V13.2617ZM11.3047 9.04687V12.293H7.30078L11.3047 9.04687ZM13.5586 10.2734L11.8477 9.04687L2.70313 3.125V5.21094L13.5586 10.2734ZM2.70703 5.20703C2.9334 5.20703 3.15468 5.27414 3.3429 5.39992C3.53112 5.52566 3.67781 5.70445 3.76444 5.91355C3.85107 6.1227 3.87373 6.35285 3.82957 6.57484C3.78541 6.79688 3.6764 7.00082 3.51634 7.16086C3.35627 7.32094 3.15234 7.42992 2.93032 7.4741C2.7083 7.51828 2.47817 7.49559 2.26904 7.40898C2.0599 7.32234 1.88115 7.17566 1.75539 6.98742C1.62963 6.79922 1.5625 6.57793 1.5625 6.35156L2.70703 5.20703ZM8.47266 14.1094V16.3867V14.1094Z'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
