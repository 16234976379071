import { CustomHeader } from 'react-calendar-timeline';

// STYLES
import tailwindConfig from '../../../../tailwind.config';

export const TimelineMonthHeader = () => (
  <CustomHeader unit='month'>
    {(customHeaderChildrenFnProps) => {
      return (
        customHeaderChildrenFnProps && (
          <div {...customHeaderChildrenFnProps.getRootProps()}>
            {customHeaderChildrenFnProps.headerContext.intervals.map((interval) => {
              const intervalStyle = {
                paddingLeft: '15px',
                borderStyle: interval.startTime.format('MM') === '01' ? 'solid' : 'dashed',
                borderLeftWidth: '1px',
                borderLeftColor: tailwindConfig.theme.extend.colors.other.white,
                borderTopWidth: '1px',
                borderTopStyle: 'solid' as const,
                borderTopColor: tailwindConfig.theme.extend.colors.other.white,
                cursor: 'default',
                backgroundColor: tailwindConfig.theme.extend.colors.other.white,
                fontSize: '14px',
                lineHeight: '30px',
                fontWeight: 300,
              };

              return (
                <div
                  {...customHeaderChildrenFnProps.getIntervalProps({
                    interval,
                    style: intervalStyle,
                  })}
                  key={interval.startTime.format('MMM-YYYY')}
                >
                  {interval.startTime.format('MMM')}
                </div>
              );
            })}
          </div>
        )
      );
    }}
  </CustomHeader>
);
