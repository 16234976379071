import { AVAILABLE_WORKING_HOURS } from '../../constants';
import {
  LocationAddressType,
  LocationCadastreType,
  LocationType,
  ProjectCategoryType,
  ThermalHeatingType,
} from '../../store/project-info';

export type ProjectInfoType = {
  projectType: ProjectCategoryType | '';
  projectName: string;
  startDate: Date | string;
  numberOfWeeks: number;
  startWorkingHour: AVAILABLE_WORKING_HOURS;
  endWorkingHour: AVAILABLE_WORKING_HOURS;
  locationType?: LocationType | '';
  locationCadastre: LocationCadastreType | null;
  locationAddress: LocationAddressType | null;
  areaSize: number | '';
  thermalHeating: ThermalHeatingType | '';
  topLoad: number | '';
  intakeEffect: number | '';
};

export type ProjectInfoPayloadType = {
  contactUserData: ContactUserDataType;
  projectInfo: ProjectInfoType;
  projectPhase: ProjectPhaseType[];
  persistenceTime?: number;
};

export type ProjectCategoriesType = {
  building: string[];
  construction: string[];
};

export type ProjectPhaseType = {
  name: string; // TODO: add project phases enum after MVP
  equipmentSchedule: EquipmentScheduleItemPayloadType[];
  customEquipmentSchedule: CustomEquipmentScheduleItemPayloadType[];
};

export type EquipmentScheduleItemPayloadType = {
  startDate: Date;
  endDate: Date;
  equipmentId: string;
  workingDays: number;
  positionOrder: number;
  quantity: number;
};

export type CustomEquipmentScheduleItemPayloadType = {
  startDate: Date;
  endDate: Date;
  customEquipment: CustomEquipmentModelType;
  workingDays: number;
  positionOrder: number;
  quantity: number;
};

export type CustomEquipmentModelType = {
  model: string;
  type: string;
};

export type ContactUserDataType = {
  firstName: string;
  lastName: string;
  email: string;
  companyNumber: string;
  phoneNumber: string;
  shareInformationAgreement: boolean;
  gdprAgreement: boolean;
  comment: string;
  customerContactAgreement: boolean;
};

export type EvaluationPayload = {
  positive: boolean;
  message: string;
};

export type EnvironmentSavingsType = {
  carTripsRoundTheWorld: number;
  fossilFuelSavings: number;
  co2Savings: number;
};

export enum ThumbValue {
  GOOD = 'GOOD',
  BAD = 'BAD',
}
