import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Button from '../../components/atoms/primitive/Button';

export const NotFound = () => {
  const history = useHistory();

  return (
    <div className='flex justify-center pt-26 h-full overflow-y-auto'>
      <div className='pr-52'>
        <h1 className='text-primary-evinyGreen3 text-6xl'>
          <FormattedMessage id='NOT.FOUND.PAGE.HEADER' />
        </h1>
        <div className='w-96'>
          <p className='py-8 whitespace-pre-line'>
            <FormattedMessage id='NOT.FOUND.PAGE.MESSAGE' />
          </p>
        </div>
        <div className='flex'>
          <Button variant='primary' onClick={() => history.push('/')}>
            <FormattedMessage id='BUTTON.GO.TO.HOME' />
          </Button>
        </div>
      </div>
    </div>
  );
};
