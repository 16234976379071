import { CustomHeader } from 'react-calendar-timeline';

// STYLES
import tailwindConfig from '../../../../tailwind.config';

export const TimelineYearHeader = () => (
  <CustomHeader unit='year'>
    {(customHeaderChildrenFnProps) => {
      return (
        customHeaderChildrenFnProps && (
          <div {...customHeaderChildrenFnProps.getRootProps()}>
            {customHeaderChildrenFnProps.headerContext.intervals.map((interval) => {
              const intervalStyle = {
                border: 'none',
                marginLeft: '-20px',
                cursor: 'default',
                backgroundColor: tailwindConfig.theme.extend.colors.other.white,
                fontSize: '16px',
                lineHeight: '30px',
                fontWeight: 700,
              };

              return (
                <div
                  {...customHeaderChildrenFnProps.getIntervalProps({
                    interval,
                    style: intervalStyle,
                  })}
                  key={interval.startTime.format('YYYY-MMM')}
                >
                  {interval.startTime.format('YYYY')}
                </div>
              );
            })}
          </div>
        )
      );
    }}
  </CustomHeader>
);
