import { FC } from 'react';
import { useIntl } from 'react-intl';
import Chart from 'react-apexcharts';

// STYLING
import tailwindConfig from '../../../../tailwind.config';
import '../PotentialEffectChart/potential-effect-chart.css';

// TYPES
import { KPI8ChartPeakType } from '../../../api/chart/types';

// UTILS
import { formatMaxRequiredPowerToChartSeries } from '../../../helpers/functions/charts';
import { DailyReportChartTooltip } from '../../molecules';

const DAILY_REPORT_CHART_ID = 'daily-report-chart';

export type DailyReportChartProps = { chartData: KPI8ChartPeakType; chartType?: 'area' | 'line' };

export const DailyReportChart: FC<DailyReportChartProps> = ({ chartData, chartType = 'line' }) => {
  const { formatMessage } = useIntl();

  const axisTextStyle = {
    colors: [tailwindConfig.theme.extend.colors.primary.evinyGreen4],
    fontSize: tailwindConfig.theme.extend.fontSize['body'][0],
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 300,
  };

  const seriesArray = [
    {
      name: formatMessage({ id: 'REPORT.DAIlY.REPORTS.CHART.YAXIS.TEXT' }),
      data: formatMaxRequiredPowerToChartSeries(
        chartData.hours && chartData.hours.length ? chartData.hours : []
      ),
    },
  ];

  return (
    <Chart
      options={{
        markers: {
          strokeColors: tailwindConfig.theme.extend.colors.other.white,
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        colors: [tailwindConfig.theme.extend.colors.secondary.evinyWarm4],
        stroke: {
          width: 3,
        },
        chart: {
          id: DAILY_REPORT_CHART_ID,
          toolbar: {
            tools: { download: true },
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          labels: {
            style: axisTextStyle,
            offsetY: 5,
            formatter: (val) => (parseInt(val) % 2 === 0 ? val : ''), // Only show every other label on the x axis to make it less crowded
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            height: 10,
          },
          title: {
            text: formatMessage({ id: 'REPORT.DAIlY.REPORTS.X.AXIS.TEXT' }),
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: axisTextStyle,
            offsetX: -2,
            offsetY: 4,
            formatter: (val) => val.toFixed(),
          },
          tickAmount: 9,
          min: 0,
          forceNiceScale: true,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          horizontalAlign: 'left',
          fontSize: tailwindConfig.theme.extend.fontSize['body'][0],
          fontWeight: 400,
          fontFamily: axisTextStyle.fontFamily,
          offsetY: 10,
          itemMargin: {
            vertical: 5,
          },
          labels: {
            useSeriesColors: true,
          },
          markers: { width: 60, height: 4, radius: 0, offsetX: -8, offsetY: -3 },
        },
        tooltip: {
          custom: ({ dataPointIndex }) => {
            return DailyReportChartTooltip(
              formatMessage({ id: 'REPORT.DAIlY.REPORTS.TOOLTIP.TEXT' }),
              dataPointIndex + 1,
              chartData.hours[dataPointIndex]
            );
          },
        },
      }}
      series={seriesArray}
      height={450}
      type={chartType}
    />
  );
};
