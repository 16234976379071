import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const RightArrow: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='14'
      height='25'
      viewBox='0 0 14 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.6776 12.5056L0.811732 1.64186C0.43609 1.26624 0.43609 0.657259 0.811732 0.281634C1.18749 -0.093878 1.79653 -0.093878 2.17223 0.281634L13.7183 11.8254C14.0939 12.2011 14.0939 12.81 13.7183 13.1857L2.17223 24.7295C1.78999 25.0986 1.18089 25.088 0.811732 24.7058C0.45165 24.333 0.45165 23.742 0.811732 23.3693L11.6776 12.5056Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen2}
      />
    </svg>
  );
};
