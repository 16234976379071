import { FC } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

// COMPONENTS
import { Divider, InternalLink } from '../../atoms';

// ROUTING
import { RootRoutes } from '../../../router';

// TYPES
import { useConfigStore } from '../../../store';
import { ConfigActionType, AdditionalFormSteps } from '../../../store/config';

type TabElementWithPathProps = {
  tabNumber: string;
  pathTo: string;
  isActive: boolean;
  isValid?: boolean;
  disabled?: boolean;
  className?: string;
  children: React.ReactNode;
};

export const TabElementWithPath: FC<TabElementWithPathProps> = ({
  children,
  className,
  tabNumber,
  pathTo,
  isValid,
  isActive,
  disabled,
}) => {
  const history = useHistory();
  const { updateConfig } = useConfigStore();

  const goToGuide = () => {
    updateConfig({
      type: ConfigActionType.UPDATE_FORM_STEP,
      payload: AdditionalFormSteps.OVERVIEW,
    });
    history.push(RootRoutes.GUIDE);
  };

  return (
    <InternalLink
      onClick={pathTo === RootRoutes.GUIDE ? goToGuide : undefined}
      href={pathTo}
      className={clsx(
        disabled && 'text-primary-evinyGreen4',
        'my-0.5 max-w-min font-secondaryMedium text-menuLink bg-opacity-0',
        className
      )}
    >
      <span
        className={clsx(
          isValid && isActive ? 'text-primary-evinyGreen4' : 'text-other-gray',
          'pr-2'
        )}
      >
        {tabNumber}
      </span>
      <span className={clsx(isValid && isActive ? 'text-primary-evinyGreen4' : 'text-other-gray')}>
        {children}
      </span>
      {!disabled && isValid && (
        <Divider
          className={clsx(
            'my-2',
            isValid ? 'border-primary-evinyGreen2' : 'border-primary-evinyGreen4'
          )}
        />
      )}
    </InternalLink>
  );
};
