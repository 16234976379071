import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { intl } from '../translations';
import * as Sentry from '@sentry/browser';
import { serviceHubApiUrl } from '../utils/env';

const defaultValues = {
  baseURL: serviceHubApiUrl,
};


export const axiosClient = axios.create(defaultValues);

const onSuccess = (response: AxiosResponse) => response;
const onError = (error: AxiosError<{ message: string }>) => {
  // Log server error to sentry
  Sentry.withScope((scope) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    scope.setExtras(error as any);
    Sentry.captureException(
      new Error(
        `${error.config?.url} failed: (${error.response?.status}): ${error.response?.data.message}`
      )
    );
  });

  switch (error.response?.status) {
    case 404: {
      toast.error(intl.formatMessage({ id: 'API.RESPONSE.ERROR.404' }), {
        toastId: 'API.RESPONSE.ERROR.404',
        icon: false,
      });
      break;
    }
    case 500: {
      toast.error(intl.formatMessage({ id: 'API.RESPONSE.ERROR.500' }), {
        toastId: 'API.RESPONSE.ERROR.500',
        icon: false,
      });
      window.location.href = '/feil';
      break;
    }
    default: {
      toast.error(intl.formatMessage({ id: 'API.RESPONSE.ERROR.OTHER' }), {
        toastId: 'API.RESPONSE.ERROR.OTHER',
        icon: false,
      });
    }
  }
  return Promise.reject(error.response || error.message);
};

const request = (options: AxiosRequestConfig) =>
  axiosClient(options).then(onSuccess).catch(onError);

export default request;

type APICall<T> = Promise<AxiosResponse<T>>;

export type { APICall };
