// TYPES
import { ReactCalendarItemRendererProps } from 'react-calendar-timeline';
import { EquipmentScheduleItemType } from '../../store/project-info';
import { TimelineItem } from '../hooks';

// STYLES
import tailwindConfig from '../../../tailwind.config';
import { getEquipmentIconFromId } from '.';

export const defineTimelineItemStyle = (
  singleTimelineItem: EquipmentScheduleItemType,
  { itemContext, getResizeProps }: ReactCalendarItemRendererProps<TimelineItem>
) => {
  const border = `${singleTimelineItem.equipmentTypeId && !itemContext.selected ? '0' : '1px'} ${
    itemContext.selected ? 'solid' : 'dashed'
  }`;
  const boxShadow = itemContext.selected
    ? tailwindConfig.theme.extend.boxShadow.modalButton
    : undefined;
  const cursor = itemContext.selected
    ? itemContext.resizing
      ? 'ew-resize'
      : itemContext.dragging
      ? 'grabbing'
      : 'grab'
    : undefined;
  const color = singleTimelineItem.isItemOutdated
    ? tailwindConfig.theme.extend.colors.other.white
    : singleTimelineItem.equipmentTypeId
    ? tailwindConfig.theme.extend.colors.other.black
    : tailwindConfig.theme.extend.colors.other.gray;
  const background = singleTimelineItem.isItemOutdated
    ? tailwindConfig.theme.extend.colors.other.errorRed
    : singleTimelineItem.equipmentTypeId
    ? tailwindConfig.theme.extend.colors.primary.evinyGreen1
    : tailwindConfig.theme.extend.colors.other.white;
  const style = {
    border,
    boxShadow,
    borderRadius: '100px',
    fontSize: '16px',
    fontWeight: 300,
    cursor,
    color,
    background,
  };
  const equipmentIcon = getEquipmentIconFromId(singleTimelineItem.equipmentTypeId);
  const resizeAreaStyle = { cursor: itemContext.selected ? 'ew-resize' : 'default', width: '20px' };
  const { left: leftResizeProps, right: rightResizeProps } = getResizeProps({
    leftStyle: { ...resizeAreaStyle, left: -3 },
    rightStyle: { ...resizeAreaStyle, right: -3 },
  });

  return { style, equipmentIcon, leftResizeProps, rightResizeProps };
};
