import { LocationCadastreType } from '../../store/project-info';

export const prepareCadastralInformationForDisplay = ({
  municipality,
  cadastralUnitNumber,
  propertyUnitNumber,
  leaseNumber,
  unitNumber,
}: LocationCadastreType) => {
  let result = `${municipality} - ${cadastralUnitNumber} / ${propertyUnitNumber}`;
  if (leaseNumber || unitNumber) {
    result += ` / `;
    if (leaseNumber && unitNumber) {
      return result + `${leaseNumber} - ${unitNumber}`;
    }
    return result + (leaseNumber ?? '') + (unitNumber ?? '');
  }
  return result;
};
