import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { getMaxPotentialEffect } from '../../api/chart';
import { ProjectInfoPayloadType } from '../../api/project-info/types';
import { formatMaxPotentialEffectApiResponse } from '../functions/charts';
import { QueryKeysNames } from '../../api/types';

export const useMaxPotentialChartData = (projectInfo: ProjectInfoPayloadType) => {
  const intakeEffect =
    projectInfo.projectInfo.intakeEffect !== '' ? projectInfo.projectInfo.intakeEffect : undefined;

  const topLoad =
    projectInfo.projectInfo.topLoad !== '' ? projectInfo.projectInfo.topLoad : undefined;

  return useQuery(
    [QueryKeysNames.MAX_POTENTIAL_EFFECT],
    async () => await getMaxPotentialEffect(projectInfo),
    {
      select: (res) =>
        formatMaxPotentialEffectApiResponse(
          res.data.phase[0],
          moment(projectInfo.projectInfo.startDate),
          intakeEffect,
          topLoad
        ),
    }
  );
};
