import { FC } from 'react';
import { GoogleMap as ReactGoogleMap, Marker } from '@react-google-maps/api';
import { Loader } from '../../atoms';

type GoogleMapType = {
  isLoaded: boolean;
  className?: string;
  visibleMarker?: boolean;
  position?: google.maps.LatLngLiteral;
};

export const GoogleMapWithoutInput: FC<GoogleMapType> = ({
  className,
  isLoaded,
  position,
  visibleMarker = true,
}) => {
  return isLoaded ? (
    <ReactGoogleMap
      id='searchbox'
      mapContainerClassName={className}
      center={position}
      mapTypeId='hybrid'
      zoom={15}
    >
      {position && <Marker visible={visibleMarker} position={position} />}
    </ReactGoogleMap>
  ) : (
    <div className='flex justify-center w-full'>
      <Loader type='Oval' />
    </div>
  );
};
