import { FC } from 'react';
import { IconPropsType } from '../../../types';
import tailwindConfig from '../../../../tailwind.config';

export const TarnkranEquipmentIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.582 24.2305H6.60156C6.49797 24.2305 6.39859 24.1893 6.32535 24.1161C6.25211 24.0428 6.21094 23.9434 6.21094 23.8398C6.21094 23.7363 6.25211 23.6369 6.32535 23.5636C6.39859 23.4904 6.49797 23.4492 6.60156 23.4492H17.582C17.6856 23.4492 17.785 23.4904 17.8582 23.5636C17.9315 23.6369 17.9727 23.7363 17.9727 23.8398C17.9727 23.9434 17.9315 24.0428 17.8582 24.1161C17.785 24.1893 17.6856 24.2305 17.582 24.2305Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M14.3984 24.2305C14.2948 24.2305 14.1955 24.1893 14.1222 24.1161C14.049 24.0428 14.0078 23.9434 14.0078 23.8398V9.71094C14.0078 9.60734 14.049 9.50801 14.1222 9.43473C14.1955 9.36148 14.2948 9.32031 14.3984 9.32031C14.502 9.32031 14.6014 9.36148 14.6746 9.43473C14.7479 9.50801 14.7891 9.60734 14.7891 9.71094V23.8398C14.7891 23.9434 14.7479 24.0428 14.6746 24.1161C14.6014 24.1893 14.502 24.2305 14.3984 24.2305Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M23.832 8.44532H15.2148C15.1113 8.44532 15.0119 8.40419 14.9386 8.3309C14.8654 8.25766 14.8242 8.15829 14.8242 8.05469C14.8242 7.9511 14.8654 7.85176 14.9386 7.77848C15.0119 7.70524 15.1113 7.66407 15.2148 7.66407H23.0508L21.3281 5.44922H15.2148C15.1113 5.44922 15.0119 5.40809 14.9386 5.33481C14.8654 5.26157 14.8242 5.16219 14.8242 5.0586C14.8242 4.95501 14.8654 4.85567 14.9386 4.78239C15.0119 4.70915 15.1113 4.66797 15.2148 4.66797H21.5078C21.5671 4.66766 21.6258 4.68087 21.6793 4.70657C21.7327 4.73231 21.7796 4.76989 21.8164 4.81641L24.1602 7.81251C24.207 7.87153 24.2357 7.94282 24.243 8.01782C24.2502 8.09278 24.2356 8.16825 24.2009 8.23516C24.1663 8.30204 24.113 8.35743 24.0475 8.39473C23.9821 8.43204 23.9073 8.44962 23.832 8.44532Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M2.73828 8.44531C2.63468 8.44531 2.53532 8.40418 2.46207 8.3309C2.38881 8.25766 2.34766 8.15828 2.34766 8.05469V5.05859C2.34766 4.955 2.38881 4.85566 2.46207 4.78238C2.53532 4.70914 2.63468 4.66797 2.73828 4.66797H8.98828C9.09187 4.66797 9.19125 4.70914 9.26449 4.78238C9.33777 4.85566 9.3789 4.955 9.3789 5.05859C9.3789 5.16219 9.33777 5.26156 9.26449 5.3348C9.19125 5.40809 9.09187 5.44922 8.98828 5.44922H3.12891V7.66406H8.98828C9.09187 7.66406 9.19125 7.70523 9.26449 7.77848C9.33777 7.85176 9.3789 7.95109 9.3789 8.05469C9.3789 8.15828 9.33777 8.25766 9.26449 8.3309C9.19125 8.40418 9.09187 8.44531 8.98828 8.44531H2.73828Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M15.2148 10.1016H8.96484C8.86125 10.1016 8.76188 10.0604 8.68863 9.98715C8.61539 9.91391 8.57422 9.81453 8.57422 9.71094V3.46095C8.57422 3.35735 8.61539 3.258 8.68863 3.18474C8.76188 3.11148 8.86125 3.07033 8.96484 3.07033H15.2148C15.3184 3.07033 15.4178 3.11148 15.4911 3.18474C15.5643 3.258 15.6055 3.35735 15.6055 3.46095V9.71094C15.6055 9.81453 15.5643 9.91391 15.4911 9.98715C15.4178 10.0604 15.3184 10.1016 15.2148 10.1016ZM9.35547 9.32032H14.8242V3.85158H9.35547V9.32032Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M15.2148 6.94532H12.0898C11.9863 6.94532 11.8869 6.90418 11.8136 6.8309C11.7404 6.75766 11.6992 6.65828 11.6992 6.55469V3.45314C11.6992 3.34954 11.7404 3.25018 11.8136 3.17693C11.8869 3.10367 11.9863 3.06252 12.0898 3.06252C12.1934 3.06252 12.2928 3.10367 12.3661 3.17693C12.4393 3.25018 12.4805 3.34954 12.4805 3.45314V6.16407H15.2148C15.3184 6.16407 15.4178 6.20524 15.4911 6.27848C15.5643 6.35176 15.6055 6.4511 15.6055 6.55469C15.6055 6.65828 15.5643 6.75766 15.4911 6.8309C15.4178 6.90418 15.3184 6.94532 15.2148 6.94532Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M13.2578 3.84377C13.1542 3.84377 13.0548 3.80261 12.9816 3.72936C12.9084 3.6561 12.8672 3.55674 12.8672 3.45314V1.57423H11.2695V3.45314C11.2695 3.55674 11.2284 3.6561 11.1551 3.72936C11.0819 3.80261 10.9825 3.84377 10.8789 3.84377C10.7753 3.84377 10.6759 3.80261 10.6027 3.72936C10.5295 3.6561 10.4883 3.55674 10.4883 3.45314V1.18361C10.4883 1.08001 10.5295 0.980652 10.6027 0.907394C10.6759 0.83414 10.7753 0.792984 10.8789 0.792984H13.2578C13.3614 0.792984 13.4608 0.83414 13.534 0.907394C13.6073 0.980652 13.6484 1.08001 13.6484 1.18361V3.45314C13.6484 3.55674 13.6073 3.6561 13.534 3.72936C13.4608 3.80261 13.3614 3.84377 13.2578 3.84377Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M21.7773 13.2461C21.6738 13.2461 21.5744 13.205 21.5011 13.1317C21.4279 13.0584 21.3867 12.9591 21.3867 12.8555V8.05469C21.3867 7.95109 21.4279 7.85176 21.5011 7.77848C21.5744 7.70523 21.6738 7.66406 21.7773 7.66406C21.8809 7.66406 21.9803 7.70523 22.0536 7.77848C22.1268 7.85176 22.168 7.95109 22.168 8.05469V12.8555C22.168 12.9591 22.1268 13.0584 22.0536 13.1317C21.9803 13.205 21.8809 13.2461 21.7773 13.2461Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M21.7773 15.5312C21.3716 15.5302 20.9827 15.3686 20.6957 15.0816C20.4088 14.7947 20.2471 14.4058 20.2461 14C20.2461 13.8964 20.2873 13.7971 20.3605 13.7238C20.4337 13.6505 20.5331 13.6094 20.6367 13.6094C20.7403 13.6094 20.8397 13.6505 20.9129 13.7238C20.9862 13.7971 21.0273 13.8964 21.0273 14C21.0281 14.1482 21.0728 14.2928 21.1556 14.4156C21.2385 14.5384 21.3559 14.6339 21.493 14.6901C21.6302 14.7463 21.7808 14.7606 21.9261 14.7312C22.0713 14.7018 22.2045 14.6301 22.309 14.525C22.4136 14.42 22.4846 14.2864 22.5132 14.141C22.5418 13.9956 22.5268 13.845 22.4699 13.7082C22.413 13.5714 22.3169 13.4545 22.1936 13.3722C22.0704 13.29 21.9255 13.2461 21.7773 13.2461C21.6737 13.2461 21.5744 13.205 21.5011 13.1317C21.4279 13.0584 21.3867 12.9591 21.3867 12.8555C21.3867 12.7519 21.4279 12.6525 21.5011 12.5793C21.5744 12.506 21.6737 12.4648 21.7773 12.4648C22.1706 12.4847 22.5412 12.6549 22.8125 12.9403C23.0838 13.2256 23.2351 13.6043 23.2351 13.998C23.2351 14.3918 23.0838 14.7705 22.8125 15.0559C22.5412 15.3412 22.1706 15.5114 21.7773 15.5312Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M5.86329 10.4375H1.17579C1.07219 10.4375 0.972832 10.3964 0.899574 10.3231C0.82632 10.2498 0.785164 10.1505 0.785164 10.0469V8.05468C0.785164 7.95109 0.82632 7.85175 0.899574 7.77847C0.972832 7.70523 1.07219 7.66406 1.17579 7.66406H2.57032L5.57422 4.77734C5.64688 4.70808 5.7434 4.66945 5.84375 4.66945C5.94414 4.66945 6.04063 4.70808 6.11329 4.77734L9.23829 7.77343C9.31106 7.84664 9.35188 7.94562 9.35188 8.04882C9.35188 8.15203 9.31106 8.25105 9.23829 8.32421C9.20196 8.36085 9.15875 8.38992 9.11118 8.40972C9.06356 8.42957 9.0125 8.43976 8.96094 8.43976C8.90938 8.43976 8.85833 8.42957 8.81071 8.40972C8.76313 8.38992 8.71993 8.36085 8.6836 8.32421L5.83985 5.58984L3.69532 7.65234H5.86329C5.96688 7.65234 6.06625 7.69351 6.1395 7.76675C6.21278 7.84003 6.25391 7.93937 6.25391 8.04296V10.0352C6.25547 10.0875 6.24653 10.1395 6.22762 10.1882C6.20868 10.237 6.18016 10.2815 6.14371 10.319C6.10731 10.3565 6.06372 10.3863 6.01555 10.4067C5.96735 10.4271 5.91559 10.4375 5.86329 10.4375ZM1.56641 9.65625H5.47266V8.44531H1.56641V9.65625Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M19.0352 8.21484C18.9457 8.21523 18.8588 8.18488 18.7891 8.1289L15.3789 5.36328C15.3389 5.33097 15.3056 5.29109 15.2811 5.24593C15.2564 5.20074 15.241 5.15117 15.2355 5.10004C15.2301 5.0489 15.2348 4.99718 15.2493 4.94785C15.2639 4.89851 15.288 4.85254 15.3203 4.8125C15.3526 4.7725 15.3925 4.73925 15.4377 4.71464C15.4828 4.69004 15.5324 4.67457 15.5836 4.66914C15.6347 4.66371 15.6864 4.6684 15.7358 4.68293C15.7851 4.69746 15.8311 4.7216 15.8711 4.7539L18.9961 7.28515L21.2227 4.79687C21.2931 4.72586 21.3881 4.68453 21.4881 4.68148C21.5882 4.67843 21.6855 4.7139 21.7602 4.7805C21.8348 4.84715 21.881 4.93988 21.8893 5.03957C21.8976 5.13929 21.8673 5.23839 21.8047 5.3164L19.3281 8.08203C19.2915 8.12371 19.2464 8.15711 19.1959 8.18C19.1454 8.20289 19.0906 8.21476 19.0352 8.21484Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M9.73047 24.2305C9.62688 24.2305 9.5275 24.1893 9.45426 24.1161C9.38102 24.0428 9.33984 23.9434 9.33984 23.8398V9.71094C9.33984 9.60734 9.38102 9.50801 9.45426 9.43473C9.5275 9.36148 9.62688 9.32031 9.73047 9.32031C9.83406 9.32031 9.93344 9.36148 10.0067 9.43473C10.08 9.50801 10.1211 9.60734 10.1211 9.71094V23.8398C10.1211 23.9434 10.08 24.0428 10.0067 24.1161C9.93344 24.1893 9.83406 24.2305 9.73047 24.2305Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M14.3984 24.25C14.3072 24.2502 14.2187 24.2184 14.1484 24.1602L9.48046 20.2539C9.4023 20.1872 9.35355 20.0925 9.3448 19.9901C9.33601 19.8877 9.36793 19.7861 9.43359 19.707C9.4664 19.6675 9.50668 19.6348 9.5521 19.6108C9.59757 19.5869 9.6473 19.5721 9.69843 19.5674C9.7496 19.5627 9.80121 19.5681 9.85027 19.5833C9.89933 19.5986 9.94492 19.6234 9.98437 19.6563L14.6719 23.5625C14.7326 23.6144 14.7759 23.6837 14.796 23.7609C14.8161 23.8382 14.8121 23.9198 14.7844 23.9947C14.7568 24.0696 14.7068 24.1343 14.6413 24.1799C14.5758 24.2256 14.4978 24.25 14.418 24.25H14.3984Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M14.3984 14.3906C14.3011 14.3893 14.2078 14.3517 14.1367 14.2852L9.46875 9.98828C9.39207 9.91836 9.34633 9.82086 9.3416 9.71719C9.33683 9.61351 9.37344 9.51223 9.44336 9.43555C9.51328 9.3589 9.61082 9.31316 9.71445 9.3084C9.81812 9.30363 9.91941 9.34023 9.99609 9.41015L14.6836 13.707C14.7421 13.7602 14.7831 13.83 14.8012 13.907C14.8193 13.9839 14.8135 14.0646 14.7847 14.1383C14.7559 14.212 14.7054 14.2752 14.64 14.3195C14.5745 14.3639 14.4971 14.3873 14.418 14.3867L14.3984 14.3906Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M9.73047 14.3906C9.67664 14.39 9.62348 14.3782 9.57442 14.356C9.52535 14.3338 9.48141 14.3017 9.44532 14.2617C9.37559 14.1855 9.33891 14.0847 9.34328 13.9815C9.3477 13.8783 9.39282 13.781 9.46875 13.7109L14.1563 9.41407C14.1942 9.37946 14.2386 9.35266 14.287 9.33516C14.3353 9.3177 14.3866 9.30993 14.4379 9.31231C14.4892 9.31465 14.5396 9.32711 14.5861 9.34891C14.6326 9.37075 14.6744 9.40153 14.709 9.43946C14.7436 9.47743 14.7704 9.52184 14.7879 9.57016C14.8054 9.61848 14.8131 9.66977 14.8108 9.7211C14.8084 9.77243 14.796 9.82278 14.7741 9.8693C14.7523 9.91583 14.7216 9.95758 14.6836 9.99219L9.9961 14.2891C9.92348 14.3551 9.82864 14.3914 9.73047 14.3906Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M9.73047 24.25C9.65094 24.2492 9.57356 24.2242 9.50863 24.1782C9.44375 24.1323 9.39442 24.0676 9.36723 23.9929C9.34004 23.9182 9.33633 23.8369 9.35656 23.76C9.3768 23.6831 9.42004 23.6142 9.48047 23.5625L14.168 19.6562C14.2472 19.5894 14.3498 19.5568 14.4531 19.5656C14.5564 19.5744 14.6519 19.6239 14.7187 19.7031C14.7856 19.7824 14.8182 19.885 14.8094 19.9882C14.8006 20.0915 14.7511 20.1871 14.6719 20.2539L10.0039 24.1601C9.92754 24.2238 9.82973 24.2559 9.73047 24.25Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M14.3984 19.3203C14.3072 19.3205 14.2187 19.2887 14.1484 19.2305L9.48047 15.3047C9.40121 15.2379 9.35176 15.1423 9.34297 15.039C9.33418 14.9357 9.36676 14.8332 9.43359 14.7539C9.50043 14.6746 9.59598 14.6252 9.6993 14.6164C9.80258 14.6076 9.90512 14.6402 9.98438 14.707L14.6719 18.6133C14.7111 18.6464 14.7435 18.6868 14.7671 18.7324C14.7907 18.778 14.8051 18.8278 14.8095 18.879C14.8138 18.9301 14.8081 18.9816 14.7925 19.0305C14.7769 19.0794 14.7519 19.1248 14.7188 19.1641C14.6816 19.2137 14.6332 19.2537 14.5775 19.2809C14.5218 19.308 14.4604 19.3216 14.3984 19.3203Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M9.73047 19.3203C9.67375 19.3199 9.61781 19.307 9.56652 19.2827C9.51527 19.2584 9.46988 19.2233 9.43359 19.1797C9.40047 19.1405 9.37543 19.0951 9.35984 19.0462C9.3443 18.9973 9.33851 18.9457 9.34285 18.8946C9.34723 18.8434 9.3616 18.7936 9.38523 18.7481C9.40883 18.7025 9.44121 18.662 9.48047 18.6289L14.168 14.7227C14.2472 14.6559 14.3498 14.6232 14.4531 14.632C14.5564 14.6408 14.6519 14.6903 14.7187 14.7695C14.7856 14.8488 14.8182 14.9514 14.8094 15.0547C14.8006 15.1579 14.7511 15.2535 14.6719 15.3203L9.98437 19.2305C9.91226 19.2882 9.82281 19.3198 9.73047 19.3203Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M5.86329 12.4336H1.17579C1.07219 12.4336 0.972832 12.3925 0.899574 12.3192C0.82632 12.2459 0.785164 12.1466 0.785164 12.043V10.0469C0.785164 9.94328 0.82632 9.84395 0.899574 9.77066C0.972832 9.69742 1.07219 9.65625 1.17579 9.65625H5.86329C5.96688 9.65625 6.06625 9.69742 6.1395 9.77066C6.21278 9.84395 6.25391 9.94328 6.25391 10.0469V12.043C6.25391 12.1466 6.21278 12.2459 6.1395 12.3192C6.06625 12.3925 5.96688 12.4336 5.86329 12.4336ZM1.56641 11.6523H5.47266V10.4375H1.56641V11.6523Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
    </svg>
  );
};
