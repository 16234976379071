import { useContext, useMemo } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

// STORE
import { useConfigStore } from '../../../store';

// TYPES
import { AreaStepInterface, ProjectInfoContext } from '../../../store/project-info';
import { ConfigActionType, DataCarryingFormSteps } from '../../../store/config';

export interface UseAreaStep {
  formik: FormikProps<AreaStepInterface>;
  goToNextStep: () => void;
  backToPreviousStep: () => void;
}

export const useAreaStep = (): UseAreaStep => {
  const { formatMessage } = useIntl();
  const { projectInfo } = useContext(ProjectInfoContext);
  const { updateConfig } = useConfigStore();

  const validationSchema = Yup.object().shape({
    areaSize: Yup.number()
      .integer(formatMessage({ id: 'ERROR.FIELD.INTEGER' }))
      .positive(formatMessage({ id: 'ERROR.FIELD.MIN.VALUE' })),
  });

  const initialValues = useMemo(
    () => ({
      areaSize: projectInfo[DataCarryingFormSteps.AREA].areaSize,
      intakeEffect: projectInfo[DataCarryingFormSteps.AREA].intakeEffect,
      topLoad: projectInfo[DataCarryingFormSteps.AREA].topLoad,
      completed: projectInfo[DataCarryingFormSteps.AREA].completed,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const formik = useFormik<AreaStepInterface>({
    initialValues,
    validationSchema,
    onSubmit: () => {
      updateConfig({
        type: ConfigActionType.UPDATE_FORM_STEP,
        payload: DataCarryingFormSteps.HEATING,
      });
    },
    validateOnMount: true,
  });

  const goToNextStep = () => {
    formik.submitForm();
  };

  const backToPreviousStep = () => {
    if (formik.isValid) {
      updateConfig({
        type: ConfigActionType.UPDATE_FORM_STEP,
        payload: DataCarryingFormSteps.ADDRESS,
      });
    } else {
      formik.submitForm();
    }
  };

  return { formik, goToNextStep, backToPreviousStep };
};
