import { InputHTMLAttributes, FC } from 'react';
import { ErrorMessage, Field } from 'formik';
import clsx from 'clsx';
import './form-button.css';

type FormButtonType = {
  label: string;
  name: string;
  showError?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const FormButton: FC<FormButtonType> = ({
  className,
  label,
  disabled,
  name,
  showError = true,
  ...props
}) => {
  return (
    <div className='form-button relative'>
      <label
        className={clsx(
          'flex items-center justify-center text-h4 cursor-pointer',
          !disabled ? 'hover:opacity-50 transition' : 'opacity-30 cursor-default',
          className
        )}
      >
        <Field type='radio' className='form-button' name={name} {...props} />
        {label}
      </label>

      {showError && (
        <ErrorMessage
          component='div'
          className={clsx(
            'absolute bottom-0 left-0 right-0 -mb-4 text-other-errorRed text-inputLabel ',
            'lg:left-0'
          )}
          name={name}
        />
      )}
    </div>
  );
};
