import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const InteractiveElementNotClicked: FC<IconPropsType> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='28'
      height='28'
      viewBox='0 0 28 28'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.47168'
        y='0.636719'
        width='26.5625'
        height='26.5625'
        rx='13.2812'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
      />
      <path
        d='M18.2988 13.1074H14.5635V9.37207C14.5635 9.16455 14.356 8.95703 14.1484 8.95703H13.3184C13.0849 8.95703 12.9033 9.16455 12.9033 9.37207V13.1074H9.16797C8.93451 13.1074 8.75293 13.3149 8.75293 13.5225V14.3525C8.75293 14.586 8.93451 14.7676 9.16797 14.7676H12.9033V18.5029C12.9033 18.7364 13.0849 18.918 13.3184 18.918H14.1484C14.356 18.918 14.5635 18.7364 14.5635 18.5029V14.7676H18.2988C18.5063 14.7676 18.7139 14.586 18.7139 14.3525V13.5225C18.7139 13.3149 18.5063 13.1074 18.2988 13.1074Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
    </svg>
  );
};
