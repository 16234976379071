import clsx from 'clsx';
import { FC, ReactNode, SelectHTMLAttributes } from 'react';

// TYPES:
import { SingleDropdownOptionType } from '../../../types';
import { Label } from '../../atoms';
import Select from '../Select';

type SelectWithLabelType = {
  name: string;
  showArrow?: boolean;
  containerClassName?: string;
  dropDownClassName?: string;
  activeArrowColor?: string;
  inActiveArrowColor?: string;
  label: string | ReactNode;
  className?: string;
  showError?: boolean;
  additionalSideOnChange?: () => void;
  options: SingleDropdownOptionType[];
  isDataLoading?: boolean;
  placeholder?: string;
} & SelectHTMLAttributes<HTMLSelectElement>;

const SelectWithLabel: FC<SelectWithLabelType> = ({ label, disabled, isDataLoading, ...props }) => {
  return (
    <>
      <Label
        className={clsx(
          'block float-left mb-2 mt-4 text-primary-evinyGreen3 font-secondaryMedium text-inputLabel ',
          disabled && 'opacity-30'
        )}
      >
        {label}
      </Label>
      <Select disabled={disabled} isDataLoading={isDataLoading} {...props} />
    </>
  );
};

export default SelectWithLabel;
