import { FC, ReactNode, useState } from 'react';
import { ArrowContainer, Popover, PopoverPosition } from 'react-tiny-popover';
import { ExitIcon } from '../../../assets/images';
import tailwindConfig from '../../../../tailwind.config';

// STYLES:
import './popover.css';

// ICONS:

type PopOverType = {
  title?: string | ReactNode;
  description?: string | ReactNode;
  icon?: ReactNode;
  className?: string;
  positions?: PopoverPosition[];
  footerPopover?: boolean;
  children: ReactNode;
};

export const PopOver: FC<PopOverType> = ({
  title,
  description,
  icon,
  positions = ['bottom'],
  className,
  children,
  footerPopover,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handlePopoverClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsPopoverOpen(!isPopoverOpen);
  };

  const popoverContent = (
    <div
      className={`popover-box p-4 w-max text-other-white font-secondaryRegular text-body bg-primary-darkBackground rounded-xl shadow-popover`}
    >
      <div className='relative flex items-center justify-between'>
        {icon}
        <h4 className='pl-4 text-h4'>{title}</h4>
        <button
          className='pointer ml-6 p-2 w-6 hover:opacity-50 transition duration-200'
          onClick={handlePopoverClick}
          aria-label='close-tooltip'
        >
          <ExitIcon />
        </button>
      </div>
      <div className='pt-3 whitespace-pre-wrap leading-8'>{description}</div>
    </div>
  );

  return (
    <div className={`${className}`}>
      <Popover
        isOpen={isPopoverOpen}
        padding={5}
        containerClassName={footerPopover ? 'footerPopover' : ''}
        align={'center'}
        positions={positions}
        reposition={false}
        content={({ position, childRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            childRect={childRect}
            popoverRect={popoverRect}
            className='animate-show'
            arrowSize={14}
            arrowClassName='my-0.5 '
            arrowColor={tailwindConfig.theme.extend.colors.primary.darkBackground}
          >
            {popoverContent}
          </ArrowContainer>
        )}
        onClickOutside={() => setIsPopoverOpen(false)}
      >
        <button onClick={handlePopoverClick} aria-label='popover'>
          {children}
        </button>
      </Popover>
    </div>
  );
};
