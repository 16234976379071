import { FC } from 'react';
import clsx from 'clsx';

type Props = {
  onClick?: () => void;
  className?: string;
  children?: React.ReactNode;
};

export const TextWithUnderline: FC<Props> = ({ onClick, children, className }) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'hover:opacity-70 hover:cursor-pointer',
        'mx-2 text-secondary-evinyCold3 underline break-normal font-secondaryRegular text-body',
        className
      )}
    >
      {children}
    </button>
  );
};
