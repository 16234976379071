import { FC, InputHTMLAttributes, ReactNode } from 'react';

// COMPONENTS:
import { Counter } from '../Counter';
import { Label } from '../../atoms';

type CounterWithLabelProps = {
  name: string;
  value: number;
  label: string | ReactNode;
  className?: string;
  showError?: boolean;
  containerClassName?: string;
  onIncrementClick: () => void;
  onDecrementClick: () => void;
} & InputHTMLAttributes<HTMLInputElement>;

export const CounterWithLabel: FC<CounterWithLabelProps> = ({ label, ...props }) => {
  return (
    <>
      <Label className='block float-left mb-2 mt-7 text-primary-evinyGreen3 font-secondaryMedium text-inputLabel'>
        {label}
      </Label>
      <Counter {...props} />
    </>
  );
};
