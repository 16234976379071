import { FC } from 'react';
import { IconPropsType } from '../../../types';
import tailwindConfig from '../../../../tailwind.config';

export const TorkesystemEquipmentIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21.4844 20.6992H3.90625C3.80265 20.6992 3.70329 20.658 3.63003 20.5848C3.55678 20.5116 3.51562 20.4122 3.51562 20.3086C3.51562 20.205 3.55678 20.1056 3.63003 20.0324C3.70329 19.9591 3.80265 19.918 3.90625 19.918H21.4844C21.588 19.918 21.6873 19.9591 21.7606 20.0324C21.8338 20.1056 21.875 20.205 21.875 20.3086C21.875 20.4122 21.8338 20.5116 21.7606 20.5848C21.6873 20.658 21.588 20.6992 21.4844 20.6992Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M8.10156 18.8047C7.99797 18.8047 7.89859 18.7635 7.82535 18.6903C7.75211 18.617 7.71094 18.5177 7.71094 18.4141C7.63727 17.8643 7.43227 17.3404 7.11328 16.8867C6.72168 16.3212 6.48102 15.665 6.41406 14.9805C6.48426 14.3014 6.72477 13.6511 7.11328 13.0898C7.43156 12.6372 7.63652 12.1147 7.71094 11.5664C7.63547 11.0189 7.42914 10.4977 7.10938 10.0469C6.71328 9.4816 6.46977 8.82367 6.40234 8.13672C6.46977 7.44977 6.71328 6.79184 7.10938 6.22656C7.42649 5.77434 7.63258 5.25375 7.71094 4.70703C7.71094 4.60344 7.75211 4.50406 7.82535 4.43082C7.89859 4.35758 7.99797 4.31641 8.10156 4.31641C8.20516 4.31641 8.30453 4.35758 8.37777 4.43082C8.45102 4.50406 8.49219 4.60344 8.49219 4.70703C8.42289 5.39234 8.17953 6.04855 7.78516 6.61328C7.4659 7.06578 7.25961 7.58816 7.18359 8.13672C7.25961 8.68527 7.4659 9.20766 7.78516 9.66016C8.18125 10.224 8.42481 10.8807 8.49219 11.5664C8.4259 12.2523 8.1852 12.9099 7.79297 13.4766C7.47469 13.9292 7.26973 14.4517 7.19531 15C7.26914 15.5473 7.47418 16.0686 7.79297 16.5195C8.13672 17.1289 8.49609 17.7578 8.49219 18.4336C8.48719 18.5338 8.44383 18.6282 8.37109 18.6973C8.29836 18.7664 8.20188 18.8048 8.10156 18.8047Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M13.3477 18.8047C13.2441 18.8047 13.1447 18.7635 13.0714 18.6903C12.9982 18.617 12.957 18.5177 12.957 18.4141C12.8898 17.8653 12.6901 17.3411 12.375 16.8867C11.9834 16.3212 11.7427 15.665 11.6758 14.9805C11.746 14.3014 11.9865 13.6511 12.375 13.0898C12.6939 12.6375 12.899 12.1149 12.9727 11.5664C12.9014 11.0125 12.6963 10.4842 12.375 10.0273C11.9789 9.46207 11.7354 8.80414 11.668 8.11719C11.7354 7.43023 11.9789 6.7723 12.375 6.20703C12.6936 5.75559 12.8998 5.23465 12.9766 4.6875C12.9766 4.58391 13.0177 4.48453 13.091 4.41129C13.1642 4.33805 13.2636 4.29688 13.3672 4.29688C13.4708 4.29688 13.5702 4.33805 13.6434 4.41129C13.7166 4.48453 13.7578 4.58391 13.7578 4.6875C13.6882 5.37211 13.4463 6.02789 13.0547 6.59375C12.7328 7.04523 12.5251 7.56793 12.4492 8.11719C12.5251 8.66645 12.7328 9.18914 13.0547 9.64063C13.448 10.2056 13.69 10.8618 13.7578 11.5469C13.6915 12.2328 13.4508 12.8904 13.0586 13.457C12.7403 13.9097 12.5354 14.4321 12.4609 14.9805C12.5348 15.5277 12.7398 16.0491 13.0586 16.5C13.4023 17.1094 13.7617 17.7383 13.7578 18.4141C13.7579 18.467 13.7471 18.5195 13.7263 18.5682C13.7055 18.6169 13.675 18.6609 13.6366 18.6974C13.5982 18.7339 13.5528 18.7623 13.5032 18.7807C13.4535 18.7992 13.4006 18.8073 13.3477 18.8047Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        d='M18.5937 18.8047C18.4902 18.8047 18.3908 18.7635 18.3175 18.6903C18.2443 18.617 18.2031 18.5177 18.2031 18.4141C18.1313 17.8639 17.9261 17.3396 17.6055 16.8867C17.2139 16.3212 16.9732 15.665 16.9063 14.9805C16.9764 14.3014 17.217 13.6511 17.6055 13.0898C17.9238 12.6372 18.1287 12.1147 18.2031 11.5664C18.1277 11.0189 17.9213 10.4977 17.6016 10.0469C17.2066 9.48137 16.9643 8.82336 16.8984 8.13672C16.9626 7.44965 17.205 6.79125 17.6016 6.22656C17.9201 5.77512 18.1263 5.25418 18.2031 4.70703C18.2031 4.60344 18.2443 4.50406 18.3175 4.43082C18.3908 4.35758 18.4902 4.31641 18.5937 4.31641C18.6973 4.31641 18.7967 4.35758 18.87 4.43082C18.9432 4.50406 18.9844 4.60344 18.9844 4.70703C18.9166 5.39207 18.6745 6.04832 18.2812 6.61328C17.9604 7.06492 17.7539 7.5877 17.6797 8.13672C17.7539 8.68574 17.9604 9.20852 18.2812 9.66016C18.6745 10.2251 18.9166 10.8814 18.9844 11.5664C18.92 12.2528 18.6791 12.9108 18.2852 13.4766C17.9669 13.9292 17.7619 14.4517 17.6875 15C17.7613 15.5473 17.9664 16.0686 18.2852 16.5195C18.6289 17.1289 18.9883 17.7578 18.9844 18.4336C18.9794 18.5338 18.936 18.6282 18.8633 18.6973C18.7905 18.7664 18.6941 18.8048 18.5937 18.8047Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
    </svg>
  );
};
