import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

type ChevronIconType = {
  onClick?: () => void;
} & IconPropsType;

export const ChevronIcon: FC<ChevronIconType> = ({
  className,
  onClick,
  color = tailwindConfig.theme.extend.colors.primary.evinyGreen4,
}) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='9'
      height='16'
      viewBox='0 0 9 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.12109 15.5073L8.82422 14.8394C8.96484 14.6636 8.96484 14.3823 8.82422 14.2417L2.46094 7.84326L8.82422 1.47998C8.96484 1.33936 8.96484 1.05811 8.82422 0.882324L8.12109 0.214355C7.94531 0.0385742 7.69922 0.0385742 7.52344 0.214355L0.140625 7.56201C0 7.73779 0 7.98389 0.140625 8.15967L7.52344 15.5073C7.69922 15.6831 7.94531 15.6831 8.12109 15.5073Z'
        fill={color}
      />
    </svg>
  );
};
