import { Route, Switch } from 'react-router-dom';
import { RootRoutes } from './router';
import { Equipment, Error, Feedback, Guide, Home, NotFound, Report } from './pages';
import { useGaTracker } from './helpers/hooks';

export const Routes = () => {
  useGaTracker();
  return (
    <Switch>
      <Route exact path={RootRoutes.HOME}>
        <Home />
      </Route>
      <Route path={RootRoutes.ERROR}>
        <Error />
      </Route>
      <Route path={RootRoutes.GUIDE}>
        <Guide />
      </Route>
      <Route path={RootRoutes.EQUIPMENT}>
        <Equipment />
      </Route>
      <Route path={RootRoutes.FEEDBACK}>
        <Feedback />
      </Route>
      <Route path={RootRoutes.REPORT}>
        <Report />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};
