import { Moment } from 'moment';
import {
  KPIChartResponseType,
  MaxDailyPotentialEffect,
  MaxDailyPotentialEffectApiType,
  MaxDailyPotentialEffectDays,
} from '../../api/chart/types';
import { DateTimeChartSerie } from '../../types';

export const formatMaxPotentialEffectToChartSeries = (
  data: MaxDailyPotentialEffectApiType[]
): DateTimeChartSerie[] => {
  return data.map((potentialEffect) => [
    potentialEffect.date.getTime(),
    potentialEffect.maxPotentialEffect,
  ]);
};

export const formatMaxRequiredPowerToChartSeries = (data: number[]): DateTimeChartSerie[] => {
  return data.map((requiredPower, index) => [index + 1, requiredPower]);
};

export const formatMaxPotentialEffectApiResponse = (
  data: MaxDailyPotentialEffectDays,
  startDate: Moment,
  intakeEffect?: number,
  topLoad?: number
): KPIChartResponseType => {
  const maxEffectArray: MaxDailyPotentialEffectApiType[] = [];
  const intakeEffectArray: MaxDailyPotentialEffectApiType[] = [];
  const topLoadArray: MaxDailyPotentialEffectApiType[] = [];

  data.weeks.forEach((week) => {
    const date = startDate.toDate();
    maxEffectArray.push({
      date,
      maxPotentialEffect: Number(week.maxPotentialEffect.toFixed(3)),
      machines: week.numberOfMachines,
    });
    if (intakeEffect)
      intakeEffectArray.push({
        date,
        maxPotentialEffect: Number(intakeEffect.toFixed(3)),
        machines: week.numberOfMachines,
      });
    startDate.add(7, 'd');
  });

  startDate.subtract(7, 'd');
  const endDate = startDate.toDate();
  if (topLoad) {
    let topLoadDays = Math.ceil(data.weeks.length * 0.1);
    if (topLoadDays < 2) topLoadDays = 2;
    const lastDayNumberOfMachines = data.weeks.slice(-1)[0].numberOfMachines;
    for (let i = 0; i < topLoadDays; i++) {
      const date = startDate.toDate();
      topLoadArray.push({
        date,
        maxPotentialEffect: Number(topLoad.toFixed(3)),
        machines: i == 0 ? lastDayNumberOfMachines : 0,
      });
      startDate.add(7, 'd');
    }
  }

  return {
    endDate,
    dataArray: [maxEffectArray, intakeEffectArray, topLoadArray],
  };
};

export const formatMaxPotentialEffectWeeks = (weeks: MaxDailyPotentialEffect[]) => {
  const maxEffectArray: number[] = [];
  weeks.forEach((week) => {
    maxEffectArray.push(week.maxPotentialEffect);
  });

  return maxEffectArray;
};
