import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const LightningIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='13'
      height='24'
      viewBox='0 0 13 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.91195 10.1386L11.8746 10.1676L8.72392 16.3188L6.16383 21.3169L6.21341 14.556L6.21707 14.056L5.71709 14.0523L1.5789 14.022L7.46556 2.82516L7.41563 9.63493L7.41196 10.1349L7.91195 10.1386Z'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
      />
    </svg>
  );
};
