import { FC } from 'react';
import { useIntl } from 'react-intl';
import Chart from 'react-apexcharts';
import moment from 'moment';

// STYLING
import tailwindConfig from '../../../../tailwind.config';
import './potential-effect-chart.css';

// COMPONENTS
import { PotentialEffectChartTooltip } from '../../molecules/PotentialEffectChartTooltip';

// TYPES
import { KPIChartResponseType } from '../../../api/chart/types';

// UTILS
import { formatMaxPotentialEffectToChartSeries } from '../../../helpers/functions/charts';

const KPI2_CHART_ID = 'potential-effect-chart';

export type PotentialEffectChartProps = { chartSeriesData: KPIChartResponseType };

export const PotentialEffectChart: FC<PotentialEffectChartProps> = ({ chartSeriesData }) => {
  const { formatMessage } = useIntl();

  const { endDate, dataArray } = chartSeriesData;

  const axisTextStyle = {
    colors: [tailwindConfig.theme.extend.colors.primary.evinyGreen4],
    fontSize: tailwindConfig.theme.extend.fontSize['body'][0],
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 300,
  };

  // If intakeEffect or topLoad values are not provided then an empty array is being pushed instead
  // The order of arrays in seriesArray is strict because of the color assignment and tooltip value display
  const seriesArray = [
    {
      name: formatMessage({ id: 'PROJECT.DETAILS.POTENTIAL.CHART.ENERGY.POTENTIAL' }),
      data: formatMaxPotentialEffectToChartSeries(dataArray[0]),
    },
    {
      name: formatMessage({ id: 'PROJECT.DETAILS.POTENTIAL.CHART.AREA.INTAKE.EFFECT' }),
      data: formatMaxPotentialEffectToChartSeries(dataArray[1].length ? dataArray[1] : []),
    },
    {
      name: formatMessage({ id: 'PROJECT.DETAILS.POTENTIAL.CHART.AREA.TOP.LOAD' }),
      data: formatMaxPotentialEffectToChartSeries(dataArray[2].length ? dataArray[2] : []),
    },
  ];

  return (
    <Chart
      options={{
        markers: {
          strokeColors: tailwindConfig.theme.extend.colors.other.white,
          strokeWidth: 2,
          hover: {
            size: 7,
          },
        },
        colors: [
          tailwindConfig.theme.extend.colors.secondary.evinyWarm4,
          tailwindConfig.theme.extend.colors.secondary.evinyCold3,
          tailwindConfig.theme.extend.colors.secondary.evinyWarm3,
        ],
        stroke: {
          width: 3,
        },
        chart: {
          id: KPI2_CHART_ID,
          toolbar: {
            tools: { download: true },
          },
          selection: {
            enabled: true,
          },
        },
        xaxis: {
          type: 'datetime',
          labels: {
            style: axisTextStyle,
            offsetY: 5,
          },
          tooltip: {
            enabled: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            height: 10,
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: axisTextStyle,
            offsetX: -2,
            offsetY: 4,
            formatter: (val) => val?.toFixed(),
          },
          title: {
            text: formatMessage({ id: 'PROJECT.DETAILS.POTENTIAL.CHART.Y.AXIS.TITLE' }),
            style: { ...axisTextStyle, fontWeight: 400, color: axisTextStyle.colors[0] },
          },
          tickAmount: 9,
          min: 0,
          forceNiceScale: true,
        },
        legend: {
          show: true,
          showForSingleSeries: true,
          showForNullSeries: false,
          horizontalAlign: 'left',
          fontSize: tailwindConfig.theme.extend.fontSize['body'][0],
          fontWeight: 400,
          fontFamily: axisTextStyle.fontFamily,
          offsetY: 10,
          itemMargin: {
            vertical: 5,
          },
          labels: {
            useSeriesColors: true,
          },
          markers: { width: 60, height: 4, radius: 0, offsetX: -8, offsetY: -3 },
        },
        tooltip: {
          custom: ({ series, seriesIndex, dataPointIndex }) => {
            if (series[seriesIndex][dataPointIndex] === undefined) return ''; // No data exists in the series for this point
            return PotentialEffectChartTooltip(
              series,
              seriesIndex,
              dataPointIndex,
              moment(dataArray[seriesIndex][dataPointIndex].date).format('D. MMM YYYY'),
              formatMessage({
                id: 'PROJECT.DETAILS.POTENTIAL.CHART.ENERGY.POTENTIAL.TOOLTIP',
              }),
              formatMessage({
                id: 'PROJECT.DETAILS.POTENTIAL.CHART.AREA.INTAKE.EFFECT',
              }),
              formatMessage({
                id: 'PROJECT.DETAILS.POTENTIAL.CHART.AREA.TOP.LOAD',
              }),
              formatMessage(
                {
                  id:
                    dataArray[seriesIndex][dataPointIndex].machines > 1
                      ? 'PROJECT.DETAILS.POTENTIAL.CHART.TOOLTIP.MACHINES'
                      : 'PROJECT.DETAILS.POTENTIAL.CHART.TOOLTIP.MACHINE',
                },
                {
                  number: `<strong>${dataArray[seriesIndex][dataPointIndex].machines}</strong>`,
                }
              )
            );
          },
          onDatasetHover: {
            highlightDataSeries: true,
          },
          shared: false,
        },
        annotations: {
          xaxis: [
            {
              x: endDate.getTime(),
              borderColor: '#d4d4d4',
              strokeDashArray: 0,
            },
          ],
        },
      }}
      series={seriesArray}
      height={450}
    />
  );
};
