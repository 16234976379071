import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { useState } from 'react';
import Modal from '../../atoms/primitive/Modal';

// ICONS:
import { PhoneIcon } from '../../../assets/images';

export const ContactUsBanner = ({ className }: { className?: string }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((prevState) => !prevState);

  return (
    <>
      <div
        className={clsx(
          'absolute',
          ' z-101 flex items-center mb-3 px-10 py-4 bg-other-white border-1 border-gray-50 rounded-full shadow-lg',
          className
        )}
      >
        <p className='text-primary-evinyGreen4 font-secondaryRegular text-body'>
          <FormattedMessage id='TIMELINE.FOOTER.TALK.TO.US' />
        </p>
        <button
          onClick={toggleModal}
          className='ml-4 rounded-full hover:opacity-60 cursor-pointer transition duration-300 ease-in-out'
          aria-label='Open modal with contact information'
        >
          <PhoneIcon className='drop-shadow-xl filter' />
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onModalClose={toggleModal}
        className='p-16 text-left text-primary-evinyGreen4 shadow-modal'
      >
        <h2 className='text-h2'>
          <FormattedMessage id='TIMELINE.FOOTER.TALK.TO.US.MODAL.HEADER' />
        </h2>
        <p className='py-8 font-secondaryRegular text-body'>
          <FormattedMessage id='TIMELINE.FOOTER.TALK.TO.US.MODAL.QUESTION' />
        </p>
        <p className='font-secondaryMedium text-body'>
          <FormattedMessage id='TIMELINE.FOOTER.TALK.TO.US.MODAL.BOLD.INFO' />
        </p>
        <p className='font-secondaryRegular text-body'>
          <FormattedMessage id='TIMELINE.FOOTER.TALK.TO.US.MODAL.INFO' />
        </p>
      </Modal>
    </>
  );
};
