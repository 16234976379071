import { FormikProps } from 'formik';
import { useState, useEffect } from 'react';

// CONSTANTS:
import { INITIAL_DEFAULT_POSITION } from '../../constants';

// TYPES
import { AddressStepInterface, PositionType } from '../../store/project-info';

type CoordsType = {
  latitude: number | null;
  longitude: number | null;
};

export const usePosition = (
  currentPosition: PositionType,
  formik: FormikProps<AddressStepInterface>
) => {
  const isCurrentPositionEmpty = currentPosition.lat === null && currentPosition.lng === null;

  const [isUserPositionLoaded, setIsUserPositionLoaded] = useState(false);

  useEffect(() => {
    const handlePositionChange = ({ coords }: { coords: CoordsType }) => {
      formik.setFieldValue('locationAddress.position', {
        lat: coords.latitude,
        lng: coords.longitude,
      });
      setIsUserPositionLoaded(true);
    };

    const handlePositionDeclined = () => {
      if (isCurrentPositionEmpty) {
        formik.setFieldValue('locationAddress.position', {
          lat: INITIAL_DEFAULT_POSITION.latitude,
          lng: INITIAL_DEFAULT_POSITION.longitude,
        });
      }
      setIsUserPositionLoaded(true);
    };

    const geo = navigator.geolocation;

    // If the position is already set, we don't want to prompt the user for geolocation one more time
    if (!geo || !isCurrentPositionEmpty) {
      setIsUserPositionLoaded(true);
      return;
    }

    // This triggers the prompt in the browser
    geo.getCurrentPosition(handlePositionChange, handlePositionDeclined);

    return () => {
      setIsUserPositionLoaded(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition]);

  return {
    isUserPositionLoaded,
  };
};
