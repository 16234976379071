import { FC } from 'react';

import clsx from 'clsx';

type StepperDividerProps = {
  isValid: boolean;
  isActive: boolean;
};

export const StepperDivider: FC<StepperDividerProps> = ({ isValid, isActive }) => {
  return (
    <div
      className={clsx(
        isValid || isActive ? 'border-primary-evinyGreen4' : 'border-primary-evinyGreen1',
        'hidden flex-auto border-t transition duration-100 ease-in-out lg:block'
      )}
    />
  );
};
