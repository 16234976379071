import { useQuery } from '@tanstack/react-query';
import { getProjectInfoData } from '../../api/project-info';
import { QueryKeysNames } from '../../api/types';

export const useProjectById = (id?: string | null) => {
  return useQuery(
    [QueryKeysNames.GET_PROJECT_INFO_DATA, id!],
    async ({ queryKey }) => {
      const res = await getProjectInfoData(queryKey[1]);
      return res.data;
    },
    {
      enabled: !!id,
    }
  );
};
