import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';

// ICONS:
import { ThumbUpIcon, ThumbDownIcon } from '../../../assets/images';

// STYLES:
import tailwindConfig from '../../../../tailwind.config';

// COMPONENTS:
import { Textarea } from '../../atoms';

// API
import { sendAppFeedback } from '../../../api/project-info';

// TYPES
import { ThumbValue } from '../../../api/project-info/types';

// ROUTER
import { useHistory } from 'react-router';
import { RootRoutes } from '../../../router';
import Button from '../../atoms/primitive/Button';

const convertThumbValueToBoolean = (value: ThumbValue) => {
  switch (value) {
    case ThumbValue.GOOD:
      return true;
    case ThumbValue.BAD:
      return false;
  }
};

export const AppEvaluationForm = () => {
  const [thumbValue, setThumbValue] = useState<ThumbValue>();
  const history = useHistory();
  const { formatMessage } = useIntl();

  return (
    <div className='pt-10'>
      <p className='text-center text-primary-evinyGreen4 font-secondaryRegular text-body'>
        <FormattedMessage id='SUCCESS.FORM.SUBMIT.MODAL.QUESTION' />
      </p>
      <div className='flex justify-center pt-6'>
        <button
          className={clsx(
            'flex items-center justify-center mr-2 w-16 h-16 rounded-full transition duration-300',
            'hover:bg-primary-evinyGreen4 hover:bg-opacity-30',
            thumbValue === ThumbValue.GOOD
              ? 'bg-primary-evinyGreen4 border-3 border-primary-evinyGreen1'
              : 'border border-primary-evinyGreen2'
          )}
          aria-label='Thumb up'
          onClick={() => setThumbValue(ThumbValue.GOOD)}
        >
          <ThumbUpIcon
            className='m-4'
            color={
              thumbValue === ThumbValue.GOOD
                ? tailwindConfig.theme.extend.colors.other.white
                : tailwindConfig.theme.extend.colors.primary.evinyGreen4
            }
          />
        </button>
        <button
          className={clsx(
            'flex items-center justify-center ml-2 w-16 h-16 rounded-full transition duration-300',
            'hover:bg-primary-evinyGreen4 hover:bg-opacity-30',
            thumbValue === ThumbValue.BAD
              ? 'bg-primary-evinyGreen4 border-3 border-primary-evinyGreen1'
              : 'border border-primary-evinyGreen2'
          )}
          aria-label='Thumb down'
          onClick={() => setThumbValue(ThumbValue.BAD)}
        >
          <ThumbDownIcon
            className='m-4'
            color={
              thumbValue === ThumbValue.BAD
                ? tailwindConfig.theme.extend.colors.other.white
                : tailwindConfig.theme.extend.colors.primary.evinyGreen4
            }
          />
        </button>
      </div>
      {thumbValue && (
        <>
          <p className='pt-8 text-center text-primary-evinyGreen4 font-secondaryRegular text-body'>
            <FormattedMessage id='SUCCESS.FORM.SUBMIT.MODAL.MESSAGE.HEADER' />
          </p>
          <Formik
            initialValues={{
              message: '',
            }}
            onSubmit={(values) =>
              sendAppFeedback({
                positive: convertThumbValueToBoolean(thumbValue),
                message: values.message,
              }).then(() => {
                toast.success(<FormattedMessage id='EVALUATION.SUCCESSFUL.SENT' />, {
                  toastId: 'EVALUATION.SUCCESSFUL.SENT',
                });
                history.push(RootRoutes.HOME);
              })
            }
          >
            {({ isSubmitting }) => (
              <Form className='flex flex-col items-end justify-center pt-6 px-40'>
                <div className='w-full'>
                  <Textarea
                    name='message'
                    placeholder={formatMessage({
                      id: 'SUCCESS.FORM.SUBMIT.MODAL.MESSAGE.PLACEHOLDER',
                    })}
                    rows={4}
                    label={<FormattedMessage id='SUCCESS.FORM.SUBMIT.MODAL.MESSAGE.LABEL' />}
                    className='mt-2 px-4 py-3.5 w-full text-primary-evinyGreen4 font-secondaryRegular text-body bg-transparent border border-primary-evinyGreen4 rounded'
                  />
                </div>
                <Button type='submit' disabled={isSubmitting} variant='primary' className='mt-2'>
                  <FormattedMessage id='SUCCESS.FORM.SUBMIT.MODAL.MESSAGE.SEND' />
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </div>
  );
};
