import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const BulbIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='26'
      height='34'
      viewBox='0 0 26 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.45459 8.4668H7.5455C10.558 8.4668 13 10.8546 13 13.8001V15.9335H11.9091C8.89668 15.9335 6.45459 13.5456 6.45459 10.6001V8.4668Z'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M13 27.6668V6.3335'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M19.5455 12.7334H18.4545C15.4421 12.7334 13 15.1212 13 18.0667V20.2001H14.0909C17.1034 20.2001 19.5455 17.8122 19.5455 14.8667V12.7334Z'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen1}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.54541 27.6665H18.4545'
        stroke={tailwindConfig.theme.extend.colors.other.white}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M25 12.7333C25 5.80807 18.864 0.284106 11.6178 1.07566C6.3317 1.65311 1.95898 5.72652 1.14769 10.8665C0.306518 16.1957 3.14128 20.9715 7.54545 23.1755V27.6667C7.54545 30.6122 9.98753 33 13 33H13C16.0125 33 18.4545 30.6122 18.4545 27.6667V23.1755C22.3371 21.2325 25 17.2909 25 12.7333Z'
        stroke={tailwindConfig.theme.extend.colors.other.white}
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
