import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';

// TRANSLATIONS
import { FormattedMessage } from 'react-intl';

// TYPES
import { FormSteps, AdditionalFormSteps, DataCarryingFormSteps } from '../../../store/config';

// COMPONENTS
import { StepperDivider } from '../../atoms';
import { StepperElement } from '../../molecules';

type SingleStep = {
  label: ReactNode;
  formStep: FormSteps;
};

export type FormValidation = {
  [DataCarryingFormSteps.PROJECT]: boolean;
  [DataCarryingFormSteps.DATE]: boolean;
  [DataCarryingFormSteps.ADDRESS]: boolean;
  [DataCarryingFormSteps.AREA]: boolean;
  [DataCarryingFormSteps.HEATING]: boolean;
  [AdditionalFormSteps.OVERVIEW]: boolean;
};

export type Props = {
  currentStep: FormSteps;
  formValidations: FormValidation;
  formCompleted: FormValidation;
  onChangeStepClick: (goToStep: FormSteps) => void;
};

export const Stepper: FC<Props> = ({
  currentStep,
  formValidations,
  formCompleted,
  onChangeStepClick,
}) => {
  const content: SingleStep[] = [
    {
      label: <FormattedMessage id='GUIDE.STEPPER.PROJECT' />,
      formStep: DataCarryingFormSteps.PROJECT,
    },
    { label: <FormattedMessage id='GUIDE.STEPPER.DATE' />, formStep: DataCarryingFormSteps.DATE },
    {
      label: <FormattedMessage id='GUIDE.STEPPER.ADDRESS' />,
      formStep: DataCarryingFormSteps.ADDRESS,
    },
    { label: <FormattedMessage id='GUIDE.STEPPER.AREA' />, formStep: DataCarryingFormSteps.AREA },
    {
      label: <FormattedMessage id='GUIDE.STEPPER.HEATING' />,
      formStep: DataCarryingFormSteps.HEATING,
    },
    {
      label: <FormattedMessage id='GUIDE.STEPPER.OVERVIEW' />,
      formStep: AdditionalFormSteps.OVERVIEW,
    },
  ];

  return (
    <div
      className={clsx(
        'px-0 py-5 max-w-7xl',
        'lg:flex lg:items-center lg:mx-4',
        '2xl:p-4 2xl:px-56'
      )}
    >
      {content.map((step) => (
        <React.Fragment key={step.formStep}>
          {step.formStep !== DataCarryingFormSteps.PROJECT && (
            <StepperDivider
              isValid={formValidations[step.formStep] && formCompleted[step.formStep]}
              isActive={currentStep === step.formStep}
            />
          )}
          <StepperElement
            isValid={formValidations[step.formStep] && formCompleted[step.formStep]}
            isActive={currentStep === step.formStep}
            onClick={() => onChangeStepClick(step.formStep)}
            label={step.label}
            formStep={step.formStep}
          />
        </React.Fragment>
      ))}
    </div>
  );
};
