import { FC, useState } from 'react';
import clsx from 'clsx';
import FlexibleSolutionsIllustrationWithoutButtons from '../../../assets/images/report/illustration.svg';
import {
  InteractiveElementClicked,
  InteractiveElementNotClicked,
  ChevronIcon,
} from '../../../assets/images';
import './flexible-solutions-section.css';
import { FormattedMessage } from 'react-intl';
import { useFlexibleSolutions, ItemType } from './use-flexible-solutions';

export const FlexibleSolutionsSection: FC = () => {
  const { flexibleSolutionsList, availableItems } = useFlexibleSolutions();
  const [selectedItem, setSelectedItem] = useState<ItemType | undefined>(availableItems[0]);

  const changeSelectedItem = (id: number) => {
    const item = availableItems.find((item) => item.id === id);
    setSelectedItem(item);
  };

  const handleNextItemClick = (id: number) => {
    const newId = id === availableItems.length ? availableItems[0].id : id + 1;
    changeSelectedItem(newId);
  };

  const handlePreviousItemClick = (id: number) => {
    const newId =
      id === availableItems[0].id ? availableItems[availableItems.length - 1].id : id - 1;
    changeSelectedItem(newId);
  };

  const InteractiveElement: FC<{ id: number; className?: string }> = ({ id, className }) => {
    const isSelected = selectedItem?.id === id;
    const Element = isSelected ? InteractiveElementClicked : InteractiveElementNotClicked;

    return (
      <div
        className={clsx(
          'item absolute flex items-center justify-center hover:opacity-70 cursor-pointer transition duration-200',
          className,
          `item-${id}`,
          isSelected && 'animate-show'
        )}
      >
        <Element onClick={() => changeSelectedItem(id)} />
      </div>
    );
  };

  return (
    <div className='px-28 py-20 bg-primary-evinyGreen4'>
      <div className='mx-auto max-w-1300px'>
        <h2 className='pb-4 pt-14 text-h2'>
          <FormattedMessage id='REPORT.FLEXIBLE.SOLUTION.HEADER' />
        </h2>
        <p className='font-secondaryRegular text-body'>
          <FormattedMessage id='REPORT.FLEXIBLE.SOLUTION.DESCRIPTION' />
        </p>

        <div className='flex items-center justify-between py-28'>
          <div className='relative'>
            <img
              src={FlexibleSolutionsIllustrationWithoutButtons}
              alt='flexible-solutions-illustration'
            />
            {availableItems.map((item) => (
              <InteractiveElement key={item.id} id={item.id} />
            ))}
          </div>

          {selectedItem && (
            <div
              className={clsx(
                'grid grid-cols-5 items-end ml-10 px-2 w-80 bg-primary-lightBackground rounded-2xl',
                '4xl:items-center 4xl:ml-0'
              )}
            >
              <div className={clsx('flex col-span-1 justify-start pb-6', '4xl:pb-0')}>
                <ChevronIcon
                  className={clsx(
                    'p-2 w-8 h-8 hover:opacity-60 cursor-pointer',
                    '4xl:w-8.5 4xl:h-8.5'
                  )}
                  onClick={() => handlePreviousItemClick(selectedItem.id)}
                />
              </div>
              <div
                className={clsx(
                  'flex flex-col col-span-3 items-center justify-center pt-8 py-28',
                  '4xl:pb-12'
                )}
              >
                <div className={clsx('w-28 h-28', '4xl:w-52 4xl:h-52')}>
                  <img src={selectedItem.photo} alt={selectedItem.title} />
                </div>
                <div
                  className={clsx('flex flex-col items-center justify-start w-40 h-28', '4xl:w-56')}
                >
                  <p className='pt-4 text-center text-primary-evinyGreen4 text-h3'>
                    {selectedItem.title}
                  </p>

                  <p
                    className={clsx(
                      'pt-2 text-center text-primary-evinyGreen4',
                      '4xl:font-secondaryRegular 4xl:text-body'
                    )}
                  >
                    {selectedItem.description}
                  </p>
                </div>
              </div>
              <div className={clsx('flex col-span-1 justify-end pb-6', '4xl:pb-0')}>
                <ChevronIcon
                  className={clsx(
                    'p-2 w-8 h-8 hover:opacity-60 cursor-pointer transform rotate-180',
                    '4xl:w-8.5 4xl:h-8.5'
                  )}
                  onClick={() => handleNextItemClick(selectedItem.id)}
                />
              </div>
            </div>
          )}
        </div>

        <div className='px-10 py-6 text-primary-evinyGreen4 bg-secondary-evinyCold1 rounded-2xl'>
          <h2 className='pb-4 text-h2'>
            <FormattedMessage id='REPORT.FLEXIBLE.SOLUTIONS.LIST.HEADER' />
          </h2>
          <ul className='list-disc'>
            {flexibleSolutionsList.map((solution) => (
              <li key={solution}>{solution}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};
