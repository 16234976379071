import { FC } from 'react';
import clsx from 'clsx';

type OverviewParagraphProps = {
  className?: string;
  children: React.ReactNode;
};

export const OverviewParagraph: FC<OverviewParagraphProps> = ({ className, children }) => {
  return (
    <p className={clsx(' text-primary-evinyGreen4 font-secondaryRegular text-body ', className)}>
      {children}
    </p>
  );
};
