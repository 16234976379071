import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const CheckCircleIcon: FC<IconPropsType> = ({
  className,
  color = tailwindConfig.theme.extend.colors.primary.evinyGreen1,
}) => {
  return (
    <svg
      className={className}
      width='94'
      height='94'
      viewBox='0 0 94 94'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M47 0.5C21.3125 0.5 0.5 21.5 0.5 47C0.5 72.6875 21.3125 93.5 47 93.5C72.5 93.5 93.5 72.6875 93.5 47C93.5 21.5 72.5 0.5 47 0.5ZM47 9.5C67.625 9.5 84.5 26.375 84.5 47C84.5 67.8125 67.625 84.5 47 84.5C26.1875 84.5 9.5 67.8125 9.5 47C9.5 26.375 26.1875 9.5 47 9.5ZM73.25 34.0625L68.9375 29.75C68.1875 28.8125 66.6875 28.8125 65.75 29.75L39.3125 56L28.0625 44.75C27.125 43.8125 25.8125 43.8125 24.875 44.75L20.5625 48.875C19.8125 49.8125 19.8125 51.3125 20.5625 52.0625L37.625 69.3125C38.5625 70.25 39.875 70.25 40.8125 69.3125L73.25 37.25C74 36.3125 74 34.8125 73.25 34.0625Z'
        fill={color}
      />
    </svg>
  );
};
