import { FC } from 'react';
import { IconPropsType } from '../../../types';
import tailwindConfig from '../../../../tailwind.config';

export const ElectricEquipmentIcon: FC<IconPropsType> = ({
  className,
  color = tailwindConfig.theme.extend.colors.primary.evinyGreen4,
}) => {
  return (
    <svg
      className={className}
      width='18'
      height='17'
      viewBox='0 0 18 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.28144 0C4.50218 0 0.625 3.80711 0.625 8.5C0.625 13.1929 4.50218 17 9.28144 17C14.0607 17 17.9379 13.1929 17.9379 8.5C17.9379 3.80711 14.0607 0 9.28144 0ZM8.8304 15.3136V9.98196H5.81193L10.1488 1.68637V7.01804H13.0545L8.8304 15.3136Z'
        fill={color}
      />
    </svg>
  );
};
