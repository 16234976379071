import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import './fonts/Atyp-Display-Medium.woff';
import './fonts/Atyp-Display-Medium.woff2';
import './assets/styles/global.css';
import 'react-datepicker/dist/react-datepicker.css';
import App from './App';
import * as Sentry from '@sentry/react';

// Initialize logging of unhandled exceptions to Sentry
const sentryDsn = import.meta.env.APP_SENTRY_DSN;
if (sentryDsn !== '') {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
  });
}
const container = document.getElementById('root');
if (!container) throw new Error('Failed to find the root element');

const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
