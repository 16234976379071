export const getNumberOfMonthsGrayedOut = (numberOfWeeks: number) => {
  switch (true) {
    case numberOfWeeks < 8:
      return 2;
    case numberOfWeeks >= 8 && numberOfWeeks < 12:
      return 3;
    case numberOfWeeks >= 12 && numberOfWeeks < 15:
      return 4;
    case numberOfWeeks >= 15 && numberOfWeeks < 20:
      return 5;
    case numberOfWeeks >= 20 && numberOfWeeks < 24:
      return 6;
    case numberOfWeeks >= 24 && numberOfWeeks < 27:
      return 7;
    case numberOfWeeks >= 27 && numberOfWeeks < 31:
      return 8;
    case numberOfWeeks >= 31 && numberOfWeeks < 34:
      return 9;
    case numberOfWeeks >= 34:
      return 10;
  }
};
