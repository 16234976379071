import { FC } from 'react';
import StepOne from '../../../assets/images/project-details/step1.png';
import StepTwo from '../../../assets/images/project-details/step2.png';
import StepThree from '../../../assets/images/project-details/step3.png';
import StepFour from '../../../assets/images/project-details/step4.png';
import './step-guide.css';
import clsx from 'clsx';
import { FormattedMessage } from 'react-intl';

export const StepGuide: FC = () => {
  return (
    <div
      className={clsx(
        'items-center mt-14 pb-32 pt-16 px-14 w-full bg-primary-darkBackground rounded-2xl',
        '3xl:px-20',
        '5xl:px-5xl'
      )}
    >
      <div className='w-full text-center'>
        <p
          className={clsx(
            'mb-6 text-center text-primary-evinyGreen1 text-mobileLargeText2',
            '3xl:px-40 3xl:text-largeText2'
          )}
        >
          <FormattedMessage id='PROJECT.DETAILS.STEP.GUIDE.HEADER' />
        </p>
        <p
          className={clsx(
            'inline-block text-center text-primary-evinyGreen1 text-h3 ',
            '2xl:px-24 2xl:text-h2',
            '3xl: 3xl:px-40'
          )}
        >
          <FormattedMessage id='PROJECT.DETAILS.STEP.GUIDE.DESCRIPTION' />
        </p>
        <div className='relative grid gap-4 gap-y-0 grid-cols-4 mt-24 pt-32'>
          <div
            className={clsx(
              ' static flex items-center px-6 py-20 text-primary-evinyGreen4 font-secondaryRegular text-body bg-primary-lightBackground rounded-2xl',
              '3xl:'
            )}
          >
            <div className={clsx('absolute left-auto top-0 w-1/5 h-44')}>
              <div className='relative flex justify-center w-full h-full'>
                <img src={StepOne} alt='first-step' className='image absolute' />
              </div>
            </div>
            <div className={clsx('circleOutside absolute left-auto w-1/5')}>
              <div className='circleInside flex justify-center m-auto text-center text-primary-evinyGreen1 text-mobileLargeText2 xl:text-largeText2'>
                1
              </div>
            </div>
            <div className='w-full'>
              <FormattedMessage id='PROJECT.DETAILS.STEP.GUIDE.FIRST.STEP' />
            </div>
          </div>

          <div
            className={clsx(
              ' static flex items-center px-6 py-20 text-primary-evinyGreen4 font-secondaryRegular text-body bg-primary-lightBackground rounded-2xl',
              '3xl:'
            )}
          >
            <div className='absolute left-auto top-0 w-1/5 h-44'>
              <div className='relative flex justify-center w-full h-full'>
                <img src={StepTwo} alt='second-step' />
              </div>
            </div>
            <div className='block'>
              <div className='blueArrowhead -mx-6' />
            </div>
            <div className='block'>
              <div className='whiteArrowhead -mx-10' />
            </div>
            <div className='circleOutside absolute left-auto w-1/5'>
              <div className='circleInside flex justify-center m-auto text-center text-primary-evinyGreen1 text-mobileLargeText2 xl:text-largeText2'>
                2
              </div>
            </div>
            <div className='w-full'>
              <FormattedMessage id='PROJECT.DETAILS.STEP.GUIDE.SECOND.STEP' />
            </div>
          </div>

          <div
            className={clsx(
              ' static flex items-center px-6 py-20 text-primary-evinyGreen4 font-secondaryRegular text-body bg-primary-lightBackground rounded-2xl',
              '3xl:'
            )}
          >
            <div className={clsx('absolute left-auto top-0 w-1/5 h-44 ')}>
              <div className='relative flex justify-center w-full h-full'>
                <img src={StepThree} alt='third-step' className='image absolute w-48' />
              </div>
            </div>
            <div className='block'>
              <div className='blueArrowhead -mx-6' />
            </div>
            <div className='block'>
              <div className='whiteArrowhead -mx-10' />
            </div>
            <div className={clsx('circleOutside absolute left-auto w-1/5')}>
              <div className='circleInside flex justify-center m-auto text-center text-primary-evinyGreen1 text-mobileLargeText2 xl:text-largeText2'>
                3
              </div>
            </div>
            <div className='w-full'>
              <FormattedMessage id='PROJECT.DETAILS.STEP.GUIDE.THIRD.STEP' />
            </div>
          </div>

          <div
            className={clsx(
              ' static flex items-center px-6 py-20 text-primary-evinyGreen4 font-secondaryRegular text-body bg-primary-lightBackground rounded-2xl',
              ' 3xl:'
            )}
          >
            <div className={clsx('left-autp absolute top-0 w-1/5 h-44')}>
              <div className='relative flex justify-center w-full h-full'>
                <img src={StepFour} alt='fourth-step' className='image absolute w-64' />
              </div>
            </div>

            <div className='block'>
              <div className='blueArrowhead -mx-6' />
            </div>
            <div className='block'>
              <div className='whiteArrowhead -mx-10' />
            </div>
            <div className={clsx('circleOutside absolute left-auto w-1/5')}>
              <div className='circleInside flex justify-center m-auto text-center text-primary-evinyGreen1 text-mobileLargeText2 xl:text-largeText2'>
                4
              </div>
            </div>
            <div className='w-full'>
              <FormattedMessage id='PROJECT.DETAILS.STEP.GUIDE.FOURTH.STEP' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
