import { FC } from 'react';
import { IconPropsType } from '../../../types';
import tailwindConfig from '../../../../tailwind.config';

export const VibratorPlateEquipmentIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M23.8516 20.9102C23.8575 20.8739 23.8575 20.837 23.8516 20.8008C23.8516 20.8008 23.8516 20.7734 23.8516 20.7617C23.848 20.7283 23.8401 20.6955 23.8281 20.6641C23.8266 20.6524 23.8266 20.6406 23.8281 20.6289C23.8062 20.5865 23.7771 20.5482 23.7422 20.5156L23.457 20.2656L4.67578 3.6133C4.60355 3.5496 4.51035 3.51482 4.41406 3.51564H1.5625C1.4589 3.51564 1.35954 3.55679 1.28629 3.63005C1.21303 3.70331 1.17188 3.80266 1.17188 3.90625C1.17188 4.00988 1.21303 4.10922 1.28629 4.18246C1.35954 4.25574 1.4589 4.29687 1.5625 4.29687H4.26563L13.0078 12.0469H12.7578C12.3614 12.095 11.9795 12.2259 11.637 12.4311C11.2944 12.6364 10.9989 12.9114 10.7695 13.2383L5.65625 20.5859C5.61773 20.6429 5.59488 20.709 5.59008 20.7776C5.58531 20.8462 5.59867 20.9148 5.62891 20.9766C5.66191 21.0403 5.7118 21.0936 5.77312 21.1309C5.83445 21.168 5.90484 21.1877 5.97656 21.1875H23.4805C23.5289 21.1865 23.5768 21.1759 23.6211 21.1562H23.6563C23.6928 21.1373 23.7259 21.1122 23.7539 21.082C23.7828 21.0487 23.8053 21.0103 23.8203 20.9687C23.8199 20.9571 23.8226 20.9455 23.8281 20.9353C23.8336 20.925 23.8417 20.9163 23.8516 20.9102ZM6.72656 20.418L11.4141 13.7031C11.5798 13.4661 11.7939 13.2667 12.042 13.118C12.2902 12.9694 12.567 12.8748 12.8543 12.8405C13.1415 12.8062 13.4328 12.833 13.7089 12.919C13.9852 13.0051 14.2401 13.1485 14.457 13.3398L22.4492 20.418H6.72656Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
    </svg>
  );
};
