import React from 'react';

// COMPONENTS:
import { FeedbackModal, AppEvaluationForm } from '../../components/molecules';

export const Feedback = () => {
  return (
    <div className='pb-20 pt-12 px-40 h-full bg-primary-lightBackground overflow-y-auto'>
      <FeedbackModal />
      <AppEvaluationForm />
    </div>
  );
};
