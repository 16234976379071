import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

type ActiveStepperDotIconProps = IconPropsType & { isActive: boolean };

export const ActiveStepperDotIcon: FC<ActiveStepperDotIconProps> = ({
  className,
  isActive,
  onClick,
}) => {
  return (
    <svg
      className={className}
      width='15'
      height='15'
      viewBox='0 0 15.5 15.5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={onClick}
    >
      <circle
        cx='7'
        cy='7'
        r={isActive ? '6' : '4'}
        transform='rotate(-90 7.8 7)'
        stroke={isActive ? tailwindConfig.theme.extend.colors.primary.evinyGreen4 : 'none'}
        strokeWidth='3.5'
        fill={
          isActive
            ? tailwindConfig.theme.extend.colors.other.white
            : tailwindConfig.theme.extend.colors.primary.evinyGreen4
        }
      />
    </svg>
  );
};
