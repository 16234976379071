import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
// import { Label } from '../../atoms';
import Button from '../../atoms/primitive/Button';
import Modal from '../../atoms/primitive/Modal';

type ConfirmationModalProps = {
  onConfirmButtonClick: () => void;
  onModalClose: () => void;
  isOpen: boolean;
};

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isOpen,
  onModalClose,
  onConfirmButtonClick,
}) => (
  <Modal
    withBackground
    isOpen={isOpen}
    onModalClose={onModalClose}
    className='pb-8 pt-16 px-8 w-120'
  >
    <p className='block mb-4 text-primary-evinyGreen4 font-secondaryRegular text-body'>
      <FormattedMessage id='TIMELINE.MODAL.UNSAVED.CHANGES.WARRNING' />
    </p>
    <div className='flex justify-between py-3'>
      <div>
        <Button
          type='button'
          variant='secondary'
          className='block py-3 w-48 text-primary-evinyGreen4 border-2 border-primary-evinyGreen4 rounded-full'
          onClick={onModalClose}
        >
          <FormattedMessage id='TIMELINE.MODAL.BACK' />
        </Button>
      </div>
      <div>
        <Button
          type='button'
          variant='primary'
          className='block py-3 w-48 text-other-white whitespace-nowrap bg-secondary-evinyCold3 rounded-full'
          onClick={() => {
            onConfirmButtonClick();
            onModalClose();
          }}
        >
          <FormattedMessage id='TIMELINE.MODAL.DISCARD.CHANGES' />
        </Button>
      </div>
    </div>
  </Modal>
);
