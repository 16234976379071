import { ProjectInfoPayloadType } from '../project-info/types';
import request, { APICall } from '../request';
import { KPI8ChartResponseType, MaxDailyPotentialEffectApiResponse } from './types';

export const getMaxPotentialEffect = (
  payload: ProjectInfoPayloadType
): APICall<MaxDailyPotentialEffectApiResponse> =>
  request({
    data: payload,
    url: `/project/calculateKPI2`,
    method: 'POST',
  });

export const getMaxRequiredPower = (
  payload: ProjectInfoPayloadType
): APICall<KPI8ChartResponseType> =>
  request({
    data: payload,
    url: `/project/calculateKPI8`,
    method: 'POST',
  });
