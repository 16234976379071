import { FC, ReactNode } from 'react';
import clsx from 'clsx';

// ICONS:
import { ActiveStepperDotIcon, StepperDotIcon } from '../../../assets/images';

// TYPES:
import { FormSteps } from '../../../store/config';

export type Props = {
  isValid: boolean;
  isActive: boolean;
  label: string | ReactNode;
  formStep: FormSteps;
  onClick: () => void;
};

export const StepperElement: FC<Props> = ({ label, formStep, isValid, isActive, onClick }) => {
  const onStepClick = () => {
    if (!isActive && isValid) {
      onClick();
    }
  };

  return (
    <div className={clsx('flex mb-2', 'lg:relative lg:block lg:items-center lg:mb-0')}>
      <div>
        <div
          className={clsx('cursor-pointer', isValid && 'bg-primary-evinyGreen4')}
          data-testid={`form-step-dot-${formStep}`}
        >
          <ActiveStepperDotIcon
            className={clsx(
              isValid || isActive ? 'opacity-100 ' : 'opacity-0 hidden',
              'absolute mt-2 transition duration-100 ease-in-out',
              'lg:-ml-1 lg:-mt-2'
            )}
            isActive={isActive}
            onClick={onStepClick}
          />
        </div>
        <StepperDotIcon
          className={clsx(
            isValid || isActive ? 'opacity-0 hidden' : 'opacity-100',
            'absolute ml-1 mt-3 transition duration-100 ease-in-out',
            'lg:-mt-1 lg:ml-0'
          )}
        />
      </div>
      <button
        onClick={onStepClick}
        role='button'
        disabled={!isActive && !isValid}
        aria-label={`${formStep}-form-step`}
        className={clsx(
          !isValid && !isActive && 'cursor-default',
          isActive ? '' : '',
          'ml-10 text-primary-evinyGreen4 text-h4 opacity-100',
          'font-secondaryRegular lg:absolute lg:top-6 lg:ml-0 lg:text-center lg:text-body'
        )}
      >
        {label}
      </button>
    </div>
  );
};
