import { InputHTMLAttributes, FC, ReactNode } from 'react';

import clsx from 'clsx';
import { InputWithPositiveNumbersOnly } from '../InputWithPositiveNumbersOnly';

type InputWithUnitProps = {
  name: string;
  unit: string;
  icon?: ReactNode;
  showError?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const InputWithUnit: FC<InputWithUnitProps> = ({
  className,
  unit,
  name,
  icon,
  ...props
}) => {
  return (
    <div className='relative'>
      <InputWithPositiveNumbersOnly
        name={name}
        className={clsx(unit && 'pr-26', className)}
        icon={icon}
        {...props}
      />
      <span
        className={clsx(
          icon ? 'mr-12' : 'mr-4',
          'absolute right-0 top-1/3 text-primary-evinyGreen4 font-secondaryRegular text-body',
          'sm:text-h4'
        )}
      >
        {unit}
      </span>
    </div>
  );
};
