// STYLES
import './daily-report-chart-tooltip.css';
export const DailyReportChartTooltip = (title: string, time: string, value: number): string => {
  return `<div class='chart-tooltip-container'>
      <p class='tooltip-date'>
        ${time}:00
      </p>
      <p class='tooltip-daily-report-description'>
        ${title}
      </p>
      <div class='tooltip-main-data'>
        <p class='tooltip-daily-report text-secondary-evinyWarm4'>
          <strong>${value.toFixed()}</strong> kW
        </p>
      </div>`;
};
