import { FC, InputHTMLAttributes } from 'react';
import clsx from 'clsx';

// STYLES
import './counter.css';
import { MinusIcon } from '../../../assets/images';
import { PlusIcon } from '../../../assets/images';
import { ErrorMessage, Field } from 'formik';

type CounterProps = {
  name: string;
  value: number;
  onIncrementClick: () => void;
  onDecrementClick: () => void;
  className?: string;
  showError?: boolean;
  containerClassName?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const Counter: FC<CounterProps> = ({
  className,
  containerClassName,
  onIncrementClick,
  onDecrementClick,
  value,
  name,
  showError = true,
  ...props
}) => {
  const prepareCounterValueForDisplay = () => {
    if (value === undefined || value <= 0) return 0;

    return String(value).padStart(2, '0');
  };

  return (
    <div className={clsx('relative', containerClassName)}>
      <div
        className={clsx('custom-number-input flex flex-row w-full h-12 bg-transparent', className)}
      >
        <button
          type='button'
          data-action='decrement'
          aria-label='decrement'
          className='hover:opacity-50 cursor-pointer'
          onClick={onDecrementClick}
        >
          <MinusIcon />
        </button>
        <Field
          type='number'
          className='flex items-center w-2/5 text-center text-primary-evinyGreen4 font-secondaryRegular text-body bg-transparent cursor-default'
          value={prepareCounterValueForDisplay()}
          name={name}
          {...props}
        />
        <button
          type='button'
          data-action='increment'
          aria-label='increment'
          className='hover:opacity-50 cursor-pointer'
          onClick={onIncrementClick}
        >
          <PlusIcon />
        </button>
      </div>
      {showError && (
        <ErrorMessage
          component='div'
          className='text-other-errorRed whitespace-nowrap text-inputLabel'
          name={name}
        />
      )}
    </div>
  );
};
