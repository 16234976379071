// CONSTANS
import { EQUIPMENT_TYPE } from '../../constants';

// ICONS:
import {
  AnnetEquipmentIcon,
  BetongEquipmentIcon,
  BrakkeEquipmentIcon,
  LastebilEquipmentIcon,
  DumperEquipmentIcon,
  GravemaskinEquipmentIcon,
  HjuldumperEquipmentIcon,
  HjullasterEquipmentIcon,
  KompressorEquipmentIcon,
  MobilkranEquipmentIcon,
  RivemaskinEquipmentIcon,
  SorteringsmaskinEquipmentIcon,
  StamperEquipmentIcon,
  TarnkranEquipmentIcon,
  TorkesystemEquipmentIcon,
  TransporterEquipmentIcon,
  VibratorPlateEquipmentIcon,
} from '../../assets/images';

export const getEquipmentIconFromId = (
  id: string,
  className = 'absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2'
) => {
  switch (id) {
    case EQUIPMENT_TYPE.DUMPER:
      return <DumperEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.EXCAVATOR:
      return <GravemaskinEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.WHEELDUMPER:
      return <HjuldumperEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.LOADER:
      return <HjullasterEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.COMPRESSOR:
      return <KompressorEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.MOBILECRANE:
      return <MobilkranEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.SHREDDER:
      return <RivemaskinEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.SORTINGMACHINE:
      return <SorteringsmaskinEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.STAMPER:
      return <StamperEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.TRANSPORTER:
      return <TransporterEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.DRYINGSYSTEM:
      return <TorkesystemEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.TOWERCRANE:
      return <TarnkranEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.VIBRATORPLATE:
      return <VibratorPlateEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.BARRACKS:
      return <BrakkeEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.CONCRETE:
      return <BetongEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.TRUCK:
      return <LastebilEquipmentIcon className={className} />;
    case EQUIPMENT_TYPE.OTHER:
      return <AnnetEquipmentIcon className={className} />;
    default:
      return null;
  }
};
