import { useState } from 'react';
import { useEquipmentModels } from '.';
import { useProjectById } from '.';
import { ProjectInfoActionType, StepActionType } from '../../store/project-info';
import { parseProjectInfoPayload } from '../../api/project-info/project-info-utils';
import { useQueryParams } from '.';

export const useExistingProject = (dispatch: React.Dispatch<StepActionType>) => {
  const [isProjectParsed, setIsProjectParsed] = useState(false);
  const [modelDataDispatched, setModelDataDispatched] = useState(false);
  const queryParams = useQueryParams();
  const uuid = queryParams.get('uuid');
  const projectId = uuid ?? '';

  const { isFetching: isEquipmentModelsListFetching, data: modelData } = useEquipmentModels();

  const { isFetching: isProjectInfoLoading, data: projectData } = useProjectById(uuid);

  const isLoading = isProjectInfoLoading || isEquipmentModelsListFetching;

  if (modelData && modelData.length && !modelDataDispatched) {
    dispatch({
      type: ProjectInfoActionType.UpdateEquipmentModels,
      payload: modelData,
    });
    setModelDataDispatched(true);
  }

  if (projectData && modelData && !isProjectParsed) {
    dispatch({
      type: ProjectInfoActionType.UpdateProjectInfo,
      payload: parseProjectInfoPayload(projectData, modelData),
    });
    dispatch({
      type: ProjectInfoActionType.UpdateContactUserData,
      payload: projectData.contactUserData,
    });
    dispatch({
      type: ProjectInfoActionType.UpdateResetProjectInfo,
      payload: parseProjectInfoPayload(projectData, modelData),
    });
    dispatch({
      type: ProjectInfoActionType.UpdateProjectId,
      payload: projectId,
    });
    localStorage.removeItem('ProjectInfo');
    setIsProjectParsed(true);
  }

  return { isLoading, hasId: !!projectId, projectId };
};
