import clsx from 'clsx';
import { InputHTMLAttributes, forwardRef } from 'react';
import { DatePickerIcon } from '../../../assets/images';

type InputWithLabelProps = {
  className?: string;
} & InputHTMLAttributes<HTMLInputElement>;

// Use React.forwardRef to forward refs to the input element
export const DatePickerInput = forwardRef<HTMLInputElement, InputWithLabelProps>(
  ({ className, ...props }, ref) => (
    <div
      className={clsx(
        'relative z-5 py-3 font-secondaryRegular text-body bg-transparent hover:opacity-50 transition duration-100 ease-in-out',
        className
      )}
    >
      {/* Forward the ref to the input element */}
      <input
        {...props}
        ref={ref} // Attach ref here
        className='w-full bg-transparent cursor-pointer'
        data-testid='date-picker'
      />
      <DatePickerIcon className='datepickerIcon absolute -z-1 right-0 top-1/2 mr-4' />
    </div>
  )
);

// Optional: Add display name for better debugging experience
DatePickerInput.displayName = 'DatePickerInput';
