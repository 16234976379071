import clsx from 'clsx';

// ICONS:
import { ElectricEquipmentIcon } from '../../../assets/images';

// HELPERS:
import {
  defineTimelineItemStyle,
  getGreatestValueFromArray,
  getLabelFromId,
} from '../../../helpers/functions';

// TYPES:
import { PowerType } from '../../../store/project-info';
import { TimelineItemComponent } from '../../../types';
import { EQUIPMENT_TYPE } from '../../../constants';

// STYLES:
import tailwindConfig from '../../../../tailwind.config';
import '../TimelineItemWithShortenName/timeline-item-with-shorten-name.css';

export const TimelineItemWithRightLabel: TimelineItemComponent = (
  itemRendererProps,
  singleTimelineItem,
  modelOptions,
  defaultTitle
) => {
  const { itemContext, getItemProps } = itemRendererProps;
  const isItemOtherType = singleTimelineItem.equipmentTypeId === EQUIPMENT_TYPE.OTHER;
  const isItemElectricPowerType = singleTimelineItem.powerType === PowerType.Electric;
  const isItemDefined = Boolean(singleTimelineItem.equipmentTypeId);
  const { style, equipmentIcon, leftResizeProps, rightResizeProps } = defineTimelineItemStyle(
    singleTimelineItem,
    itemRendererProps
  );

  const equipmentTitle = getLabelFromId(
    modelOptions,
    singleTimelineItem.equipmentModel.id,
    defaultTitle
  );

  return (
    <div
      {...getItemProps({
        style,
      })}
      className={clsx(
        !itemContext.selected && 'relative hover:shadow-md',
        'tooltip-container relative flex transition-all duration-75 ease-linear'
      )}
    >
      {isItemDefined && (
        <div className='tooltip animate-show'>
          {isItemOtherType || !isItemElectricPowerType
            ? singleTimelineItem.equipmentModel.id
            : equipmentTitle}
        </div>
      )}
      <div {...leftResizeProps} />
      <div
        className='flex items-center justify-start pl-1 whitespace-nowrap overflow-hidden overflow-ellipsis'
        style={{
          height: itemContext.dimensions.height,
        }}
      >
        <div className='flex'>
          <div
            className={clsx(
              singleTimelineItem.isItemOutdated
                ? 'bg-other-errorRed bg-opacity-40'
                : isItemDefined
                ? 'bg-other-white'
                : 'bg-other-gray bg-opacity-20',
              'relative mr-3 w-10 h-10 rounded-full'
            )}
          >
            {isItemElectricPowerType && (
              <ElectricEquipmentIcon
                className='absolute z-30 right-0'
                color={
                  singleTimelineItem.isItemOutdated
                    ? tailwindConfig.theme.extend.colors.other.white
                    : tailwindConfig.theme.extend.colors.primary.evinyGreen4
                }
              />
            )}
            {equipmentIcon}
          </div>
        </div>
      </div>
      <div className='absolute top-0 flex items-center h-full' style={{ right: -80 }}>
        {isItemElectricPowerType && !isItemOtherType && (
          <div
            className={clsx(
              'max-h-8 rounded-md',
              isItemDefined && 'underline',
              singleTimelineItem.isItemOutdated
                ? 'bg-other-errorRed text-other-white'
                : 'text-primary-evinyGreen4 bg-primary-evinyGreen1'
            )}
          >
            <div className='flex items-center p-3 max-h-8'>
              {getGreatestValueFromArray([
                singleTimelineItem.equipmentModel.powerFastCharging ?? 0,
                singleTimelineItem.equipmentModel.powerNormalCharging ?? 0,
                singleTimelineItem.equipmentModel.powerUsageBattery ?? 0,
                singleTimelineItem.equipmentModel.powerUsageGrid ?? 0,
              ])}
              &nbsp;
              <span className='font-secondaryRegular'> kW</span>
            </div>
          </div>
        )}
      </div>
      <div {...rightResizeProps} />
    </div>
  );
};
