import clsx from 'clsx';
import { Form, Formik, useFormikContext } from 'formik';
import { FC, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import * as Yup from 'yup';
import moment, { Moment } from 'moment';
import { useEffect } from 'react';

// COMPONENTS:
import { RadioGroup } from '../RadioGroup';
import SelectWithLabel from '../../molecules/SelectWithLabel';
import DatepickerWithLabel from '../../molecules/DatepickerWithLabel';
import { CounterWithLabel } from '../CounterWithLabel';
import { ConfirmationModal } from '../ConfirmationModal';
import Modal from '../../atoms/primitive/Modal';
import { FormButton } from '../../atoms';
import Button from '../../atoms/primitive/Button';
import { InputWithLabel } from '../InputWithLabel';

// STORE:
import { ProjectInfoContext } from '../../../store';

// HELPERS:
import { filterOptionsByKey } from '../../../helpers/functions';

// TYPES:
import { SingleDropdownOptionType } from '../../../types';
import {
  ProjectInfoActionType,
  PowerType,
  EquipmentScheduleItemType,
} from '../../../store/project-info';

type TimelineModalProps = {
  isOpen: boolean;
  projectStartDate: Moment;
  projectEndDate: Moment;
  onModalClose: () => void;
  afterLeave: () => void;
  onSelectedItemRemove: () => void;
  selectedItemIndex: number;
  equipmentOptions: SingleDropdownOptionType[];
  modelOptions: SingleDropdownOptionType[];
};

const ConfirmationModalDisplayer = ({
  isConfirmationModalVisible,
  setIsConfirmationModalVisible,
  onModalClose,
  setUnsavedChanges,
}: {
  isConfirmationModalVisible: boolean;
  setIsConfirmationModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onModalClose: () => void;
  setUnsavedChanges: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { dirty } = useFormikContext();

  useEffect(() => {
    setUnsavedChanges(dirty);
  }, [dirty, setUnsavedChanges]);

  if (!dirty) return null;

  return (
    <ConfirmationModal
      onModalClose={() => setIsConfirmationModalVisible(false)}
      onConfirmButtonClick={onModalClose}
      isOpen={isConfirmationModalVisible}
    />
  );
};

export const TimelineModal: FC<TimelineModalProps> = ({
  isOpen,
  projectStartDate,
  projectEndDate,
  onModalClose,
  afterLeave,
  onSelectedItemRemove,
  selectedItemIndex,
  equipmentOptions,
  modelOptions,
}) => {
  const { formatMessage } = useIntl();
  const { projectInfo, updateProjectInfo } = useContext(ProjectInfoContext);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);
  const isItemUpdating = Boolean(projectInfo.equipmentSchedule[selectedItemIndex]?.equipmentTypeId);
  const [genericMode, setGenericMode] = useState(true);

  const filteredModelOptions = modelOptions.filter(
    (modelOption) => modelOption.nestedParams?.generic === genericMode
  );

  // Filter down equipment options to only those that have at least one model option
  const filteredEquipmentOptions = equipmentOptions.filter((equipmentOption) =>
    filteredModelOptions.find((model) => model.nestedParams?.forTypeId === equipmentOption.id)
  );

  const equipmentDetailsSchema = Yup.object().shape({
    powerType: Yup.string().required(formatMessage({ id: 'ERROR.OPTION.REQUIRED' })),
    equipmentTypeId: Yup.string().required(formatMessage({ id: 'ERROR.OPTION.REQUIRED' })),
    equipmentModel: Yup.object().shape({
      id: Yup.string().required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    }),
    startTime: Yup.string().required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    endTime: Yup.string().required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
    numberOfMachines: Yup.number()
      .positive(formatMessage({ id: 'ERROR.FIELD.MIN.VALUE' }))
      .integer(formatMessage({ id: 'ERROR.FIELD.NUMBER.INTEGER' }))
      .max(99, formatMessage({ id: 'ERROR.FIELD.MAX.VALUE' })),
    numberOfDaysInUse: Yup.number()
      .integer(formatMessage({ id: 'ERROR.FIELD.NUMBER.INTEGER' }))
      .min(5, formatMessage({ id: 'ERROR.FIELD.MIN.VALUE.NUMBER.OF.DAYS.IN.USE' }))
      .max(7, formatMessage({ id: 'ERROR.FIELD.MAX.VALUE.NUMBER.OF.DAYS.IN.USE' })),
  });

  // if an actual equipment is selected, leave it and close the modal, otherwise remove the item and close the modal
  const handleModalClose = () => {
    if (isItemUpdating && !unsavedChanges) {
      onModalClose();
    } else {
      // if the user has made changes, show the confirmation modal, otherwise close the modal and remove the item
      unsavedChanges ? setIsConfirmationModalVisible(true) : onSelectedItemRemove();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onModalClose={handleModalClose}
      afterLeave={afterLeave}
      className='float-right px-14 py-16 w-112 min-h-screen shadow-modal'
    >
      <h2 className='text-center text-primary-evinyGreen4 text-h2'>
        <FormattedMessage id={`TIMELINE.MODAL.TITLE.${isItemUpdating ? 'UPDATE' : 'ADD'}`} />
      </h2>
      <div className='mb-10 pt-2.5 text-center text-other-gray font-secondaryRegular text-body'>
        <FormattedMessage id='TIMELINE.MODAL.SUBTITLE' />
      </div>

      <Formik
        enableReinitialize
        validationSchema={equipmentDetailsSchema}
        initialValues={{
          id: projectInfo.equipmentSchedule[selectedItemIndex]?.id,
          startTime: projectInfo.equipmentSchedule[selectedItemIndex]?.startTime,
          endTime: projectInfo.equipmentSchedule[selectedItemIndex]?.endTime,
          powerType: projectInfo.equipmentSchedule[selectedItemIndex]?.powerType,
          equipmentTypeId: projectInfo.equipmentSchedule[selectedItemIndex]?.equipmentTypeId,
          equipmentModel: projectInfo.equipmentSchedule[selectedItemIndex]?.equipmentModel,
          numberOfMachines: projectInfo.equipmentSchedule[selectedItemIndex]?.numberOfMachines,
          isItemOutdated: projectInfo.equipmentSchedule[selectedItemIndex]?.isItemOutdated,
          numberOfDaysInUse: projectInfo.equipmentSchedule[selectedItemIndex]?.numberOfDaysInUse,
        }}
        onSubmit={(values: EquipmentScheduleItemType) => {
          if (values.numberOfMachines > 1) {
            for (let i = 1; i < values.numberOfMachines; i++) {
              updateProjectInfo({
                type: ProjectInfoActionType.AddEquipment,
                payload: {
                  ...values,
                  id: projectInfo.equipmentSchedule.length + i,
                  equipmentModel: { ...values.equipmentModel },
                  numberOfMachines: 1,
                },
              });
            }
          }

          updateProjectInfo({
            payload: { ...values, numberOfMachines: 1 },
            type: ProjectInfoActionType.UpdateEquipment,
            itemIndex: selectedItemIndex,
          });

          updateProjectInfo({
            type: ProjectInfoActionType.CheckForSpecificTimelineItemOutOfRange,
            payload: {
              projectStartDate: projectStartDate,
              projectEndDate: projectEndDate,
              itemIndex: selectedItemIndex,
            },
          });

          onModalClose();
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <SelectWithLabel
              label={<FormattedMessage id='TIMELINE.MODAL.EQUIPMENT.TYPE.TITLE' />}
              options={filteredEquipmentOptions}
              containerClassName='z-10 block float-left w-full mb-8'
              placeholder={formatMessage({ id: 'TIMELINE.MODAL.SELECT.PLACEHOLDER' })}
              name='equipmentTypeId'
              className='inline-flex px-4 py-3.5 w-full text-left text-other-gray font-secondaryRegular text-body bg-other-white'
              dropDownClassName='max-h-52 text-other-gray text-body font-secondaryRegular leading-10 border-primary-evinyGreen4 rounded'
              additionalSideOnChange={() => {
                setFieldValue('equipmentModel', {
                  id: '',
                  additionalInformation: 0,
                  additionalInformationUnit: '',
                  powerUsageBattery: null,
                  powerUsageGrid: null,
                  powerFastCharging: null,
                  powerNormalCharging: null,
                });
              }}
            />

            {(values.powerType === PowerType.Other ||
              values.equipmentTypeId === PowerType.Other) && (
              <InputWithLabel
                name='equipmentModel.id'
                placeholder={formatMessage({ id: 'TIMELINE.MODAL.MODEL.PLACEHOLDER' })}
                label={<FormattedMessage id='TIMELINE.MODAL.MODEL.TITLE' />}
                className='inline-flex px-4 py-3.5 max-h-51px text-other-gray font-secondaryRegular text-body bg-other-white border border-primary-evinyGreen4 rounded'
              />
            )}

            <RadioGroup
              name='powerType'
              className='clear-both my-4 border border-primary-evinyGreen4 rounded'
              onChange={() => {
                setGenericMode(!genericMode);
              }}
            >
              <div className='grid gap-0 grid-cols-2'>
                <FormButton
                  showError={false}
                  className={clsx(
                    genericMode
                      ? 'bg-secondary-evinyCold3 text-other-white'
                      : 'bg-other-white text-other-gray',
                    'inline-flex items-center justify-center py-3.5 w-full font-secondaryRegular text-body border-r-1 border-primary-evinyGreen4 rounded transition'
                  )}
                  name='genericMode'
                  value='true'
                  label={formatMessage({ id: 'TIMELINE.MODAL.MODE.GENERIC' })}
                />
                <FormButton
                  showError={false}
                  className={clsx(
                    !genericMode
                      ? 'bg-secondary-evinyCold3 text-other-white'
                      : 'bg-other-white text-other-gray',
                    'inline-flex items-center justify-center py-3.5 w-full font-secondaryRegular text-body rounded transition'
                  )}
                  name='genericMode'
                  value={'false'}
                  label={formatMessage({ id: 'TIMELINE.MODAL.MODE.DETAILED' })}
                />
              </div>
            </RadioGroup>

            {values.powerType === PowerType.Electric &&
              values.equipmentTypeId !== PowerType.Other && (
                <SelectWithLabel
                  label={<FormattedMessage id='TIMELINE.MODAL.MODEL.TITLE' />}
                  options={filterOptionsByKey(filteredModelOptions, values.equipmentTypeId)}
                  containerClassName='block float-left w-full mb-4'
                  placeholder={formatMessage({ id: 'TIMELINE.MODAL.SELECT.PLACEHOLDER' })}
                  name='equipmentModel.id'
                  className='inline-flex px-4 py-3.5 w-full text-left text-other-gray font-secondaryRegular text-body bg-other-white border border-primary-evinyGreen4 rounded'
                  dropDownClassName='max-h-52 w-full text-other-gray text-body font-secondaryRegular leading-10 border-primary-evinyGreen4 rounded'
                  disabled={values.equipmentTypeId === ''}
                />
              )}

            <DatepickerWithLabel
              label={<FormattedMessage id='TIMELINE.MODAL.START.TIME.TITLE' />}
              className='inline-flex mb-4 px-4 py-3 w-full text-left text-primary-evinyGreen4 font-secondaryRegular text-body border border-primary-evinyGreen4 rounded'
              selected={values.startTime?.toDate?.()}
              onChange={(val: Date | React.ChangeEvent<HTMLInputElement> | [Date, Date] | null) => {
                if (!val) {
                  setFieldValue('startTime', '');
                } else if (val instanceof Date) {
                  setFieldValue('startTime', moment(val));
                }
                setFieldValue('endTime', '');
              }}
              minDate={projectStartDate.toDate()}
              maxDate={projectEndDate.toDate()}
              placeholderText={formatMessage({ id: 'GUIDE.DATE.DATEPICKER.PLACEHOLDER' })}
              name='startTime'
            />

            <DatepickerWithLabel
              label={<FormattedMessage id='TIMELINE.MODAL.END.TIME.TITLE' />}
              className='inline-flex mb-2 px-4 py-3 w-full text-left text-primary-evinyGreen4 font-secondaryRegular text-body border border-primary-evinyGreen4 rounded'
              selected={values.endTime?.toDate?.()}
              onChange={(val: Date | React.ChangeEvent<HTMLInputElement> | [Date, Date] | null) => {
                if (!val) {
                  setFieldValue('endTime', '');
                } else if (val instanceof Date) {
                  setFieldValue('endTime', moment(val).endOf('day'));
                }
              }}
              minDate={values.startTime?.toDate?.()}
              maxDate={projectEndDate.toDate()}
              placeholderText={formatMessage({ id: 'GUIDE.DATE.DATEPICKER.PLACEHOLDER' })}
              name='endTime'
            />

            {!projectInfo.equipmentSchedule[selectedItemIndex]?.equipmentTypeId && (
              <CounterWithLabel
                label={<FormattedMessage id='TIMELINE.MODAL.NUMBER.OF.MACHINES' />}
                containerClassName='w-36 mt-2'
                name='numberOfMachines'
                aria-label='number of machines'
                onDecrementClick={() => {
                  if (values.numberOfMachines <= 1) return;
                  if (values.numberOfMachines % 1 === 0) {
                    setFieldValue('numberOfMachines', values.numberOfMachines - 1);
                  } else {
                    setFieldValue('numberOfMachines', Math.floor(values.numberOfMachines));
                  }
                }}
                onIncrementClick={() => {
                  if (values.numberOfMachines % 1 === 0) {
                    setFieldValue('numberOfMachines', values.numberOfMachines + 1);
                  } else {
                    setFieldValue('numberOfMachines', Math.ceil(values.numberOfMachines));
                  }
                }}
                value={values.numberOfMachines}
              />
            )}

            <CounterWithLabel
              label={<FormattedMessage id='TIMELINE.MODAL.NUMBER.OF.DAYS.IN.USE' />}
              containerClassName='w-36 mt-2'
              name='numberOfDaysInUse'
              aria-label='number of days in use'
              onDecrementClick={() => {
                if (values.numberOfDaysInUse <= 5) return;
                if (values.numberOfDaysInUse % 1 === 0) {
                  setFieldValue('numberOfDaysInUse', values.numberOfDaysInUse - 1);
                } else {
                  setFieldValue('numberOfDaysInUse', Math.floor(values.numberOfDaysInUse));
                }
              }}
              onIncrementClick={() => {
                if (values.numberOfDaysInUse >= 7) return;
                if (values.numberOfDaysInUse % 1 === 0) {
                  setFieldValue('numberOfDaysInUse', values.numberOfDaysInUse + 1);
                } else {
                  setFieldValue('numberOfDaysInUse', Math.ceil(values.numberOfDaysInUse));
                }
              }}
              value={values.numberOfDaysInUse}
            />

            <Button type='submit' variant='primary' className='m-auto mt-6'>
              <FormattedMessage id={`TIMELINE.MODAL.BUTTON.${isItemUpdating ? 'UPDATE' : 'ADD'}`} />
            </Button>

            <ConfirmationModalDisplayer
              isConfirmationModalVisible={isConfirmationModalVisible}
              onModalClose={() => {
                isItemUpdating ? onModalClose() : onSelectedItemRemove();
              }}
              setIsConfirmationModalVisible={setIsConfirmationModalVisible}
              setUnsavedChanges={setUnsavedChanges}
            />
          </Form>
        )}
      </Formik>

      <Button variant='cancelSecondary' className='m-auto mt-8' onClick={onSelectedItemRemove}>
        <FormattedMessage id={`TIMELINE.MODAL.BUTTON.${isItemUpdating ? 'REMOVE' : 'CANCEL'}`} />
      </Button>
    </Modal>
  );
};
