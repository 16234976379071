import ReactGA from 'react-ga4';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isProd } from '../../utils/env';

export const useGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (isProd && import.meta.env.APP_GA_ID !== '') {
      ReactGA.initialize(import.meta.env.APP_GA_ID);
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }
  }, [initialized, location]);
};
