import { FC } from 'react';

// UTILS
import clsx from 'clsx';

// STYLES
import './start-content-mobile.css';

// COMPONENTS
// import { Video } from '../../molecules';

// TRANSLATIONS
import { FormattedMessage } from 'react-intl';

export const StartContentMobile: FC = () => {
  return (
    <div
      className={clsx(
        'pb-20 pt-12 px-6 h-full bg-primary-lightBackground',
        'xs:px-10',
        'sm:px-12',
        '2sm:px-24',
        'md:px-40',
        'overflow-y-auto'
      )}
    >
      <div className='flex flex-col items-start'>
        <div className='pb-6 pt-6 text-center'>
          <h1 className={clsx('text-primary-evinyGreen3 text-h3 ', 'sm:text-h2', 'md:text-h1')}>
            <FormattedMessage id='START.HEADER.LABEL' />
          </h1>
        </div>
        <div className='pb-8'>
          <p
            className={clsx(
              'pt-2 text-center text-primary-evinyGreen4 font-secondaryRegular text-body leading-7',
              'md:text-left'
            )}
          >
            <FormattedMessage id='MOBILE.ACCESS.MESSAGE' />
          </p>
        </div>

        <div className='flex flex-col w-full'>
          {/* <Video
            url='https://www.youtube.com/embed/-CtOl8hgYsw?autoplay=0&controls=2&modestbranding=1&showinfo=0&rel=0'
            description={<FormattedMessage id='START.VIDEO.DESCRIPTION' />}
            title='Demo video'
          /> */}
        </div>
      </div>
    </div>
  );
};
