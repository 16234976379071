import { Moment } from 'moment';
import { Dispatch } from 'react';
import { EquipmentModelFromApiType } from '../../api/equipment/types';

// CONSTANS:
import { AVAILABLE_WORKING_HOURS } from '../../constants';

// TYPES
import { DataCarryingFormSteps } from '../config';
import { ContactUserDataType } from 'api/project-info/types';

export type ProjectStepsInfoType = {
  [DataCarryingFormSteps.PROJECT]: ProjectStepInterface;
  [DataCarryingFormSteps.DATE]: DateStepInterface;
  [DataCarryingFormSteps.ADDRESS]: AddressStepInterface;
  [DataCarryingFormSteps.AREA]: AreaStepInterface;
  [DataCarryingFormSteps.HEATING]: HeatingStepInterface;
  equipmentSchedule: EquipmentScheduleItemType[];
  persistenceTime?: string;
};

export interface ProjectStepInterface {
  projectType: string;
  projectName: string;
  completed: boolean;
}

export interface DateStepInterface {
  startDate: Date | string;
  numberOfWeeks: number;
  startWorkingHour: AVAILABLE_WORKING_HOURS;
  endWorkingHour: AVAILABLE_WORKING_HOURS;
  completed: boolean;
}

export interface AddressStepInterface {
  locationType: LocationType;
  locationAddress: LocationAddressType;
  locationCadastre: LocationCadastreType;
  completed: boolean;
}

export type LocationAddressType = {
  address: string;
  position: PositionType;
};

export type LocationCadastreType = {
  municipality: string;
  cadastralUnitNumber: number | null;
  propertyUnitNumber: number | null;
  leaseNumber: number | null | '';
  unitNumber: number | null | '';
};

export interface AreaStepInterface {
  areaSize: number | '';
  intakeEffect: number | '';
  topLoad: number | '';
  completed: boolean;
}

export interface HeatingStepInterface {
  thermalHeating: ThermalHeatingType | '';
  completed: boolean;
}

export interface EquipmentScheduleItemType {
  id: number;
  startTime: Moment;
  endTime: Moment;
  powerType: PowerType;
  equipmentTypeId: string;
  equipmentModel: EquipmentModelInterface;
  numberOfMachines: number;
  numberOfDaysInUse: number;
  isItemOutdated: boolean;
}

export interface EquipmentModelInterface {
  id: string;
  equipmentTypeId: string;
  additionalInformation: number;
  additionalInformationUnit: string;
  powerUsageBattery: number | null;
  powerUsageGrid: number | null;
  powerFastCharging: number | null;
  powerNormalCharging: number | null;
}

export type ProjectStoreType = {
  projectInfo: ProjectStepsInfoType;
  resetProjectInfo: ProjectStepsInfoType | null;
  equipmentModels: EquipmentModelFromApiType[];
  projectId: string;
  contactUserData: ContactUserDataType;
};

export type ProjectContextType = ProjectStoreType & {
  updateProjectInfo: Dispatch<StepActionType>;
};

export type StepActionType =
  | {
      payload:
        | ProjectStepInterface
        | DateStepInterface
        | AddressStepInterface
        | AreaStepInterface
        | HeatingStepInterface;
      step: number;
      type: ProjectInfoActionType.UpdateStepInfo;
    }
  | {
      payload: EquipmentScheduleItemType;
      type: ProjectInfoActionType.AddEquipment;
    }
  | {
      payload: number;
      type: ProjectInfoActionType.RemoveEquipment;
    }
  | {
      payload: EquipmentScheduleItemType;
      itemIndex: number;
      type: ProjectInfoActionType.UpdateEquipment;
    }
  | {
      type: ProjectInfoActionType.ResetProjectInfo;
    }
  | {
      payload: number;
      type: ProjectInfoActionType.MoveAllEquipmentsStartTime;
    }
  | {
      payload: {
        projectEndDate: Moment;
        visibleTimelineEndDate: Moment;
      };
      type: ProjectInfoActionType.CheckForEveryTimelineItemOutOfRange;
    }
  | {
      payload: {
        projectEndDate: Moment;
        projectStartDate: Moment;
        itemIndex: number;
      };
      type: ProjectInfoActionType.CheckForSpecificTimelineItemOutOfRange;
    }
  | {
      type: ProjectInfoActionType.UpdateProjectInfo;
      payload: ProjectStepsInfoType;
    }
  | {
      type: ProjectInfoActionType.UpdateEquipmentModels;
      payload: EquipmentModelFromApiType[];
    }
  | {
      type: ProjectInfoActionType.UpdateResetProjectInfo;
      payload: ProjectStepsInfoType | null;
    }
  | {
      type: ProjectInfoActionType.UpdateProjectId;
      payload: string;
    }
  | {
      type: ProjectInfoActionType.UpdateContactUserData;
      payload: ContactUserDataType;
    };

export enum ProjectInfoActionType {
  AddEquipment = 'ADD_EQUIPMENT',
  RemoveEquipment = 'REMOVE_EQUIPMENT',
  UpdateEquipment = 'UPDATE_EQUIPMENT',
  UpdateStepInfo = 'UPDATE_STEP_INFO',
  ResetProjectInfo = 'RESET_PROJECT_INFO',
  MoveAllEquipmentsStartTime = 'MOVE_ALL_EQUIPMENTS_START_TIME',
  CheckForEveryTimelineItemOutOfRange = 'CHECK_FOR_EVERY_TIMELINE_ITEM_OUT_OF_RANGE',
  CheckForSpecificTimelineItemOutOfRange = 'CHECK_FOR_SPECIFIC_TIMELINE_ITEM_OUT_OF_RANGE',
  UpdateProjectInfo = 'UPDATE_PROJECT_INFO',
  UpdateEquipmentModels = 'UPDATE_EQUIPMENT_MODELS',
  UpdateResetProjectInfo = 'UPDATE_RESET_PROJECT_INFO',
  UpdateProjectId = 'UPDATE_PROJECT_ID',
  UpdateContactUserData = 'UPDATE_CONTACT_USER_DATA',
}

export type PositionType = {
  lat: number | null;
  lng: number | null;
};

export type ProjectCategoryType = {
  domain: string;
  type: string;
};

export enum PowerType {
  Electric = 'electric',
  Other = 'other',
}

export enum LocationType {
  Cadastre = 'cadastre',
  Address = 'address',
}

export enum ThermalHeatingType {
  Yes = 'yes',
  No = 'no',
  Unknown = 'unknown',
}

export const isAddressStepInterface = (
  value:
    | ProjectStepInterface
    | DateStepInterface
    | AddressStepInterface
    | AreaStepInterface
    | HeatingStepInterface
): value is AddressStepInterface => {
  return (value as AddressStepInterface).locationType !== undefined;
};
