import { useRef, useState } from 'react';
import clsx from 'clsx';

// COMPONENTS:
import { ContactUsBanner, Tabs } from '../../components/molecules';
import { Timeline, TimelineFooter, TimelineProjectDetails } from '../../components/organisms';

// TRANSLATIONS:
import { FormattedMessage } from 'react-intl';

// ROUTES
import { RootRoutes } from '../../router';

export const Equipment = () => {
  const timelineWrapperRef = useRef<HTMLDivElement>(null);
  const tabsContent = [
    {
      label: <FormattedMessage id='TAB.GUIDE' />,
      number: '01',
      isActive: false,
      isValid: true,
      pathTo: RootRoutes.GUIDE,
    },
    { label: <FormattedMessage id='TAB.EQUIPMENT' />, number: '02', isActive: true },
  ];

  const [isDetailsViewVisible, setIsDetailsViewVisible] = useState(false);

  const toggleDetailsViewVisibility = () => setIsDetailsViewVisible((prevState) => !prevState);

  return (
    <>
      {isDetailsViewVisible ? (
        <TimelineProjectDetails />
      ) : (
        <>
          <div className='flex flex-auto flex-col bg-primary-lightBackground overflow-y-auto'>
            <Tabs
              className={clsx(
                'pt-8 px-4',
                'xs:px-6',
                'sm:px-8',
                '2sm:px-12',
                'md:px-16',
                'lg:flex'
              )}
              content={tabsContent}
            />
            <div
              ref={timelineWrapperRef}
              className={clsx(
                'flex flex-col flex-grow pt-6 px-6',
                'min-h-350px',
                'xs:px-10',
                'sm:px-12',
                '2sm:px-24',
                'md:px-30',
                'lg:pt-12'
              )}
            >
              <Timeline timelineRef={timelineWrapperRef} />
            </div>
            <ContactUsBanner className='bottom-26 left-5' />
          </div>
        </>
      )}
      <TimelineFooter
        isDetailsViewVisible={isDetailsViewVisible}
        toggleDetailsViewVisibility={toggleDetailsViewVisibility}
      />
    </>
  );
};
