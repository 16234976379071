import { FC, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// COMPONENTS:
import Button from '../../atoms/primitive/Button';
import Modal from '../../atoms/primitive/Modal';

// ICONS:
import { CheckCircleIcon } from '../../../assets/images';

// STORE:
import { ProjectInfoContext } from '../../../store';

// API:
import { updateProjectInfoData } from '../../../api/project-info';
import { parseProjectInfoData } from '../../../api/project-info/project-info-utils';

// TYPES:
import { QueryKeysNames } from '../../../api/types';
import { ProjectInfoActionType } from '../../../store/project-info';

type SaveUpdatedProjectModalProps = {
  onModalClose: () => void;
  isOpen: boolean;
  setProjectSaved: (value: boolean) => void;
};

enum SaveUpdateModalSteps {
  DEFAULT = 'default',
  SUCCESS = 'success',
}

export const SaveUpdatedProjectModal: FC<SaveUpdatedProjectModalProps> = ({
  isOpen,
  onModalClose,
  setProjectSaved,
}) => {
  const [step, setStep] = useState(SaveUpdateModalSteps.DEFAULT);

  const { projectInfo, equipmentModels, projectId, updateProjectInfo, contactUserData } =
    useContext(ProjectInfoContext);

  const queryClient = useQueryClient();

  const parsedProjectInfoData = parseProjectInfoData(contactUserData, projectInfo, equipmentModels);

  const { mutate: saveUpdatedProject, isLoading } = useMutation(
    async () => await updateProjectInfoData(projectId, parsedProjectInfoData),
    {
      onSuccess: () => {
        setStep(SaveUpdateModalSteps.SUCCESS);
        setTimeout(() => {
          onModalClose();
          setProjectSaved(true);
          queryClient.invalidateQueries([QueryKeysNames.GET_PROJECT_INFO_DATA]);
          updateProjectInfo({
            type: ProjectInfoActionType.UpdateResetProjectInfo,
            payload: projectInfo,
          });
        }, 2000);
      },
    }
  );

  const renderModalContent = (step: SaveUpdateModalSteps) => {
    switch (step) {
      case SaveUpdateModalSteps.DEFAULT:
        return (
          <>
            <h2 className='block text-center text-primary-evinyGreen4 text-h2'>
              <FormattedMessage id='TIMELINE.MODAL.UPDATE.PROJECT.TITLE' />
            </h2>
            <p className='texr-body py-8 text-center text-primary-evinyGreen4 whitespace-pre-line'>
              <FormattedMessage id='TIMELINE.MODAL.UPDATE.PROJECT.DESCRIPTION' />
            </p>
            <div className='flex justify-evenly'>
              <Button
                variant='secondary'
                onClick={() => {
                  onModalClose();
                }}
              >
                <FormattedMessage id='TIMELINE.MODAL.UPDATE.PROJECT.CANCEL.BUTTON' />
              </Button>
              <Button variant='primary' isLoading={isLoading} onClick={() => saveUpdatedProject()}>
                <FormattedMessage id='TIMELINE.MODAL.UPDATE.PROJECT.CONFIRM.BUTTON' />
              </Button>
            </div>
          </>
        );
      case SaveUpdateModalSteps.SUCCESS:
        return (
          <div className='flex flex-col items-center justify-center w-96 h-48'>
            <CheckCircleIcon />
            <h2 className='block pt-8 text-center text-primary-evinyGreen4 text-h4'>
              <FormattedMessage id='TIMELINE.MODAL.UPDATE.PROJECT.UPDATED' />
            </h2>
          </div>
        );
      default:
        return;
    }
  };

  return (
    <Modal
      withBackground
      isOpen={isOpen}
      onModalClose={onModalClose}
      afterLeave={() => setStep(SaveUpdateModalSteps.DEFAULT)}
      className='px-16 py-16 max-w-xl'
    >
      {renderModalContent(step)}
    </Modal>
  );
};
