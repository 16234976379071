import React, { FC, InputHTMLAttributes } from 'react';
import clsx from 'clsx';
import { useIntl } from 'react-intl';

// COMPONENTS
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';

// STYLES
import './date-picker.css';

// ICONS
import { LeftArrow, RightArrow } from '../../../assets/images';
import { ErrorMessage } from 'formik';
import { DatePickerInput } from '../DatePickerInput';

type DatePickerProps = {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (date: Date | [Date, Date], event: React.SyntheticEvent<any, Event>) => void;
  className?: string;
  showError?: boolean;
} & ReactDatePickerProps &
  InputHTMLAttributes<HTMLInputElement>;

const DatePicker: FC<DatePickerProps> = ({
  selected,
  onChange,
  name,
  showError = true,
  className,
  ...props
}) => {
  const { formatMessage } = useIntl();

  const months = [
    { monthNumber: 0, monthName: formatMessage({ id: 'MONTH.JANUARY' }) },
    { monthNumber: 1, monthName: formatMessage({ id: 'MONTH.FEBRUARY' }) },
    { monthNumber: 2, monthName: formatMessage({ id: 'MONTH.MARCH' }) },
    { monthNumber: 3, monthName: formatMessage({ id: 'MONTH.APRIL' }) },
    { monthNumber: 4, monthName: formatMessage({ id: 'MONTH.MAY' }) },
    { monthNumber: 5, monthName: formatMessage({ id: 'MONTH.JUNE' }) },
    { monthNumber: 6, monthName: formatMessage({ id: 'MONTH.JULY' }) },
    { monthNumber: 7, monthName: formatMessage({ id: 'MONTH.AUGUST' }) },
    { monthNumber: 8, monthName: formatMessage({ id: 'MONTH.SEPTEMBER' }) },
    { monthNumber: 9, monthName: formatMessage({ id: 'MONTH.OCTOBER' }) },
    { monthNumber: 10, monthName: formatMessage({ id: 'MONTH.NOVEMBER' }) },
    { monthNumber: 11, monthName: formatMessage({ id: 'MONTH.DECEMBER' }) },
  ];

  return (
    <div>
      <ReactDatePicker
        dateFormat='dd.MM.yyyy'
        className='absolute px-4 py-2 w-full text-primary-evinyGreen4 border border-primary-evinyGreen4 rounded-lg'
        selected={selected}
        onChange={onChange}
        showWeekNumbers
        customInput={
          <DatePickerInput className={className} value={String(selected)} onChange={onChange} />
        }
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className='relative z-5 flex justify-between mx-4 my-2'>
            <button
              type='button'
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              className={clsx(
                prevMonthButtonDisabled && 'opacity-30',
                'hover:opacity-50 transition duration-100 ease-in-out'
              )}
            >
              <LeftArrow />
            </button>

            <span
              className={clsx(
                'flex items-center justify-center text-primary-evinyGreen4 text-h3',
                'lg:justify-start'
              )}
            >
              {months[date.getMonth()].monthName}
            </span>
            <span className='text-h6 absolute bottom-0 left-11/25 -mb-4 text-primary-evinyGreen4'>
              {date.getFullYear()}
            </span>

            <button
              type='button'
              onClick={increaseMonth}
              className={clsx(
                nextMonthButtonDisabled && 'opacity-30',
                'hover:opacity-50 transition duration-100 ease-in-out'
              )}
              disabled={nextMonthButtonDisabled}
            >
              <RightArrow />
            </button>
          </div>
        )}
        {...props}
      />
      <div className='relative'>
        {showError && (
          <ErrorMessage
            component='div'
            className='absolute bottom-0 left-0 -mb-4 text-other-errorRed text-inputLabel'
            name={name}
          />
        )}
      </div>
    </div>
  );
};

export default DatePicker;
