import { FC } from 'react';
import { IconPropsType } from '../../types';

export const DownArrow: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='18'
      height='10'
      viewBox='0 0 18 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17 0.999587L9 8.28125L1 0.999587'
        stroke='white'
        strokeWidth='2'
        strokeMiterlimit='10'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
