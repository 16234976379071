import moment from 'moment';
import React, { useEffect, useReducer, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Loader } from '../../components/atoms';
import Button from '../../components/atoms/primitive/Button';
import Modal from '../../components/atoms/primitive/Modal';

// CONSTANTS
import { AVAILABLE_WORKING_HOURS } from '../../constants';
import { useExistingProject } from '../../helpers/hooks/use-existing-project';

// TYPES:
import {
  ProjectInfoActionType,
  ProjectContextType,
  EquipmentScheduleItemType,
  StepActionType,
  LocationType,
  ProjectStepsInfoType,
  ProjectStoreType,
} from './types';
import { useLocation } from 'react-router-dom';
import { RootRoutes } from '../../router';
import { isEmpty } from 'lodash';
import { ContactUserDataType } from 'api/project-info/types';

const localStorageToProjectInfo = (): ProjectStepsInfoType | null => {
  // Compare the version of the current project in the local storage with the version of the currently running application code
  // If they do not match, we assume that local storage should be cleared.
  localStorage.removeItem('TEMP_BUILD_VERSION'); // TODO: Remove in some later update, used now to
  const versionKey = 'PROJECT_VERSION';
  let currentVersion = import.meta.env.APP_PROJECT_VERSION;
  if (typeof currentVersion === 'undefined') currentVersion = '0.0.0';
  const storedVersion = localStorage.getItem(versionKey);
  if (currentVersion) localStorage.setItem(versionKey, currentVersion); // Update with newest version. Will not affect the variable above

  const projectInfo = localStorage.getItem('ProjectInfo');
  if (!projectInfo) {
    return null;
  }

  if (currentVersion !== storedVersion) {
    localStorage.removeItem('ProjectInfo');
    return null;
  }

  const parsedequipmentSchedule = JSON.parse(projectInfo).equipmentSchedule.map(
    (singleEquipment: EquipmentScheduleItemType) => {
      return {
        ...singleEquipment,
        startTime: moment(singleEquipment.startTime),
        endTime: moment(singleEquipment.endTime),
        equipmentModel: { ...singleEquipment.equipmentModel },
      };
    }
  );
  return {
    ...JSON.parse(projectInfo),
    equipmentSchedule: parsedequipmentSchedule,
  };
};

export const projectInfoEmptyObject: ProjectStepsInfoType = {
  0: {
    projectType: '',
    projectName: '',
    completed: false,
  },
  1: {
    startDate: '',
    numberOfWeeks: 0,
    startWorkingHour: AVAILABLE_WORKING_HOURS['07:00'],
    endWorkingHour: AVAILABLE_WORKING_HOURS['15:00'],
    completed: false,
  },
  2: {
    locationType: LocationType.Address,
    locationAddress: {
      address: '',
      position: { lat: null, lng: null },
    },
    locationCadastre: {
      municipality: '',
      cadastralUnitNumber: null,
      propertyUnitNumber: null,
      leaseNumber: null,
      unitNumber: null,
    },
    completed: false,
  },
  3: {
    areaSize: '',
    intakeEffect: '',
    topLoad: '',
    completed: false,
  },
  4: {
    thermalHeating: '',
    completed: false,
  },
  equipmentSchedule: [],
};

const emptyContactUserData: ContactUserDataType = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  comment: '',
  companyNumber: '',
  customerContactAgreement: true,
  gdprAgreement: true,
  shareInformationAgreement: true,
};

export const initialStore: ProjectStoreType = {
  projectInfo: localStorageToProjectInfo() || projectInfoEmptyObject,
  resetProjectInfo: null,
  equipmentModels: [],
  projectId: '',
  contactUserData: emptyContactUserData,
};

export const ProjectInfoContext = React.createContext<ProjectContextType>({
  projectInfo: initialStore.projectInfo,
  equipmentModels: initialStore.equipmentModels,
  resetProjectInfo: initialStore.resetProjectInfo,
  projectId: initialStore.projectId,
  contactUserData: initialStore.contactUserData,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateProjectInfo: () => {},
});

export const projectInfoReducer = (state: ProjectStoreType, action: StepActionType) => {
  switch (action.type) {
    case ProjectInfoActionType.AddEquipment:
      return {
        ...state,
        projectInfo: {
          ...state.projectInfo,
          equipmentSchedule: [...state.projectInfo.equipmentSchedule, action.payload],
        },
      };
    case ProjectInfoActionType.RemoveEquipment: {
      const equipmentSchedule = state.projectInfo.equipmentSchedule
        .filter((singleEquipment) => {
          return singleEquipment.id !== action.payload;
        })
        .map((singleEquipment, index) => {
          return {
            ...singleEquipment,
            id: index + 1,
            group: index + 1,
          };
        });
      return { ...state, projectInfo: { ...state.projectInfo, equipmentSchedule } };
    }
    case ProjectInfoActionType.UpdateEquipment:
      return {
        ...state,
        projectInfo: {
          ...state.projectInfo,
          equipmentSchedule: state.projectInfo.equipmentSchedule.map(
            (item: EquipmentScheduleItemType, index: number) => {
              if (index !== action.itemIndex) return item;

              return {
                ...item,
                ...action.payload,
              };
            }
          ),
        },
      };
    case ProjectInfoActionType.MoveAllEquipmentsStartTime:
      return {
        ...state,
        projectInfo: {
          ...state.projectInfo,
          equipmentSchedule: state.projectInfo.equipmentSchedule.map(
            (item: EquipmentScheduleItemType) => {
              return {
                ...item,
                startTime: item.startTime.add(action.payload, 'days'),
                endTime: item.endTime.add(action.payload, 'days').endOf('day'),
              };
            }
          ),
        },
      };
    case ProjectInfoActionType.UpdateStepInfo:
      return {
        ...state,
        projectInfo: {
          ...state.projectInfo,
          [action.step]: { ...action.payload, completed: true },
        },
      };
    case ProjectInfoActionType.ResetProjectInfo: {
      localStorage.removeItem('ProjectInfo');
      return {
        ...state,
        projectInfo: projectInfoEmptyObject,
        resetProjectInfo: null,
        projectId: '',
      };
    }
    case ProjectInfoActionType.CheckForEveryTimelineItemOutOfRange: {
      return {
        ...state,
        projectInfo: {
          ...state.projectInfo,
          equipmentSchedule: state.projectInfo.equipmentSchedule.map(
            (item: EquipmentScheduleItemType) => {
              const itemLengthInDays = item.endTime.diff(item.startTime, 'days');
              let newStartDate = item.startTime;
              let newEndDate = item.endTime;
              // Check if item is outside the visible project date range - if yes, move item to the right corner
              if (moment(item.startTime).isSameOrAfter(action.payload.visibleTimelineEndDate)) {
                newStartDate = moment(action.payload.visibleTimelineEndDate).subtract(
                  itemLengthInDays,
                  'days'
                );
                newEndDate = moment(newStartDate).add(itemLengthInDays, 'days').endOf('day');
              }
              return {
                ...item,
                isItemOutdated: !moment(item.endTime).isSameOrBefore(action.payload.projectEndDate),
                startTime: newStartDate,
                endTime: newEndDate,
              };
            }
          ),
        },
      };
    }
    case ProjectInfoActionType.CheckForSpecificTimelineItemOutOfRange: {
      return {
        ...state,
        projectInfo: {
          ...state.projectInfo,
          equipmentSchedule: state.projectInfo.equipmentSchedule.map(
            (item: EquipmentScheduleItemType, index) => {
              if (index !== action.payload.itemIndex) return item;

              return {
                ...item,
                isItemOutdated:
                  !moment(item.endTime).isSameOrBefore(action.payload.projectEndDate) ||
                  moment(item.startTime).isBefore(action.payload.projectStartDate),
              };
            }
          ),
        },
      };
    }
    case ProjectInfoActionType.UpdateProjectInfo: {
      return {
        ...state,
        projectInfo: {
          ...action.payload,
        },
      };
    }
    case ProjectInfoActionType.UpdateContactUserData: {
      return {
        ...state,
        contactUserData: action.payload,
      };
    }
    case ProjectInfoActionType.UpdateEquipmentModels: {
      return {
        ...state,
        equipmentModels: action.payload,
      };
    }
    case ProjectInfoActionType.UpdateResetProjectInfo: {
      return {
        ...state,
        resetProjectInfo: action.payload,
      };
    }
    case ProjectInfoActionType.UpdateProjectId: {
      return {
        ...state,
        projectId: action.payload,
      };
    }
    default:
      return { ...state };
  }
};

export const ProjectInfoProvider: React.FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const { pathname } = useLocation();
  const showEditModal = pathname !== RootRoutes.REPORT;

  const [
    { projectInfo, resetProjectInfo, equipmentModels, projectId, contactUserData },
    projectInfoDispatch,
  ] = useReducer(projectInfoReducer, initialStore);
  const { isLoading, hasId } = useExistingProject(projectInfoDispatch);
  const [modalIsOpen, setModalIsOpen] = useState(hasId);

  useEffect(() => {
    if (isEmpty(projectId)) localStorage.setItem('ProjectInfo', JSON.stringify(projectInfo));
  }, [projectId, projectInfo]);

  const onModalClose = () => {
    setModalIsOpen(false);
  };

  const updateProjectModal = (
    <Modal
      withBackground
      isOpen={modalIsOpen}
      onModalClose={onModalClose}
      className='px-20 py-16 w-120'
    >
      <h2 className='block mb-4 text-left text-primary-evinyGreen4 text-h4'>
        {projectInfo[0].projectName}
      </h2>
      <p className='text-left text-other-gray whitespace-pre-line'>
        <FormattedMessage id='TIMELINE.UNSAVED.CHANGES.INFORMATION.MODAL.DESCRIPTION' />
      </p>
      <Button type='button' variant='primary' onClick={onModalClose} className='mt-6'>
        <FormattedMessage id='TIMELINE.UNSAVED.CHANGES.INFORMATION.MODAL.CONFIRM.BUTTON' />
      </Button>
    </Modal>
  );

  if (isLoading)
    return (
      <div className='flex items-center justify-center w-full h-full'>
        <Loader type='Oval' />
      </div>
    );

  return (
    <ProjectInfoContext.Provider
      value={{
        projectInfo,
        resetProjectInfo,
        equipmentModels,
        projectId,
        contactUserData,
        updateProjectInfo: projectInfoDispatch,
      }}
    >
      {children}
      {showEditModal && updateProjectModal}
    </ProjectInfoContext.Provider>
  );
};

export * from './types';
