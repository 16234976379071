import { FC, LabelHTMLAttributes } from 'react';
import clsx from 'clsx';

export const Label: FC<LabelHTMLAttributes<HTMLLabelElement>> = ({
  children,
  className,
  htmlFor,
  ...props
}) => {
  return (
    <label
      htmlFor={htmlFor}
      className={clsx(className, 'text-primary-evinyGreen3', className)}
      {...props}
    >
      {children}
    </label>
  );
};
