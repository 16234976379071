import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const MinusIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='18'
      height='19'
      viewBox='0 0 18 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.92188 10.4542H13.0781C13.2891 10.4542 13.5 10.2784 13.5 10.0323V8.90729C13.5 8.69635 13.2891 8.48541 13.0781 8.48541H4.92188C4.67578 8.48541 4.5 8.69635 4.5 8.90729V10.0323C4.5 10.2784 4.67578 10.4542 4.92188 10.4542ZM17.7188 9.46979C17.7188 4.65338 13.8164 0.751038 9 0.751038C4.18359 0.751038 0.28125 4.65338 0.28125 9.46979C0.28125 14.2862 4.18359 18.1885 9 18.1885C13.8164 18.1885 17.7188 14.2862 17.7188 9.46979ZM16.0312 9.46979C16.0312 13.3721 12.8672 16.501 9 16.501C5.09766 16.501 1.96875 13.3721 1.96875 9.46979C1.96875 5.6026 5.09766 2.43854 9 2.43854C12.8672 2.43854 16.0312 5.6026 16.0312 9.46979Z'
        fill={tailwindConfig.theme.extend.colors.other.gray}
      />
    </svg>
  );
};
