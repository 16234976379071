import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';

// COMPONENTS:
import { CustomMarker } from 'react-calendar-timeline';

// TYPES:
import {
  ProjectInfoActionType,
  ProjectStepsInfoType,
  StepActionType,
} from '../../../store/project-info';

// STYLES:
import tailwindConfig from '../../../../tailwind.config';

export enum ITEM_WIDTH_BREAKPOINTS {
  WIDE = 460,
  MEDIUM = 290,
  NARROW = 135,
  TINY = 48,
}

export const displayRangeDateErrorNotification = () =>
  toast.error(<FormattedMessage id='TIMELINE.MOVE.ITEM.ERROR' />, {
    toastId: 'TIMELINE.MOVE.ITEM.ERROR',
    icon: false,
  });

export const checkForSpecificDateWhenResizingOrMoving = (
  time: number,
  earliestPossibleDate: Moment,
  latestPossibleDate: Moment,
  endDate: Moment,
  itemLengthInDays?: number,
  startDate?: Moment
) => {
  if (moment(time).unix() < earliestPossibleDate.unix()) {
    displayRangeDateErrorNotification();
    return earliestPossibleDate;
  } else if (itemLengthInDays && latestPossibleDate.unix() > moment(endDate).unix()) {
    displayRangeDateErrorNotification();
    return moment(endDate).subtract(itemLengthInDays, 'days');
  } else if (
    moment(time).unix() > latestPossibleDate.unix() &&
    startDate &&
    startDate.unix() > latestPossibleDate.unix()
  ) {
    displayRangeDateErrorNotification();
    return moment(time);
  } else if (
    moment(time).unix() > latestPossibleDate.unix() &&
    startDate &&
    startDate.unix() < latestPossibleDate.unix()
  ) {
    displayRangeDateErrorNotification();
    return latestPossibleDate;
  }

  return moment(time);
};

export const handleItemMove = (
  itemId: number,
  startTime: number,
  endDate: Moment,
  startDate: string | Date,
  projectInfo: ProjectStepsInfoType,
  updateProjectInfo: React.Dispatch<StepActionType>
) => {
  const itemIndex = itemId - 1;
  const itemLengthInDays = moment(projectInfo.equipmentSchedule[itemIndex].endTime).diff(
    moment(projectInfo.equipmentSchedule[itemIndex].startTime),
    'days'
  );
  const newStartDate = checkForSpecificDateWhenResizingOrMoving(
    startTime,
    moment(startDate),
    moment(startTime).add(itemLengthInDays, 'days').endOf('day'),
    endDate,
    itemLengthInDays
  );

  updateProjectInfo({
    type: ProjectInfoActionType.UpdateEquipment,
    itemIndex: itemIndex,
    payload: {
      id: projectInfo.equipmentSchedule[itemIndex].id,
      startTime: moment(newStartDate),
      endTime: moment(newStartDate).add(itemLengthInDays, 'days').endOf('day'),
      powerType: projectInfo.equipmentSchedule[itemIndex].powerType,
      equipmentTypeId: projectInfo.equipmentSchedule[itemIndex].equipmentTypeId,
      equipmentModel: { ...projectInfo.equipmentSchedule[itemIndex].equipmentModel },
      numberOfMachines: projectInfo.equipmentSchedule[itemIndex].numberOfMachines,
      numberOfDaysInUse: projectInfo.equipmentSchedule[itemIndex].numberOfDaysInUse,
      isItemOutdated: projectInfo.equipmentSchedule[itemIndex].isItemOutdated,
    },
  });
};

export const handleTimeChange = (
  visibleTimeStart: number,
  visibleTimeEnd: number,
  updateScrollCanvas: (start: number, end: number) => void,
  endDate: Moment,
  startDate: string | Date,
  defaultTimeRange: number
) => {
  const minimumScrollDate = moment(startDate).subtract(1, 'month').valueOf();
  const maximumScrollDate = moment(endDate).add(1, 'month').valueOf();

  if (defaultTimeRange > maximumScrollDate - minimumScrollDate) {
    return;
  } else {
    if (visibleTimeStart < minimumScrollDate && visibleTimeEnd > maximumScrollDate) {
      updateScrollCanvas(minimumScrollDate, maximumScrollDate);
    } else if (visibleTimeStart < minimumScrollDate) {
      updateScrollCanvas(
        minimumScrollDate,
        minimumScrollDate + (visibleTimeEnd - visibleTimeStart)
      );
    } else if (visibleTimeEnd > maximumScrollDate) {
      updateScrollCanvas(
        maximumScrollDate - (visibleTimeEnd - visibleTimeStart),
        maximumScrollDate
      );
    } else {
      updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
    }
  }
};

export const handleItemResize = (
  itemId: number,
  time: number,
  edge: 'left' | 'right',
  endDate: Moment,
  startDate: string | Date,
  projectInfo: ProjectStepsInfoType,
  updateProjectInfo: React.Dispatch<StepActionType>
) => {
  const itemIndex = itemId - 1;
  if (
    edge === 'right' &&
    projectInfo.equipmentSchedule[itemIndex].startTime.unix() < moment(time).unix()
  ) {
    updateProjectInfo({
      type: ProjectInfoActionType.UpdateEquipment,
      itemIndex: itemIndex,
      payload: {
        id: projectInfo.equipmentSchedule[itemIndex].id,
        startTime: projectInfo.equipmentSchedule[itemIndex].startTime,
        endTime: checkForSpecificDateWhenResizingOrMoving(
          time,
          moment(startDate),
          endDate,
          endDate,
          undefined,
          projectInfo.equipmentSchedule[itemIndex].startTime
        ),
        powerType: projectInfo.equipmentSchedule[itemIndex].powerType,
        equipmentTypeId: projectInfo.equipmentSchedule[itemIndex].equipmentTypeId,
        equipmentModel: { ...projectInfo.equipmentSchedule[itemIndex].equipmentModel },
        numberOfMachines: projectInfo.equipmentSchedule[itemIndex].numberOfMachines,
        numberOfDaysInUse: projectInfo.equipmentSchedule[itemIndex].numberOfDaysInUse,
        isItemOutdated: projectInfo.equipmentSchedule[itemIndex].isItemOutdated,
      },
    });
  } else if (
    edge === 'left' &&
    projectInfo.equipmentSchedule[itemIndex].endTime.unix() > moment(time).unix()
  )
    updateProjectInfo({
      type: ProjectInfoActionType.UpdateEquipment,
      itemIndex: itemIndex,
      payload: {
        id: projectInfo.equipmentSchedule[itemIndex].id,
        startTime: checkForSpecificDateWhenResizingOrMoving(
          time,
          moment(startDate),
          endDate,
          endDate
        ),
        endTime: projectInfo.equipmentSchedule[itemIndex].endTime,
        powerType: projectInfo.equipmentSchedule[itemIndex].powerType,
        equipmentTypeId: projectInfo.equipmentSchedule[itemIndex].equipmentTypeId,
        equipmentModel: { ...projectInfo.equipmentSchedule[itemIndex].equipmentModel },
        numberOfMachines: projectInfo.equipmentSchedule[itemIndex].numberOfMachines,
        numberOfDaysInUse: projectInfo.equipmentSchedule[itemIndex].numberOfDaysInUse,
        isItemOutdated: projectInfo.equipmentSchedule[itemIndex].isItemOutdated,
      },
    });
  else
    toast.error(<FormattedMessage id='TIMELINE.TOO.SHORT.ITEM.ERROR' />, {
      toastId: 'TIMELINE.TOO.SHORT.ITEM.ERROR',
      icon: false,
    });
};

export const highlightActiveTimelineArea = (
  lengthOfGrayInDays: number,
  startGrayDate: Date | Moment
) => {
  let previousWidthOfSingleGrayLine = 0;
  return new Array(lengthOfGrayInDays + 2).fill(null).map((_, index) => (
    <CustomMarker
      key={index}
      date={moment(startGrayDate).subtract(1, 'days').add(index, 'days').valueOf()}
    >
      {({ styles }) => {
        if (!index) {
          previousWidthOfSingleGrayLine = Number(styles.left);

          return null;
        }

        const customStyles = {
          ...styles,
          backgroundColor: tailwindConfig.theme.extend.colors.other.white,
          width: Number(styles.left) - previousWidthOfSingleGrayLine + 1,
        };
        previousWidthOfSingleGrayLine = Number(styles.left);
        return <div style={customStyles} />;
      }}
    </CustomMarker>
  ));
};
