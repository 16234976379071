import { FC } from 'react';
import { ContactSection } from './ContactSection';
import { ProjectInformationSection } from './ProjectInformationSection';
import { ChartAndTimelineSection } from './ChartAndTimelineSection';
import { FlexibleSolutionsSection } from './FlexibleSolutionsSection';
import { useQueryParams } from '../../helpers/hooks';
import { ProjectErrorHeader } from './ProjectErrorHeader';

export const Report: FC = () => {
  const queryParams = useQueryParams();
  const projectId = queryParams.get('uuid');

  return (
    <div className='relative flex flex-col h-full text-other-white overflow-y-auto'>
      {projectId ? (
        <>
          <ProjectInformationSection projectId={projectId} />

          <ChartAndTimelineSection />
        </>
      ) : (
        <ProjectErrorHeader />
      )}
      <FlexibleSolutionsSection />
      <ContactSection />
    </div>
  );
};
