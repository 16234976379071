// STORE
import { EquipmentScheduleItemType, PowerType } from '../../store/project-info';

type Machines = {
  numberOfMachines: number;
  numberOfElectricMachines: number;
};

export const calculateNumberOfMachines = (
  equipmentSchedule: EquipmentScheduleItemType[]
): Machines => {
  let numberOfElectricMachines = 0;
  const numberOfMachines = equipmentSchedule.reduce((numberOfMachines, machine) => {
    if (machine.equipmentTypeId) {
      if (machine.powerType === PowerType.Electric) {
        numberOfElectricMachines += 1;
      }
      return numberOfMachines + 1;
    }
    return numberOfMachines;
  }, 0);

  return { numberOfMachines, numberOfElectricMachines };
};
