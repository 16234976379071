import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const DropdownArrowIcon: FC<IconPropsType> = ({
  className,
  color = tailwindConfig.theme.extend.colors.primary.evinyGreen4,
}) => {
  return (
    <svg
      className={className}
      width='18'
      height='10'
      viewBox='0 0 18 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1 1L9 9L17 1' stroke={color} />
    </svg>
  );
};
