import { FC, InputHTMLAttributes, ReactNode } from 'react';
import { ReactDatePickerProps } from 'react-datepicker';

// COMPONENTS
import { Label } from '../../atoms';
import Datepicker from '../Datepicker';

type DatePickerWithLabelProps = {
  name: string;
  label: string | ReactNode;
  onChange: (date: Date | [Date, Date]) => void;
  className?: string;
  showError?: boolean;
} & ReactDatePickerProps &
  InputHTMLAttributes<HTMLInputElement>;

const DatepickerWithLabel: FC<DatePickerWithLabelProps> = ({ label, ...props }) => {
  return (
    <>
      <Label className='block float-left mb-2 mt-4 text-primary-evinyGreen3 font-secondaryMedium text-inputLabel'>
        {label}
      </Label>
      <Datepicker {...props} />
    </>
  );
};

export default DatepickerWithLabel;
