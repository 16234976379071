import { SingleDropdownOptionType } from '../../types';
import { EquipmentModelFromApiType } from './types';

export const parseEquipmentModelOptions = (equipmentModelOptions: EquipmentModelFromApiType[]) => {
  return equipmentModelOptions.map(
    (equipmentModelOption) =>
      ({
        id: equipmentModelOption.uuid,
        label: equipmentModelOption.model,
        nestedParams: {
          forTypeId: equipmentModelOption.type,
          additionalInformation: equipmentModelOption.additionalInformation,
          additionalInformationUnit: equipmentModelOption.additionalInformationUnit,
          powerUsageBattery: equipmentModelOption.power_usage_battery,
          powerUsageGrid: equipmentModelOption.power_usage_grid,
          powerFastCharging: equipmentModelOption.power_fast_charging,
          powerNormalCharging: equipmentModelOption.power_normal_charging,
          generic: equipmentModelOption.generic,
        },
      } as SingleDropdownOptionType)
  );
};
