import { FC } from 'react';
import { IconPropsType } from '../../../types';
import tailwindConfig from '../../../../tailwind.config';

export const StamperEquipmentIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.6719 19.9375L16.4531 18.707L17.2344 4.71093V4.6875C17.238 4.66421 17.238 4.64046 17.2344 4.61718C17.2363 4.59378 17.2363 4.57027 17.2344 4.54687C17.2208 4.51671 17.2023 4.48898 17.1797 4.46484C17.1743 4.44933 17.1663 4.43484 17.1563 4.42187C17.1234 4.38933 17.085 4.36289 17.043 4.34375C16.9957 4.32019 16.9435 4.30816 16.8906 4.30859H5.46875C5.36516 4.30859 5.26578 4.34976 5.19254 4.423C5.1193 4.49625 5.07812 4.59562 5.07812 4.69921C5.07812 4.80281 5.1193 4.90218 5.19254 4.97543C5.26578 5.04867 5.36516 5.08984 5.46875 5.08984H8.16797V7.5039C8.16797 8.2291 8.45606 8.92461 8.96887 9.43738C9.48164 9.95019 10.1771 10.2383 10.9023 10.2383H14.0625L14.5234 18.7109L11.2695 19.9453C11.1855 19.9785 11.1155 20.0398 11.0716 20.1187C11.0277 20.1977 11.0125 20.2894 11.0286 20.3784C11.0447 20.4673 11.0912 20.5478 11.16 20.6063C11.2289 20.6648 11.3159 20.6977 11.4063 20.6992H19.5313C19.6219 20.6986 19.7095 20.6663 19.7791 20.6081C19.8486 20.5499 19.8957 20.4694 19.9123 20.3802C19.9289 20.2911 19.9141 20.1989 19.8702 20.1196C19.8263 20.0402 19.7562 19.9786 19.6719 19.9453V19.9375ZM16.4141 5.09375L15.6758 18.5937H15.3164L14.5781 5.09375H16.4141ZM14.0352 9.46484H10.9102C10.3921 9.46484 9.89535 9.25906 9.5291 8.89277C9.16281 8.52648 8.95703 8.02972 8.95703 7.51171V5.07812H13.8008L13.8281 5.52734L14.0352 9.46484ZM13.5586 19.9258L15.0156 19.375H15.9766L17.418 19.9258H13.5586Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
    </svg>
  );
};
