import request, { APICall } from '../request';
import {
  EvaluationPayload,
  ProjectInfoPayloadType,
  EnvironmentSavingsType,
  ProjectCategoriesType,
} from './types';

export const sendProjectInfoData = (
  payload: ProjectInfoPayloadType
): APICall<ProjectInfoPayloadType> =>
  request({
    data: payload,
    url: `/project/submit`,
    method: 'POST',
  });

export const fetchProjectTypes = (): APICall<ProjectCategoriesType> =>
  request({
    url: `/project/types`,
    method: 'GET',
  });

export const sendAppFeedback = (payload: EvaluationPayload): APICall<string[]> =>
  request({
    data: payload,
    url: `/project/feedback`,
    method: 'POST',
  });

export const getProjectEnvironmentSavings = (
  payload: ProjectInfoPayloadType
): APICall<EnvironmentSavingsType> =>
  request({
    data: payload,
    url: `project/environmentSavings`,
    method: 'POST',
  });

export const getProjectInfoData = (payload: string | null): APICall<ProjectInfoPayloadType> =>
  request({
    url: `/project/${payload}`,
    method: 'GET',
  });

export const updateProjectInfoData = (
  id: string,
  payload: ProjectInfoPayloadType
): APICall<ProjectInfoPayloadType> =>
  request({
    data: payload,
    url: `/project/${id}`,
    method: 'PUT',
  });
