import { useContext } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

// COMPONENTS:
import { Tabs, Stepper, StepperNavigation, FormValidation } from '../../components/molecules';
import {
  ProjectStep,
  AddressStep,
  AreaStep,
  HeatingStep,
  OverviewStep,
  DateStep,
} from '../../components/organisms';

// STORE
import { ProjectInfoContext, useConfigStore } from '../../store';

// TYPES
import {
  ConfigActionType,
  FormSteps,
  DataCarryingFormSteps,
  AdditionalFormSteps,
} from '../../store/config';
import { ProjectInfoActionType } from '../../store/project-info';

// ROUTES
import { RootRoutes } from '../../router';

//HOOKS
import { useProjectStep, useDateStep, useAddressStep, useAreaStep, useHeatingStep } from './hooks';

// TRANSLATIONS
import { FormattedMessage } from 'react-intl';

export const Guide = () => {
  const { activeFormStep, updateConfig } = useConfigStore();
  const history = useHistory();
  const { updateProjectInfo, projectInfo } = useContext(ProjectInfoContext);

  const {
    formik: projectFormik,
    goToNextStep: projectGoToNextStep,
    backToPreviousStep: projectBackToPreviousStep,
  } = useProjectStep();
  const {
    formik: dateFormik,
    goToNextStep: dateGoToNextStep,
    backToPreviousStep: dateBackToPreviousStep,
  } = useDateStep();
  const {
    formik: addressFormik,
    goToNextStep: addressGoToNextStep,
    backToPreviousStep: addressBackToPreviousStep,
  } = useAddressStep();
  const {
    formik: areaFormik,
    goToNextStep: areaGoToNextStep,
    backToPreviousStep: areaBackToPreviousStep,
  } = useAreaStep();
  const {
    formik: heatingFormik,
    goToNextStep: heatingGoToNextStep,
    backToPreviousStep: heatingBackToPreviousStep,
  } = useHeatingStep();

  const onCancelButtonClick = () => {
    updateConfig({
      type: ConfigActionType.UPDATE_FORM_STEP,
      payload: DataCarryingFormSteps.PROJECT,
    });
    updateProjectInfo({ type: ProjectInfoActionType.ResetProjectInfo });
    history.push(RootRoutes.HOME);
  };

  const overviewBackToPreviousStep = () => {
    updateConfig({
      type: ConfigActionType.UPDATE_FORM_STEP,
      payload: DataCarryingFormSteps.HEATING,
    });
  };

  const goToNextStep = () => {
    switch (activeFormStep) {
      case DataCarryingFormSteps.PROJECT:
        return projectGoToNextStep;
      case DataCarryingFormSteps.DATE:
        return dateGoToNextStep;
      case DataCarryingFormSteps.ADDRESS:
        return addressGoToNextStep;
      case DataCarryingFormSteps.AREA:
        return areaGoToNextStep;
      case DataCarryingFormSteps.HEATING:
        return heatingGoToNextStep;
      default:
        return () => null;
    }
  };

  const backToPreviousStep = () => {
    switch (activeFormStep) {
      case DataCarryingFormSteps.PROJECT:
        return projectBackToPreviousStep;
      case DataCarryingFormSteps.DATE:
        return dateBackToPreviousStep;
      case DataCarryingFormSteps.ADDRESS:
        return addressBackToPreviousStep;
      case DataCarryingFormSteps.AREA:
        return areaBackToPreviousStep;
      case DataCarryingFormSteps.HEATING:
        return heatingBackToPreviousStep;
      case AdditionalFormSteps.OVERVIEW:
        return overviewBackToPreviousStep;
    }
  };

  const formValidations: FormValidation = {
    [DataCarryingFormSteps.PROJECT]: projectFormik.isValid,
    [DataCarryingFormSteps.DATE]: dateFormik.isValid,
    [DataCarryingFormSteps.ADDRESS]: addressFormik.isValid,
    [DataCarryingFormSteps.AREA]: areaFormik.isValid,
    [DataCarryingFormSteps.HEATING]: heatingFormik.isValid,
    [AdditionalFormSteps.OVERVIEW]: [
      projectFormik.isValid,
      dateFormik.isValid,
      addressFormik.isValid,
      areaFormik.isValid,
      heatingFormik.isValid,
    ].every((validation) => validation),
  };

  const formCompleted: FormValidation = {
    [DataCarryingFormSteps.PROJECT]: projectInfo[DataCarryingFormSteps.PROJECT].completed,
    [DataCarryingFormSteps.DATE]: projectInfo[DataCarryingFormSteps.DATE].completed,
    [DataCarryingFormSteps.ADDRESS]: projectInfo[DataCarryingFormSteps.ADDRESS].completed,
    [DataCarryingFormSteps.AREA]: projectInfo[DataCarryingFormSteps.AREA].completed,
    [DataCarryingFormSteps.HEATING]: projectInfo[DataCarryingFormSteps.HEATING].completed,
    [AdditionalFormSteps.OVERVIEW]: [
      projectInfo[DataCarryingFormSteps.PROJECT].completed,
      projectInfo[DataCarryingFormSteps.DATE].completed,
      projectInfo[DataCarryingFormSteps.ADDRESS].completed,
      projectInfo[DataCarryingFormSteps.AREA].completed,
      projectInfo[DataCarryingFormSteps.HEATING].completed,
    ].every((validation) => validation),
  };

  const tabsContent = [
    { label: <FormattedMessage id='TAB.GUIDE' />, number: '01', isActive: true },
    {
      label: <FormattedMessage id='TAB.EQUIPMENT' />,
      number: '02',
      isActive: false,
      disabled: !formValidations[AdditionalFormSteps.OVERVIEW],
      pathTo: formValidations[AdditionalFormSteps.OVERVIEW] ? RootRoutes.EQUIPMENT : undefined,
    },
  ];

  const onChangeStepClick = (goToStep: FormSteps) => {
    if (activeFormStep === AdditionalFormSteps.OVERVIEW) {
      updateConfig({ type: ConfigActionType.UPDATE_FORM_STEP, payload: goToStep });
    } else {
      if (formValidations[activeFormStep]) {
        updateConfig({ type: ConfigActionType.UPDATE_FORM_STEP, payload: goToStep });
      } else {
        switch (activeFormStep) {
          case DataCarryingFormSteps.PROJECT:
            projectFormik.submitForm();
            break;
          case DataCarryingFormSteps.DATE:
            dateFormik.submitForm();
            break;
          case DataCarryingFormSteps.ADDRESS:
            addressFormik.submitForm();
            break;
          case DataCarryingFormSteps.AREA:
            areaFormik.submitForm();
            break;
          case DataCarryingFormSteps.HEATING:
            heatingFormik.submitForm();
            break;
        }
      }
    }
  };

  return (
    <>
      <div className='col-flex flex-col flex-grow bg-primary-lightBackground overflow-y-auto'>
        <Tabs
          className={clsx(
            'pt-8 px-4 bg-primary-lightBackground',
            'xs:px-6',
            'sm:px-8',
            '2sm:px-12',
            'md:px-16',
            'lg:flex'
          )}
          content={tabsContent}
        />

        <div
          className={clsx(
            'pb-20 pt-6 px-6 bg-primary-lightBackground',
            'xs:px-10',
            'sm:px-12',
            '2sm:px-24',
            'md:px-30',
            'lg:pt-12'
          )}
        >
          <Stepper
            currentStep={activeFormStep}
            formValidations={formValidations}
            formCompleted={formCompleted}
            onChangeStepClick={onChangeStepClick}
          />

          <div className={clsx('pt-8', 'lg:pt-16', '2xl:px-56')}>
            {activeFormStep === DataCarryingFormSteps.PROJECT && (
              <ProjectStep formik={projectFormik} />
            )}
            {activeFormStep === DataCarryingFormSteps.DATE && <DateStep formik={dateFormik} />}
            {activeFormStep === DataCarryingFormSteps.ADDRESS && (
              <AddressStep formik={addressFormik} />
            )}
            {activeFormStep === DataCarryingFormSteps.AREA && <AreaStep formik={areaFormik} />}
            {activeFormStep === DataCarryingFormSteps.HEATING && (
              <HeatingStep formik={heatingFormik} />
            )}
            {activeFormStep === AdditionalFormSteps.OVERVIEW && (
              <OverviewStep onChangeStepClick={onChangeStepClick} />
            )}
          </div>
        </div>
      </div>
      <StepperNavigation
        currentStep={activeFormStep}
        onBackButtonClick={backToPreviousStep()}
        onNextButtonClick={goToNextStep()}
        onCancelButtonClick={onCancelButtonClick}
      />
    </>
  );
};
