import { FC } from 'react';

import clsx from 'clsx';

type DividerProps = {
  className?: string;
};

export const Divider: FC<DividerProps> = ({ className }) => {
  return <div className={clsx('border border-black', className)} />;
};
