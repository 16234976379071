import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';

// STYLES:
import 'react-toastify/dist/ReactToastify.css';
import './notifications.css';

// CONSTANTS:
const DEFAULT_DISMISS_TIME = 4000; //ms

export const Notification = () => (
  <ToastContainer
    position='top-right'
    autoClose={DEFAULT_DISMISS_TIME}
    hideProgressBar
    closeOnClick
    draggable={false}
    transition={Slide}
  />
);
