import { useState } from 'react';
import { useFormikContext } from 'formik';
import { useIntl, FormattedMessage } from 'react-intl';
import { useJsApiLoader } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import { UseLoadScriptOptions } from '@react-google-maps/api/dist/useLoadScript';
import { getMapPinPosition } from '../../../helpers/functions';

// COMPONENTS:
import { GoogleMap, InputWithLabel } from '../../molecules';

// TYPES:
import { AddressStepInterface, PositionType } from '../../../store/project-info';

// ICONS:
import { InputArrowIcon } from '../../../assets/images';
import { googleMapsApiKey } from '../../../utils/env';

const GOOGLE_MAPS_API_KEY = googleMapsApiKey ?? '';

const libraries: UseLoadScriptOptions['libraries'] = ['places'];
const jsApiLoaderOptions: UseLoadScriptOptions = {
  id: 'google-map-script',
  googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  libraries,
};

Geocode.setApiKey(GOOGLE_MAPS_API_KEY);

const AddressFields = () => {
  const [visibleMarker, setVisibleMarker] = useState(true);
  const [searchBox, setSearchBox] = useState<google.maps.places.Autocomplete | null>(null);
  const { isLoaded } = useJsApiLoader(jsApiLoaderOptions);
  const { setFieldValue, values } = useFormikContext<AddressStepInterface>();
  const { formatMessage } = useIntl();

  const onLoad = (autocomplete: google.maps.places.Autocomplete) => {
    setSearchBox(autocomplete);
  };

  const onPlaceChanged = () => {
    if (searchBox) {
      setVisibleMarker(true);
      let newPosition: PositionType;
      if (!searchBox.getPlace().geometry) {
        newPosition = {
          lat: null,
          lng: null,
        };
        setVisibleMarker(false);
      } else {
        newPosition = {
          lat: searchBox.getPlace().geometry?.location?.lat() ?? null,
          lng: searchBox.getPlace().geometry?.location?.lng() ?? null,
        };
        setFieldValue('locationAddress.address', searchBox.getPlace().formatted_address);
      }
      setFieldValue('locationAddress.position', newPosition);
    }
  };

  const onMarkerChanged = (e: google.maps.MapMouseEvent) => {
    setVisibleMarker(true);
    const newPosition = {
      lat: e.latLng?.lat(),
      lng: e.latLng?.lng(),
    };
    setFieldValue('locationAddress.position', newPosition);
    Geocode.fromLatLng(String(e.latLng?.lat()), String(e.latLng?.lng())).then(
      (response) => {
        const address = response.results[0].formatted_address;
        setFieldValue('locationAddress.address', address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  return (
    <GoogleMap
      input={
        <InputWithLabel
          data-testid='address-input'
          label={<FormattedMessage id='GUIDE.ADDRESS.INPUT.LABEL' />}
          name='locationAddress.address'
          placeholder={formatMessage({ id: 'GUIDE.ADDRESS.MAP.PLACEHOLDER' })}
          icon={<InputArrowIcon />}
        />
      }
      onLoad={onLoad}
      onPlaceChanged={onPlaceChanged}
      onMarkerChanged={onMarkerChanged}
      visibleMarker={visibleMarker}
      position={getMapPinPosition(values.locationAddress.position)}
      className='mt-8 w-full h-96'
      isLoaded={isLoaded}
    />
  );
};

export default AddressFields;
