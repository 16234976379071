import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

// COMPONENTS:
import Button from '../../atoms/primitive/Button';

// ICONS:
import { CloseIcon, LightningIcon } from '../../../assets/images';
import FirstWorker from '../../../assets/images/worker1.png';
import SecondWorker from '../../../assets/images/worker2.png';

// STYLES:
import tailwindConfig from '../../../../tailwind.config';
import './feedback-modal.css';

// CONSTANTS:
import { MENU_URLS } from '../../../constants';
import { RootRoutes } from '../../../router';

export const FeedbackModal = () => {
  const history = useHistory();

  return (
    <div className='relative flex flex-col items-center m-auto pt-10 px-24 py-16 max-w-2xl text-other-white bg-primary-darkBackground rounded-2xl'>
      <button
        onClick={() => history.push(RootRoutes.HOME)}
        aria-label='Go to the homepage'
        className={clsx('absolute right-8 top-8 p-4 transition duration-300', 'hover:opacity-50')}
      >
        <CloseIcon className='w-5 h-5' color={tailwindConfig.theme.extend.colors.other.white} />
      </button>
      <div className='relative'>
        <h1 className='pt-24 text-center text-h1'>
          <FormattedMessage id='SUCCESS.FORM.SUBMIT.MODAL.TITLE' />
        </h1>
        <LightningIcon className='absolute left-24 top-16' />
        <LightningIcon
          className={clsx('second-lightning-icon', 'absolute left-16 top-20 transform')}
        />
        <LightningIcon
          className={clsx('third-lightning-icon', 'absolute bottom-2 right-20 transform')}
        />
      </div>
      <div className='flex flex-col items-center pt-12 text-center text-other-white font-secondaryRegular text-body leading-6'>
        <p className='pb-8 px-14 max-w-md'>
          <FormattedMessage id='SUCCESS.FORM.SUBMIT.MODAL.FIRST.PARAGRAPH' />
        </p>
        <p className='pb-12 max-w-xs'>
          <FormattedMessage id='SUCCESS.FORM.SUBMIT.MODAL.SECOND.PARAGRAPH' />
        </p>
      </div>
      <Link to={{ pathname: MENU_URLS.CONTACT }} target='_blank'>
        <Button variant='tertiary' className='focus:ring-offset-primary-darkBackground'>
          <FormattedMessage id='SUCCESS.FORM.SUBMIT.MODAL.CONTACT.BUTTON' />
        </Button>
      </Link>
      <img src={FirstWorker} alt='worker1' className='absolute -bottom-2 -left-16' />
      <img src={SecondWorker} alt='worker2' className='absolute -bottom-6 -right-20' />
    </div>
  );
};
