import { useQuery } from '@tanstack/react-query';
import { getMaxPotentialEffect } from '../../api/chart';
import { ProjectInfoPayloadType } from '../../api/project-info/types';
import { formatMaxPotentialEffectWeeks } from '../functions/charts';
import { QueryKeysNames } from '../../api/types';
import { getGreatestValueFromArray } from '../functions';

export const useTheMaxEffectPeak = (projectInfo: ProjectInfoPayloadType) => {
  return useQuery(
    [QueryKeysNames.MAX_POTENTIAL_EFFECT_PEAK],
    async () => await getMaxPotentialEffect(projectInfo),
    {
      select: (res) => {
        const weekValuesArray = formatMaxPotentialEffectWeeks(res.data.phase[0].weeks);
        return getGreatestValueFromArray(weekValuesArray);
      },
    }
  );
};
