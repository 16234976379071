import { useFormikContext } from 'formik';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';

// COMPONENTS:
import { Label } from '../../atoms';
import { PopOver } from '../../molecules';
import RadioButton from '../../atoms/primitive/RadioButton';

// TYPES:
import { AddressStepInterface, LocationType } from '../../../store/project-info';

// ICONS:
import { BulbIcon, QuestionIcon } from '../../../assets/images';

const ConstructionTypeField = () => {
  const { setErrors, setTouched } = useFormikContext<AddressStepInterface>();

  return (
    <>
      <div className='flex' id='location-type-group'>
        <Label
          className={clsx(
            'flex items-center justify-center mb-8 text-primary-evinyGreen4 text-h3 ',
            'lg:justify-start'
          )}
          htmlFor='locationType'
        >
          <FormattedMessage id='GUIDE.ADDRESS.MAP.TITLE' />
        </Label>
        <PopOver
          icon={<BulbIcon />}
          description={
            <>
              <FormattedMessage id='GUIDE.ADDRESS.POPOVER.FIRST.PART' />
              <br />
              <br />
              <FormattedMessage id='GUIDE.ADDRESS.POPOVER.SECOND.PART' />
            </>
          }
          className='relative top-1.5 ml-3'
        >
          <QuestionIcon />
        </PopOver>
      </div>

      <div role='group' aria-labelledby='location-type-group' className={clsx('mb-4', 'lg:flex')}>
        <RadioButton
          label={<FormattedMessage id='GUIDE.ADDRESS.FIRST.RADIO' />}
          className={clsx('mb-4', 'lg:mb-0 lg:mr-16')}
          name='locationType'
          value={LocationType.Address}
          onClick={() => {
            setErrors({});
            setTouched({});
          }}
        />
        <RadioButton
          label={<FormattedMessage id='GUIDE.ADDRESS.SECOND.RADIO' />}
          name='locationType'
          value={LocationType.Cadastre}
          onClick={() => {
            setErrors({});
            setTouched({});
          }}
        />
      </div>
    </>
  );
};

export default ConstructionTypeField;
