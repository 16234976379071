import { FC, ReactNode } from 'react';

// UTILS:
import clsx from 'clsx';

type TitleWithTextProps = {
  title: ReactNode | string;
  body: ReactNode | string;
  className?: string;
};

export const TitleWithText: FC<TitleWithTextProps> = ({ className, title, body }) => {
  return (
    <div className={clsx('lg:flex', className)}>
      <div>
        <h2 className={'block text-primary-evinyGreen4 text-h2'}>{title}</h2>
        <p className={'block pt-1 text-primary-evinyGreen4 text-body font-secondaryRegular '}>
          {body}
        </p>
      </div>
    </div>
  );
};
