import React, { useReducer } from 'react';

import {
  ConfigActionType,
  ConfigAction,
  ConfigContext,
  ConfigProviderProps,
  ConfigState,
  DataCarryingFormSteps,
} from './types';

const ConfigStoreContext = React.createContext<ConfigContext | undefined>(undefined);

const configReducer = (state: ConfigState, action: ConfigAction): ConfigState => {
  switch (action.type) {
    case ConfigActionType.UPDATE_FORM_STEP: {
      return { ...state, activeFormStep: action.payload };
    }
    default: {
      throw { ...state };
    }
  }
};

const initialStore = { activeFormStep: DataCarryingFormSteps.PROJECT };

const ConfigProvider = ({ children }: ConfigProviderProps) => {
  const [configState, configDispatch] = useReducer(configReducer, initialStore);

  const value = { activeFormStep: configState.activeFormStep, updateConfig: configDispatch };
  return <ConfigStoreContext.Provider value={value}>{children}</ConfigStoreContext.Provider>;
};

const useConfigStore = () => {
  const context = React.useContext(ConfigStoreContext);
  if (typeof context === 'undefined') {
    throw new Error('useConfigStore must be used within a ConfigProvider');
  }
  return context;
};

export { ConfigProvider, useConfigStore };
export * from './types';
