import { FC, TextareaHTMLAttributes, ReactNode } from 'react';
import { Field } from 'formik';

type TextareProps = {
  label?: string | ReactNode;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

export const Textarea: FC<TextareProps> = ({ label, name, className, ...props }) => {
  return (
    <>
      {label && (
        <label className='mb-2 mt-4 text-primary-evinyGreen3 font-secondaryMedium text-inputLabel'>
          {label}
        </label>
      )}
      <Field component='textarea' name={name} {...props} className={className} />
    </>
  );
};
