import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

type PhoneIconPropsType = IconPropsType & { strokeColor?: string };

export const PhoneIcon: FC<PhoneIconPropsType> = ({
  className,
  strokeColor = tailwindConfig.theme.extend.colors.primary.lightBackground,
}) => {
  return (
    <svg
      className={className}
      width='40'
      height='40'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect
        x='0.5'
        y='0.5'
        width='39'
        height='39'
        rx='19.5'
        fill={tailwindConfig.theme.extend.colors.primary.lightBackground}
        stroke={strokeColor}
      />
      <path
        d='M20.9767 22.7608L19.7902 24.2454C17.9519 23.1644 16.4198 21.6323 15.3389 19.794L16.8234 18.6075C16.9973 18.4683 17.1202 18.2754 17.1729 18.0589C17.2256 17.8424 17.2051 17.6146 17.1147 17.4109L15.7624 14.3657C15.6654 14.1472 15.494 13.9703 15.2788 13.8665C15.0635 13.7627 14.8184 13.7387 14.5871 13.7988L12.0101 14.471C11.7692 14.5326 11.5595 14.6811 11.4213 14.8879C11.2832 15.0947 11.2263 15.3452 11.2617 15.5914C11.7143 18.8083 13.2019 21.7904 15.4996 24.0869C17.7973 26.3834 20.7802 27.8694 23.9973 28.3203C24.2435 28.3556 24.494 28.2988 24.7008 28.1607C24.9077 28.0225 25.0561 27.8128 25.1177 27.5718L25.7899 24.9949C25.8498 24.7638 25.8256 24.5189 25.7218 24.3039C25.618 24.0889 25.4412 23.9177 25.223 23.8207L22.1733 22.4707C21.9698 22.3801 21.742 22.3594 21.5256 22.4119C21.3091 22.4644 21.1161 22.5871 20.9767 22.7608V22.7608Z'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen3}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.2167 15.446C21.2568 15.446 22.2542 15.8592 22.9896 16.5946C23.725 17.33 24.1381 18.3274 24.1381 19.3674'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen3}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.2167 12.085C21.1731 12.085 22.1201 12.2733 23.0037 12.6393C23.8872 13.0053 24.6901 13.5417 25.3663 14.218C26.0426 14.8942 26.579 15.6971 26.945 16.5806C27.311 17.4642 27.4993 18.4112 27.4993 19.3676'
        stroke={tailwindConfig.theme.extend.colors.primary.evinyGreen3}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
