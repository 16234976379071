import { FC } from 'react';
import { IconPropsType } from '../../../types';
import tailwindConfig from '../../../../tailwind.config';

export const KompressorEquipmentIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='25'
      height='25'
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.5625 12.5C1.5625 11.8528 2.08717 11.3281 2.73438 11.3281H21.875C22.5222 11.3281 23.0469 11.8528 23.0469 12.5V19.5312C23.0469 20.1784 22.5222 20.7031 21.875 20.7031H19.0332V19.9219H21.875C22.0907 19.9219 22.2656 19.747 22.2656 19.5312V12.5C22.2656 12.2843 22.0907 12.1094 21.875 12.1094H2.73438C2.51864 12.1094 2.34375 12.2843 2.34375 12.5V19.5312C2.34375 19.747 2.51864 19.9219 2.73438 19.9219H5.57617V20.7031H2.73438C2.08717 20.7031 1.5625 20.1784 1.5625 19.5312V12.5ZM8.68164 19.9219H15.9277V20.7031H8.68164V19.9219Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.51562 20.3125V11.7188H4.29688V20.3125H3.51562ZM20.3125 20.3125V11.7188H21.0938V20.3125H20.3125Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.6875 8.59375C4.6875 8.16227 5.03727 7.8125 5.46875 7.8125H7.8125C8.24398 7.8125 8.59375 8.16227 8.59375 8.59375V12.1094H4.6875V8.59375ZM7.8125 8.59375H5.46875V11.3281H7.8125V8.59375Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.98438 9.76562C8.98438 9.33414 9.33414 8.98438 9.76562 8.98438H12.5C12.9315 8.98438 13.2812 9.33414 13.2812 9.76562V12.1094H8.98438V9.76562ZM12.5 9.76562H9.76562V11.3281H12.5V9.76562Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M5.07812 19.5312C5.07812 19.0998 5.42789 18.75 5.85938 18.75H8.20312C8.63461 18.75 8.98438 19.0998 8.98438 19.5312V21.4844H5.07812V19.5312ZM8.20312 19.5312H5.85938V20.7031H8.20312V19.5312Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.625 19.5312C15.625 19.0998 15.9748 18.75 16.4062 18.75H18.75C19.1815 18.75 19.5312 19.0998 19.5312 19.5312V21.4844H15.625V19.5312ZM18.75 19.5312H16.4062V20.7031H18.75V19.5312Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.0156 9.76562C16.0156 9.33414 16.3654 8.98438 16.7969 8.98438H19.1406C19.5721 8.98438 19.9219 9.33414 19.9219 9.76562V12.1094H16.0156V9.76562ZM19.1406 9.76562H16.7969V11.3281H19.1406V9.76562Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.42188 6.25C7.20613 6.25 7.03125 6.42488 7.03125 6.64062V7.86828H6.25V6.64062C6.25 5.9934 6.77465 5.46875 7.42188 5.46875H10.4936C11.1408 5.46875 11.6655 5.9934 11.6655 6.64062V9.375H10.8842V6.64062C10.8842 6.42488 10.7093 6.25 10.4936 6.25H7.42188ZM18.3594 7.36605V9.375H17.5781V7.36605H18.3594Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.9687 3.89931C16.962 3.89931 16.2109 4.63308 16.2109 5.46179C16.2109 6.29054 16.962 7.02429 17.9687 7.02429C18.9754 7.02429 19.7265 6.29054 19.7265 5.46179C19.7265 4.63308 18.9754 3.89931 17.9687 3.89931ZM15.4297 5.46179C15.4297 4.1332 16.6023 3.11806 17.9687 3.11806C19.3351 3.11806 20.5078 4.1332 20.5078 5.46179C20.5078 6.79042 19.3351 7.80554 17.9687 7.80554C16.6023 7.80554 15.4297 6.79042 15.4297 5.46179Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.9688 4.29688C18.1845 4.29688 18.3594 4.47176 18.3594 4.6875V6.25C18.3594 6.46574 18.1845 6.64062 17.9688 6.64062C17.753 6.64062 17.5782 6.46574 17.5782 6.25V4.6875C17.5782 4.47176 17.753 4.29688 17.9688 4.29688Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
    </svg>
  );
};
