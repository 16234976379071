import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const DatePickerIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='16'
      height='19'
      viewBox='0 0 16 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.0625 2.71979H12.375V0.891663C12.375 0.680725 12.1641 0.469788 11.9531 0.469788H10.5469C10.3008 0.469788 10.125 0.680725 10.125 0.891663V2.71979H5.625V0.891663C5.625 0.680725 5.41406 0.469788 5.20312 0.469788H3.79688C3.55078 0.469788 3.375 0.680725 3.375 0.891663V2.71979H1.6875C0.738281 2.71979 0 3.49323 0 4.40729V16.7823C0 17.7315 0.738281 18.4698 1.6875 18.4698H14.0625C14.9766 18.4698 15.75 17.7315 15.75 16.7823V4.40729C15.75 3.49323 14.9766 2.71979 14.0625 2.71979ZM13.8516 16.7823H1.89844C1.75781 16.7823 1.6875 16.712 1.6875 16.5714V6.09479H14.0625V16.5714C14.0625 16.712 13.957 16.7823 13.8516 16.7823Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
    </svg>
  );
};
