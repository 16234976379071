import { useQuery } from '@tanstack/react-query';

// API
import { getEquipmentModelsList } from '../../api/equipment';
import { QueryKeysNames } from '../../api/types';

export const useEquipmentModels = () => {
  return useQuery([QueryKeysNames.EQUIPMENT_MODELS_LIST], async () => {
    const res = await getEquipmentModelsList();
    return res.data;
  });
};
