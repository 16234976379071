import clsx from 'clsx';
import { InputHTMLAttributes, ReactNode, FC } from 'react';

import { Input, Label } from '../../atoms';

type InputWithLabelProps = {
  name: string;
  label: string | ReactNode;
  icon?: ReactNode;
  containerClassName?: string;
  labelClassName?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const InputWithLabel: FC<InputWithLabelProps> = ({
  containerClassName,
  label,
  labelClassName,
  required,
  name,
  icon,
  ...props
}) => {
  return (
    <div className={containerClassName}>
      <Label htmlFor={name}>
        <span
          className={clsx(
            'block float-left mb-2 mt-4 w-full text-left text-primary-evinyGreen3 font-secondaryMedium text-inputLabel',
            labelClassName
          )}
        >
          {label}
          {required && <span className='pl-1 text-primary-evinyGreen3'>*</span>}
        </span>
        <Input required={required} name={name} icon={icon} {...props} />
      </Label>
    </div>
  );
};
