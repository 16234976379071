import { FC, RefObject, useState } from 'react';
import ReactTimeline, {
  ReactCalendarItemRendererProps,
  TimelineHeaders,
  TimelineMarkers,
} from 'react-calendar-timeline';
import {
  handleTimeChange,
  highlightActiveTimelineArea,
  ITEM_WIDTH_BREAKPOINTS,
} from '../../components/organisms/Timeline/timeline-item-utils';
import {
  TimelineItem,
  TimelineItemWithHiddenName,
  TimelineItemWithRightLabel,
  TimelineItemWithShortenName,
  TimelineItemWithTooltip,
  TimelineMonthHeader,
  TimelineYearHeader,
} from '../../components/molecules';
import moment from 'moment';
import {
  TimelineItem as TimelineItemType,
  useTimelineGroupsForTheReport,
  useTimelineItemsForTheReport,
} from '../../helpers/hooks';
import { EQUIPMENT_TYPE } from '../../constants';
import {
  EquipmentScheduleItemType,
  PowerType,
  ProjectStepsInfoType,
} from '../../store/project-info';
import { useIntl } from 'react-intl';
import { SingleDropdownOptionType } from '../../types';
import { DataCarryingFormSteps } from '../../store/config';
import { getNumberOfMonthsGrayedOut } from '../../helpers/functions';

type TimelineForReportProps = {
  timelineRef: RefObject<HTMLDivElement>;
  projectInfo: ProjectStepsInfoType;
  equipmentSchedule: EquipmentScheduleItemType[];
  modelOptions: SingleDropdownOptionType[];
};

export const TimelineForReport: FC<TimelineForReportProps> = ({
  projectInfo,
  equipmentSchedule,
  modelOptions,
  timelineRef,
}) => {
  const { formatMessage } = useIntl();
  const [selected, setSelected] = useState([]);
  const groupHeight = 70;
  const headersHeight = 147;
  const timelineWrapperHeight = timelineRef.current?.clientHeight;
  const computedTimelineWrapperHeight =
    (timelineWrapperHeight && timelineWrapperHeight - headersHeight) || 0;
  const defaultAmountOfGroups = Math.floor(computedTimelineWrapperHeight / groupHeight);

  const numberOfWeeks = projectInfo[DataCarryingFormSteps.DATE].numberOfWeeks;
  const startDate = projectInfo[DataCarryingFormSteps.DATE].startDate;
  const endDate = moment(startDate).add(numberOfWeeks, 'weeks').endOf('day');

  const numberOfMonthsAfterProjectEndGrayedOut = getNumberOfMonthsGrayedOut(numberOfWeeks);

  const defaultTimeStart = moment(startDate).add(-1, 'month');
  const defaultTimeEnd = moment(startDate)
    .add(numberOfMonthsAfterProjectEndGrayedOut, 'month')
    .endOf('day');
  const defaultTimeRange = defaultTimeEnd.valueOf() - defaultTimeStart.valueOf();
  const numberOfProjectDays = endDate.diff(moment(startDate), 'days');

  const { timelineItems } = useTimelineItemsForTheReport(
    equipmentSchedule,
    startDate,
    numberOfWeeks
  );

  const { timelineGroups } = useTimelineGroupsForTheReport(
    equipmentSchedule,
    defaultAmountOfGroups
  );

  const itemRenderer = (itemProps: ReactCalendarItemRendererProps<TimelineItemType>) => {
    const item = equipmentSchedule[itemProps.item.id - 1];
    if (typeof item !== 'undefined') {
      const equipmentItemWidth = itemProps.itemContext.dimensions.width;
      const defaultTitle = formatMessage({ id: 'TIMELINE.DEFAULT.ITEM.TITLE' });
      const isItemOtherType =
        item.equipmentTypeId === EQUIPMENT_TYPE.OTHER || item.powerType === PowerType.Other;
      const itemRenderRange = equipmentItemWidth > ITEM_WIDTH_BREAKPOINTS.WIDE;
      const itemWithShortenNameRenderRange = isItemOtherType
        ? equipmentItemWidth > ITEM_WIDTH_BREAKPOINTS.NARROW
        : equipmentItemWidth <= ITEM_WIDTH_BREAKPOINTS.WIDE &&
          equipmentItemWidth > ITEM_WIDTH_BREAKPOINTS.MEDIUM;
      const itemWithHiddenNameRenderRange =
        equipmentItemWidth <= ITEM_WIDTH_BREAKPOINTS.MEDIUM &&
        equipmentItemWidth > ITEM_WIDTH_BREAKPOINTS.NARROW;
      const itemWithRightLabelRenderRange =
        equipmentItemWidth <= ITEM_WIDTH_BREAKPOINTS.NARROW &&
        equipmentItemWidth > ITEM_WIDTH_BREAKPOINTS.TINY;

      if (itemRenderRange) {
        return TimelineItem(itemProps, item, modelOptions, defaultTitle);
      }
      if (itemWithShortenNameRenderRange) {
        return TimelineItemWithShortenName(itemProps, item, modelOptions, defaultTitle);
      }
      if (!isItemOtherType && itemWithHiddenNameRenderRange) {
        return TimelineItemWithHiddenName(itemProps, item, modelOptions, defaultTitle);
      }
      if (itemWithRightLabelRenderRange) {
        return TimelineItemWithRightLabel(itemProps, item, modelOptions, defaultTitle);
      }
      return TimelineItemWithTooltip(itemProps, item, modelOptions, defaultTitle);
    }

    return null;
  };

  return (
    <ReactTimeline
      itemHeightRatio={0.7}
      lineHeight={groupHeight}
      sidebarWidth={0}
      canMove={false}
      canResize={false}
      canChangeGroup={false}
      groups={timelineGroups}
      items={timelineItems}
      minZoom={defaultTimeRange}
      maxZoom={defaultTimeRange}
      defaultTimeStart={defaultTimeStart}
      defaultTimeEnd={defaultTimeEnd}
      itemRenderer={itemRenderer}
      selected={selected}
      onItemSelect={() => setSelected([])}
      onTimeChange={(visibleTimeStart, visibleTimeEnd, updateScrollCanvas) =>
        handleTimeChange(
          visibleTimeStart,
          visibleTimeEnd,
          updateScrollCanvas,
          endDate,
          startDate,
          defaultTimeRange
        )
      }
    >
      <TimelineHeaders>
        <TimelineYearHeader />
        <TimelineMonthHeader />
      </TimelineHeaders>
      <TimelineMarkers>
        {highlightActiveTimelineArea(numberOfProjectDays, moment(startDate))}
      </TimelineMarkers>
    </ReactTimeline>
  );
};
