import { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';

// TYPES:
import { EquipmentScheduleItemType } from '../../store/project-info';

const parseContextToTimelineProps = (items: EquipmentScheduleItemType[]) => {
  return items.map((singleItem) => {
    return {
      id: singleItem.id,
      group: singleItem.id,
      start_time: moment(singleItem.startTime),
      end_time: moment(singleItem.endTime),
      isItemOutdated: singleItem.isItemOutdated,
    };
  });
};

export type TimelineItem = {
  id: number;
  group: number;
  start_time: Moment;
  end_time: Moment;
  isItemOutdated: boolean;
};

type UseTimelineItems = {
  timelineItems: TimelineItem[];
};

export const useTimelineItemsForTheReport = (
  equipmentSchedule: EquipmentScheduleItemType[],
  startDate: string | Date,
  numberOfWeeks: number
): UseTimelineItems => {
  const [timelineItems, setTimelineItems] = useState<TimelineItem[]>([]);

  useEffect(() => {
    if (!equipmentSchedule.length && startDate !== '') {
      setTimelineItems([
        {
          id: 1,
          group: 1,
          start_time: moment(startDate),
          end_time: moment(startDate)
            .add(numberOfWeeks < 12 ? numberOfWeeks : 12, 'weeks')
            .endOf('day'),
          isItemOutdated: false,
        },
      ]);
    } else setTimelineItems(parseContextToTimelineProps(equipmentSchedule));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { timelineItems };
};
