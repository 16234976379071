import clsx from 'clsx';

// ICONS:
import { ElectricEquipmentIcon } from '../../../assets/images';

// HELPERS:
import {
  defineTimelineItemStyle,
  getGreatestValueFromArray,
  getLabelFromId,
} from '../../../helpers/functions';

// TYPES:
import { PowerType } from '../../../store/project-info';
import { EQUIPMENT_TYPE } from '../../../constants';
import { TimelineItemComponent } from '../../../types';

// STYLES:
import tailwindConfig from '../../../../tailwind.config';
import { FormattedMessage } from 'react-intl';

export const TimelineItem: TimelineItemComponent = (
  itemRendererProps,
  singleTimelineItem,
  modelOptions,
  defaultTitle
) => {
  const { equipmentModel, isItemOutdated, powerType, equipmentTypeId } = singleTimelineItem;

  const { itemContext, getItemProps } = itemRendererProps;
  const isItemOtherType = equipmentTypeId === EQUIPMENT_TYPE.OTHER;
  const isItemElectricPowerType = powerType === PowerType.Electric;
  const isItemDefined = Boolean(equipmentTypeId);
  const { style, equipmentIcon, leftResizeProps, rightResizeProps } = defineTimelineItemStyle(
    singleTimelineItem,
    itemRendererProps
  );

  return (
    <div
      {...getItemProps({
        style,
      })}
      className={clsx(
        !itemContext.selected && 'hover:shadow-md',
        'flex transition-all duration-75 ease-linear'
      )}
    >
      <div {...leftResizeProps} />
      <div
        className='flex justify-between pl-1 w-full whitespace-nowrap overflow-hidden overflow-ellipsis'
        style={{
          height: itemContext.dimensions.height,
        }}
      >
        <div className='flex'>
          <div
            className={clsx(
              isItemOutdated
                ? 'bg-other-errorRed bg-opacity-40'
                : isItemDefined
                ? 'bg-other-white'
                : 'bg-other-gray bg-opacity-20',
              'relative mr-3 mt-1 w-10 h-10 rounded-full'
            )}
          >
            {isItemElectricPowerType && (
              <ElectricEquipmentIcon
                className='absolute z-30 right-0'
                color={
                  isItemOutdated
                    ? tailwindConfig.theme.extend.colors.other.white
                    : tailwindConfig.theme.extend.colors.primary.evinyGreen4
                }
              />
            )}
            {equipmentIcon}
          </div>
          <div>
            {powerType === PowerType.Other || isItemOtherType
              ? equipmentModel.id
              : getLabelFromId(modelOptions, equipmentModel.id, defaultTitle)}
            <span className='pl-2'>
              {isItemDefined &&
                isItemElectricPowerType &&
                !isItemOtherType &&
                equipmentModel.additionalInformation && (
                  <>
                    {equipmentModel.additionalInformation}{' '}
                    <FormattedMessage
                      id={`TIMELINE_EQUIPMENT_ADDITIONAL_INFO_UNIT_${equipmentModel.additionalInformationUnit?.toUpperCase()}`}
                      defaultMessage={equipmentModel.additionalInformationUnit}
                    />
                  </>
                )}
            </span>
          </div>
        </div>
        {isItemElectricPowerType && !isItemOtherType && (
          <div className={clsx('mr-4 pl-2 ', isItemDefined && 'underline')}>
            {getGreatestValueFromArray([
              equipmentModel.powerFastCharging ?? 0,
              equipmentModel.powerNormalCharging ?? 0,
              equipmentModel.powerUsageBattery ?? 0,
              equipmentModel.powerUsageGrid ?? 0,
            ])}
            <span className='font-secondaryRegular'> kW</span>
          </div>
        )}
      </div>
      <div {...rightResizeProps} />
    </div>
  );
};
