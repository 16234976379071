// STYLES
import './potential-effect-chart-tooltip.css';

export const PotentialEffectChartTooltip = (
  series: number[][],
  seriesIndex: number,
  dataPointIndex: number,
  date: string,
  maxEffectText: string,
  intakeEffectText: string,
  topLoadText: string,
  numberOfMachines: string
): string => {
  const lastMainValueIndex = series[0].length - 1;
  let showTopLoad = false;
  let showMainValues = true;

  let mainValueIndex = dataPointIndex;
  let topLoadIndex = dataPointIndex;

  // series - represents an array of all the data that we forwared to the chart
  // The series array is composed in the following order:
  // [ maxEffect[], intakeEffect[], topLoad[] ]
  // seriesIndex - points to a specific data array in the series the user is hovering over
  // dataPointIndex - points to the specific data in time in the selected data array

  // If the user is hovering over data in the topLoad array then we should only show the topLoad data,
  // unless the user is hovering over the last day of the project, when all 3 values are available.
  if (seriesIndex === 2) {
    showMainValues = false;
    showTopLoad = true;
    if (dataPointIndex === 0) {
      showMainValues = true;
      mainValueIndex = lastMainValueIndex;
    }
  }

  // The last element in the maxEffect and intakeEffect arrays corresponds by date to the first element in the topLoad array
  if (dataPointIndex === lastMainValueIndex) {
    showTopLoad = true;
    topLoadIndex = 0;
  }

  return `<div class='chart-tooltip-container'>
      <p class='tooltip-date'>
        ${date}
      </p>
      ${
        showMainValues
          ? `<p class='tooltip-max-effect-description'>
        ${maxEffectText}:
      </p>
      <div class='tooltip-main-data'>
        <p class='tooltip-max-effect text-secondary-evinyWarm4'>
          <strong>${series[0][mainValueIndex]?.toFixed(1)}</strong> kW
        </p>
        <p>
          ${numberOfMachines}
        </p>
      </div>`
          : ``
      }
  ${
    showMainValues && series[1].length
      ? `<p class='tooltip-max-effect-description'>
        ${intakeEffectText}:
      </p>
      <div class='tooltip-main-data'>
        <p class='tooltip-max-effect text-secondary-evinyCold3'>
          <strong>${series[1][mainValueIndex]?.toFixed(1)}</strong> kW
        </p>
      </div>`
      : ``
  }
       ${
         showTopLoad && series[2].length
           ? `<p class='tooltip-max-effect-description'>
        ${topLoadText}:
      </p>
      <div class='tooltip-main-data'>
        <p class='tooltip-max-effect text-secondary-evinyWarm3'>
          <strong>${series[2][topLoadIndex]?.toFixed(1)}</strong> kW
        </p>
      </div>`
           : ``
       }
      
    </div>`;
};
