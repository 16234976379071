import { InputHTMLAttributes, FC, ReactNode } from 'react';
import { ErrorMessage, Field } from 'formik';
import clsx from 'clsx';
import './form-checkbox.css';

type FormCheckboxType = {
  label: string | ReactNode;
  name: string;
  showError?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const FormCheckbox: FC<FormCheckboxType> = ({
  className,
  label,
  disabled,
  name,
  showError = true,
  ...props
}) => {
  return (
    <div className='relative'>
      <label className={clsx('flex place-items-center cursor-pointer', className)}>
        <Field
          type='checkbox'
          name={name}
          {...props}
          className={clsx(
            'text-primary-evinyGreen4',
            !disabled ? 'hover:opacity-50 transition' : 'opacity-30 cursor-default'
          )}
        />
        <span className='ml-2 text-primary-evinyGreen4'>{label}</span>
      </label>

      {showError && (
        <ErrorMessage
          component='div'
          className='absolute bottom-0 left-6 right-0 -mb-4 text-other-errorRed text-inputLabel'
          name={name}
        />
      )}
    </div>
  );
};
