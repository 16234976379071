import { FC } from 'react';
import clsx from 'clsx';
import { Header } from '../../organisms';

type MobileLayoutProps = {
  className?: string;
  children: React.ReactNode;
};

export const MobileLayout: FC<MobileLayoutProps> = ({ children, className }) => {
  return (
    <div className={clsx('flex flex-col h-screen', className)}>
      <Header />
      <main className='flex flex-col flex-grow overflow-y-hidden'>{children}</main>
    </div>
  );
};
