import { FC } from 'react';

// UTILS:
import clsx from 'clsx';

export type ExternalLinkProps = {
  href?: string;
  className?: string;
  children: React.ReactNode;
  target?: string;
};

const ExternalLink: FC<ExternalLinkProps> = ({ children, href, className, target }) => {
  return (
    <a
      href={href}
      target={target ?? '_blank'}
      className={clsx(
        href ? 'hover:opacity-50 transition' : 'opacity-30 cursor-default',
        className
      )}
    >
      {children}
    </a>
  );
};

export default ExternalLink;
