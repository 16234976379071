import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

// COMPONENTS:
import { InputWithLabel } from '../../molecules';

const CadastreInformationFields = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <InputWithLabel
        name='locationCadastre.municipality'
        placeholder={formatMessage({ id: 'GUIDE.ADDRESS.MUNICIPALITY.PLACEHOLDER' })}
        label={<FormattedMessage id='GUIDE.ADDRESS.MUNICIPALITY.LABEL' />}
        required
      />
      <InputWithLabel
        name='locationCadastre.cadastralUnitNumber'
        type='number'
        placeholder={formatMessage({
          id: 'GUIDE.ADDRESS.CADASTRAL.UNIT.NUMBER.PLACEHOLDER',
        })}
        label={<FormattedMessage id='GUIDE.ADDRESS.CADASTRAL.UNIT.NUMBER.LABEL' />}
        required
      />
      <InputWithLabel
        name='locationCadastre.propertyUnitNumber'
        type='number'
        placeholder={formatMessage({
          id: 'GUIDE.ADDRESS.PROPERTY.UNIT.NUMBER.PLACEHOLDER',
        })}
        label={<FormattedMessage id='GUIDE.ADDRESS.PROPERTY.UNIT.NUMBER.LABEL' />}
        required
      />
      <InputWithLabel
        name='locationCadastre.leaseNumber'
        type='number'
        placeholder={formatMessage({ id: 'GUIDE.ADDRESS.LEASE.NUMBER.PLACEHOLDER' })}
        label={<FormattedMessage id='GUIDE.ADDRESS.LEASE.NUMBER.LABEL' />}
      />
      <InputWithLabel
        name='locationCadastre.unitNumber'
        type='number'
        placeholder={formatMessage({ id: 'GUIDE.ADDRESS.UNIT.NUMBER.PLACEHOLDER' })}
        label={<FormattedMessage id='GUIDE.ADDRESS.UNIT.NUMBER.LABEL' />}
      />
    </>
  );
};

export default CadastreInformationFields;
