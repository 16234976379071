import { InputHTMLAttributes, FC, ReactNode } from 'react';
import { ErrorMessage, Field, useFormikContext, getIn } from 'formik';
import clsx from 'clsx';

// STYLES:
import './input.css';

type InputProps = {
  name: string;
  icon?: ReactNode;
  showError?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const Input: FC<InputProps> = ({
  className,
  showError = true,
  disabled,
  name,
  icon,
  ...props
}) => {
  const { values } = useFormikContext();
  const fieldValue = getIn(values, name);

  return (
    <div className='relative'>
      <Field
        className={clsx(
          disabled && 'opacity-30 cursor-default',
          ' pb-3.5 pl-4 pr-12 pt-4 w-full text-primary-evinyGreen4 font-secondaryRegular text-body bg-transparent border border-primary-evinyGreen4 rounded-lg overflow-ellipsis',
          className
        )}
        name={name}
        value={fieldValue !== null ? fieldValue : ''}
        {...props}
      />
      <span className='absolute bottom-0 right-0 mb-3.5 mr-4'>{icon}</span>
      <div className='relative'>
        {showError && (
          <ErrorMessage
            component='div'
            className='absolute bottom-0 -mb-4 text-other-errorRed text-inputLabel'
            name={name}
          />
        )}
      </div>
    </div>
  );
};
