import { FC } from 'react';
import { IconPropsType } from '../../types';
import tailwindConfig from '../../../tailwind.config';

export const InputArrowIcon: FC<IconPropsType> = ({ className }) => {
  return (
    <svg
      className={className}
      width='21'
      height='21'
      viewBox='0 0 21 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M20 16L20 16.65L20.65 16.65L20.65 16L20 16ZM0.540379 15.5404C0.286537 15.7942 0.286537 16.2058 0.540379 16.4596L4.67695 20.5962C4.93079 20.85 5.34235 20.85 5.59619 20.5962C5.85003 20.3424 5.85003 19.9308 5.59619 19.677L1.91924 16L5.59619 12.323C5.85003 12.0692 5.85003 11.6576 5.59619 11.4038C5.34235 11.15 4.93079 11.15 4.67695 11.4038L0.540379 15.5404ZM20 15.35L0.999998 15.35L0.999998 16.65L20 16.65L20 15.35ZM20.65 16L20.65 1.96417e-06L19.35 1.85052e-06L19.35 16L20.65 16Z'
        fill={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
      />
    </svg>
  );
};
