import { FC } from 'react';
import { Link } from 'react-router-dom';
import Button from '../Button';

// UTILS:
import clsx from 'clsx';

type InternalLinkProps = {
  href?: string;
  onClick?: () => void;
  className?: string;
  variant?: 'primary' | 'secondary' | 'tertiary' | 'cancelPrimary' | 'cancelSecondary' | 'default';
  children: React.ReactNode;
};

export const InternalLink: FC<InternalLinkProps> = ({
  children,
  onClick,
  variant = 'default',
  href,
  className,
}) => {
  const defaultStyle = 'text-button text-center hover:opacity-50 transition';

  return (
    <>
      {onClick ? (
        <Button variant={variant} onClick={onClick} className={clsx(defaultStyle, className)}>
          {children}
        </Button>
      ) : (
        href && (
          <Link
            to={href}
            className={
              variant === 'default'
                ? clsx(defaultStyle, className)
                : clsx(
                    'border-box flex items-center justify-center px-10 py-4 text-button border-2 rounded-full transition duration-300',
                    'focus:outline-none focus:ring-offset-2 focus:ring-2',
                    `${variant}-button`,
                    className
                  )
            }
          >
            {children}
          </Link>
        )
      )}
    </>
  );
};
