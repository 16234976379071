import { useQuery } from '@tanstack/react-query';
// API
import { fetchProjectTypes } from '../../api/project-info';
import { QueryKeysNames } from '../../api/types';

export const useProjectTypeCategories = () => {
  return useQuery([QueryKeysNames.PROJECT_TYPES], async () => {
    const res = await fetchProjectTypes();
    return res.data;
  });
};
