import { BrowserRouter as Router } from 'react-router-dom';
import clsx from 'clsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// LAYOUT
import { DefaultLayout, MobileLayout } from './components/layouts';

// COMPONENTS:
import { Notification } from './components/molecules';

// TRANSLATIONS
import { IntlProvider } from 'react-intl';
import noMessages from './translations/no.json';

// STORE:
import { ConfigProvider, ProjectInfoProvider } from './store';

// COMPONENTS
import { StartContentMobile } from './components/organisms';
import { Routes } from './Routes';

// TRANSLATIONS
import { locale, defaultLocale } from './translations';

import ReactGA from 'react-ga4';

function App() {
  import.meta.env.APP_GA_ID && ReactGA.initialize(import.meta.env.APP_GA_ID);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // Enable on specific queries if stale data in user sessions is a problem
      },
    },
  });

  return (
    <IntlProvider locale={locale} defaultLocale={defaultLocale} messages={noMessages}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider>
          <Notification />
          <Router>
            <DefaultLayout className={clsx('hidden', 'xl:flex')}>
              <ProjectInfoProvider>
                <Routes />
              </ProjectInfoProvider>
            </DefaultLayout>
          </Router>
        </ConfigProvider>
        <MobileLayout className='xl:hidden'>
          <StartContentMobile />
        </MobileLayout>
      </QueryClientProvider>
    </IntlProvider>
  );
}

export default App;
