import { useContext, useMemo } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

// ROUTER
import { RootRoutes } from '../../../router';

// STORE
import { useConfigStore } from '../../../store';

// TYPES
import { ProjectInfoContext, ProjectStepInterface } from '../../../store/project-info';
import { ConfigActionType, DataCarryingFormSteps } from '../../../store/config';

export interface UseProjectStep {
  formik: FormikProps<ProjectStepInterface>;
  goToNextStep: () => void;
  backToPreviousStep: () => void;
}

export const useProjectStep = (): UseProjectStep => {
  const { formatMessage } = useIntl();
  const { projectInfo } = useContext(ProjectInfoContext);
  const history = useHistory();
  const { updateConfig } = useConfigStore();

  const validationSchema = Yup.object().shape({
    projectType: Yup.string().required(formatMessage({ id: 'ERROR.OPTION.REQUIRED' })),
    projectName: Yup.string().required(formatMessage({ id: 'ERROR.FIELD.REQUIRED' })),
  });

  const initialValues = useMemo(
    () => ({
      projectName: projectInfo[DataCarryingFormSteps.PROJECT].projectName,
      projectType: projectInfo[DataCarryingFormSteps.PROJECT].projectType,
      completed: projectInfo[DataCarryingFormSteps.PROJECT].completed,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const formik = useFormik<ProjectStepInterface>({
    initialValues,
    validationSchema,
    onSubmit: () => {
      updateConfig({
        type: ConfigActionType.UPDATE_FORM_STEP,
        payload: DataCarryingFormSteps.DATE,
      });
    },
    validateOnMount: true,
  });

  const goToNextStep = () => {
    formik.submitForm();
  };

  const backToPreviousStep = () => {
    if (formik.isValid) {
      history.push(RootRoutes.HOME);
    } else {
      formik.submitForm();
    }
  };

  return { formik, goToNextStep, backToPreviousStep };
};
