import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import clsx from 'clsx';
import { useQuery } from '@tanstack/react-query';

// COMPONENTS:
import Button from '../../atoms/primitive/Button';
import { PotentialEffectChart } from '../PotentialEffectChart';
import { Loader } from '../../atoms';
import { PopOver, StepGuide, RequestQuoteModal } from '../../molecules';

// ICONS:
import {
  MachineIcon,
  SaveEnvironmentIcon,
  MobilEnergiLogo,
  BulbIcon,
  QuestionIcon,
} from '../../../assets/images';

// STORE
import { ProjectInfoContext } from '../../../store';

// STYLES:
import './timeline-project-details.css';

// API
import { getProjectEnvironmentSavings } from '../../../api/project-info';

// HELPERS:
import { calculateNumberOfMachines } from '../../../helpers/functions';
import { useMaxPotentialChartData, useTheMaxEffectPeak } from '../../../helpers/hooks';
import { parseProjectInfoData } from '../../../api/project-info/project-info-utils';

// TYPES:
import { QueryKeysNames } from '../../../api/types';
import tailwindConfig from '../../../../tailwind.config';
import { locale } from '../../../translations';

export const TimelineProjectDetails: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((prevState) => !prevState);
  const { projectInfo, equipmentModels, contactUserData } = useContext(ProjectInfoContext);

  const parsedProjectInfoData = useMemo(
    () => parseProjectInfoData(contactUserData, projectInfo, equipmentModels),
    [contactUserData, projectInfo, equipmentModels]
  );

  const { data: environmentSavings } = useQuery(
    [QueryKeysNames.ENVIRONMENT_SAVINGS],
    async () => await getProjectEnvironmentSavings(parsedProjectInfoData)
  );

  const { numberOfMachines, numberOfElectricMachines } = calculateNumberOfMachines(
    projectInfo.equipmentSchedule
  );

  const {
    data: chartSeriesData,
    isFetching: isMaxPotentialEffectFetching,
    refetch: maxPotentialRefetch,
  } = useMaxPotentialChartData(parsedProjectInfoData);

  const { data: maxPotentialEffect } = useTheMaxEffectPeak(parsedProjectInfoData);

  useEffect(() => {
    maxPotentialRefetch();
  }, [projectInfo, maxPotentialRefetch]);

  const literOfPetrolSaved = Math.round(
    environmentSavings?.data.fossilFuelSavings ?? 0
  ).toLocaleString(locale);

  const co2Saved = Number((environmentSavings?.data.co2Savings ?? 0) * 0.001)
    .toFixed(1)
    .replace('.', ',');

  return (
    <div className='relative flex flex-col h-full text-other-white bg-primary-evinyGreen4 overflow-y-auto'>
      <div className='px-28 py-20'>
        <div className='mx-auto max-w-1300px'>
          {/* Project details header */}
          <div className='flex flex-col text-other-white'>
            <div className='flex justify-between'>
              <MobilEnergiLogo />
              <div className='flex items-center'>
                <h1 className='text-primary-evinyGreen2 text-mobileLargeText1 xl:text-largeText1'>
                  <FormattedMessage id='PROJECT.DETAILS.POWER.CALCULATOR' />
                </h1>
              </div>
            </div>
            <h2 className='pt-14 text-h2'>
              <FormattedMessage id='PROJECT.DETAILS.DESCRIPTION.HEADER' />
            </h2>
            <span className='flex items-center pt-3 font-secondaryRegular text-body'>
              <p>
                <FormattedMessage
                  id='PROJECT.DETAILS.DESCRIPTION.FIRST.PARAGRAPH'
                  values={{ projectName: projectInfo[0].projectName }}
                />
              </p>
              <p className='pl-1.5 text-secondary-evinyWarm1'>{maxPotentialEffect?.toFixed()} kW</p>
              <PopOver
                description={
                  <FormattedMessage id='PROJECT.DETAILS.MAX.EFFECT.POPOVER.DESCRIPTION' />
                }
                icon={<BulbIcon />}
                className='relative top-1 ml-1'
              >
                <QuestionIcon
                  className='px-0.5'
                  color={tailwindConfig.theme.extend.colors.other.white}
                />
              </PopOver>
            </span>
            <p className='pt-3 font-secondaryRegular text-body'>
              <FormattedMessage id='PROJECT.DETAILS.DESCRIPTION.SECOND.PARAGRAPH' />
            </p>
          </div>
          {/* Potential effect chart */}
          <div className='mt-14 p-8 min-h-660px bg-other-white border-3 border-primary-evinyGreen3 rounded-3xl'>
            <h2 className='pl-2 pt-2 text-primary-evinyGreen4 text-h2'>
              <FormattedMessage id='PROJECT.DETAILS.POTENTIAL.CHART.HEADER' />
            </h2>
            <p className='mt-1 pl-2 text-primary-evinyGreen4 font-secondaryRegular text-body'>
              <FormattedMessage id='PROJECT.DETAILS.POTENTIAL.CHART.DESCRIPTION' />
            </p>
            <div className='relative mt-6 pl-8 h-3'>
              <p className='absolute text-primary-evinyGreen4 font-secondaryRegular text-body'>
                kW
              </p>
            </div>

            {isMaxPotentialEffectFetching || !chartSeriesData ? (
              <div className='flex justify-center w-full'>
                <Loader
                  type='Oval'
                  color={tailwindConfig.theme.extend.colors.primary.evinyGreen4}
                />
              </div>
            ) : (
              <PotentialEffectChart chartSeriesData={chartSeriesData} />
            )}
          </div>
          <p className='pt-14 whitespace-pre-line'>
            <FormattedMessage id='PROJECT.DETAILS.POTENTIAL.CHART.BELOW.TEXT' />
          </p>

          {!projectInfo.persistenceTime && (
            <>
              <p className='pt-6 whitespace-pre-line'>
                <FormattedMessage id='PROJECT.DETAILS.SEND.REPORT.TEXT' />
              </p>
              <Button
                variant='tertiary'
                onClick={toggleModal}
                className='flex justify-center mt-4 px-14 py-4 text-primary-evinyGreen4 text-h5 bg-primary-evinyGreen1 rounded-3xl transition duration-200 focus:ring-offset-primary-darkBackground'
              >
                <FormattedMessage id='PROJECT.DETAILS.POTENTIAL.CHART.SEND.REPORT.BUTTON' />
              </Button>
            </>
          )}

          <StepGuide />
          <div className='flex justify-between pt-24'>
            {/* Machines quantity box */}
            <div
              className={clsx(
                'flex flex-col items-center p-10 bg-secondary-evinyCold4 rounded-3xl',
                '3xl:p-14'
              )}
            >
              <div
                className={clsx(
                  'max-w-60 flex justify-center p-4 bg-primary-evinyGreen1 rounded-full',
                  '3xl:p-6 3xl:max-w-112'
                )}
              >
                <div
                  className={clsx(
                    'flex flex-col items-center justify-center w-52 h-52 bg-secondary-evinyCold4 rounded-full',
                    '3xl:w-72 3xl:h-72'
                  )}
                >
                  <p
                    className={clsx(
                      'max-w-28 text-center text-primary-evinyGreen1 text-mobileLargeText2',
                      'px-10 max-w-none 3xl:text-largeText2'
                    )}
                  >
                    <FormattedMessage id='PROJECT.DETAILS.MACHINES.QUANTITY' />
                  </p>
                  <span className='flex items-center'>
                    <p className={clsx('text-mobileLargeText1 ', '3xl:text-largeText1')}>
                      {numberOfMachines}
                    </p>
                    <MachineIcon
                      className={clsx(
                        numberOfMachines > 9 ? 'ml-2' : 'ml-4',
                        ' w-16 h-16',
                        '3xl:w-20 3xl:h-20'
                      )}
                    />
                  </span>
                </div>
              </div>
              <span
                className={clsx(
                  ' flex justify-center pt-8 text-center text-other-white font-secondaryRegular text-body',
                  '2xl:text-h3'
                )}
              >
                <p>
                  <FormattedMessage
                    values={{
                      value: <strong className=''>{numberOfElectricMachines}</strong>,
                    }}
                    id='PROJECT.DETAILS.MACHINES.EMISSION.FREE.QUANTITY'
                  />
                </p>
              </span>
            </div>

            {/* Save environment box */}
            <div className='ml-20 p-1 bg-primary-evinyGreen1 rounded-3xl'>
              <div className='flex items-center px-8 py-16 h-full bg-primary-evinyGreen4 rounded-3xl'>
                <SaveEnvironmentIcon className='h-44' />
                <div className='pl-8'>
                  <p className='max-w-sm text-primary-evinyGreen1 font-secondaryRegular text-body'>
                    <FormattedMessage id='PROJECT.DETAILS.SAVE.ENVIRONMENT.DESCRIPTION' />
                  </p>

                  <span
                    className={clsx(
                      'flex items-center pt-8 text-secondary-evinyWarm1 text-mobileLargeText2',
                      '4xl:text-largeText2'
                    )}
                  >
                    <FormattedMessage
                      values={{
                        value: literOfPetrolSaved,
                      }}
                      id='PROJECT.DETAILS.LITER.OF.PETROL.SAVED'
                    />
                  </span>

                  <span
                    className={clsx(
                      'flex items-center pt-8 text-secondary-evinyWarm1 text-mobileLargeText2',
                      '4xl:text-largeText2'
                    )}
                  >
                    <FormattedMessage
                      values={{
                        value: co2Saved,
                        subscript: <sub>2</sub>,
                      }}
                      id='PROJECT.DETAILS.TONS.OF.CO2.SAVED'
                    />
                  </span>

                  <p className='pt-4 max-w-sm text-primary-evinyGreen1 text-h4'>
                    <FormattedMessage id='PROJECT.DETAILS.SAVE.ENVIRONMENT.SUBTITLE' />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <RequestQuoteModal onModalClose={toggleModal} isOpen={isModalOpen} />
    </div>
  );
};
