import { FC } from 'react';

// COMPONENTS
import { LogoLabel } from '../../atoms';

// TRANSLATIONS
import { FormattedMessage } from 'react-intl';

// CONSTANTS
import { MENU_URLS } from '../../../constants';
import ExternalLink, { ExternalLinkProps } from '../../atoms/primitive/ExternalLink';

// ICONS

// UTILS:
import clsx from 'clsx';

// STYLES:
import './header.css';
import Button from 'components/atoms/primitive/Button';

export const Header: FC = () => {
  return (
    <header className='z-103'>
      <div
        style={{ height: '86px' }}
        className={
          'z-100 flex items-center justify-between pl-6 pr-8 w-screen bg-other-white border-b border-primary-evinyGreen4'
        }
      >
        <div>
          <LogoLabel />
        </div>
        <div className='flex items-center'>
          <HeaderLink className='md:block' href={MENU_URLS.PRIVACY_POLICY}>
            <FormattedMessage id='HEADER.MENU.PRIVACY.POLICY' />
          </HeaderLink>
          <HeaderLink className='lg:block' href={MENU_URLS.PRODUCTS_AND_SERVICES}>
            <FormattedMessage id='HEADER.PRODUCTS_AND_SERVICES' />
          </HeaderLink>
          <Button
            variant='primary'
            className='py-0 px-5'
            style={{ lineHeight: '12px' }}
            onClick={(event) => {
              event.preventDefault();
              window.open(MENU_URLS.CONTACT, '_blank');
            }}
          >
            <FormattedMessage id='HEADER.CONTACT' />
          </Button>
        </div>
      </div>
    </header>
  );
};

const HeaderLink = (props: ExternalLinkProps) => {
  return (
    <ExternalLink
      {...props}
      className={clsx('hidden mr-8 text-primary-evinyGreen4 header-link', props.className)}
    />
  );
};
