import { Fragment, SelectHTMLAttributes, FC, useRef } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { ErrorMessage, useFormikContext, getIn } from 'formik';
import { useIntl } from 'react-intl';

// ICONS:
import { DropdownArrowIcon } from '../../../assets/images';

// STYLES:
import './select.css';

// TYPES:
import { SingleDropdownOptionType } from '../../../types';

// HELPERS:
import { getLabelFromId } from '../../../helpers/functions';

// COMPONENTS:
import { Loader } from '../../atoms';

type SelectType = {
  name: string;
  showArrow?: boolean;
  scrollBottom?: boolean;
  containerClassName?: string;
  dropDownClassName?: string;
  activeArrowColor?: string;
  inActiveArrowColor?: string;
  className?: string;
  showError?: boolean;
  options: SingleDropdownOptionType[];
  additionalSideOnChange?: () => void;
  isDataLoading?: boolean;
  placeholder?: string;
} & SelectHTMLAttributes<HTMLSelectElement>;

const checkIfValueInsideOptions = (
  options: SingleDropdownOptionType[],
  value: string | number | readonly string[]
) => {
  return options.find((option) => option?.id === value);
};

const Select: FC<SelectType> = ({
  showArrow = true,
  scrollBottom = false,
  containerClassName,
  dropDownClassName,
  activeArrowColor,
  inActiveArrowColor,
  showError = true,
  options,
  name,
  disabled,
  placeholder,
  className,
  additionalSideOnChange,
  isDataLoading,
}) => {
  const { formatMessage } = useIntl();
  const { setFieldValue, values, setFieldTouched } = useFormikContext<{
    [key: string]: Record<string, unknown>;
  }>();

  const value = getIn(values, name);

  const bottomOfSelectRef = useRef<HTMLDivElement | null>(null);

  const scrollToBottom = () => {
    bottomOfSelectRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const onChange = (option: SingleDropdownOptionType) => {
    additionalSideOnChange && additionalSideOnChange();
    setFieldValue(name, option.id);
    setFieldTouched(name, false);
    if (option.nestedParams) {
      const parentKey = name.split('.')[0];
      if (parentKey === 'equipmentTypeId' || parentKey === 'equipmentModel') {
        for (const optionKey in option.nestedParams) {
          for (const key in values[parentKey]) {
            if (key === optionKey) {
              const finalKey = parentKey + '.' + key;
              setFieldValue(finalKey, option.nestedParams[key]);
            }
          }
        }
      }
    }
  };

  return (
    <Listbox disabled={disabled || isDataLoading} value={value} onChange={onChange}>
      {(list: { open: boolean }) => {
        if (list.open && scrollBottom) scrollToBottom();
        return (
          <div className={clsx('relative', containerClassName)}>
            <Listbox.Button
              className={clsx(
                'select-input border border-primary-evinyGreen4 rounded-lg transition-all',
                disabled ? 'cursor-default opacity-50' : 'hover:opacity-50',
                className
              )}
            >
              {isDataLoading ? (
                <div className='flex justify-center w-full'>
                  <Loader type='ThreeDots' height={40} width={40} />
                </div>
              ) : (
                <div
                  className={clsx(
                    'w-full',
                    !checkIfValueInsideOptions(options, value) && 'text-primary-evinyGreen2 '
                  )}
                >
                  {getLabelFromId(
                    options,
                    value,
                    placeholder || formatMessage({ id: 'SELECT.PLACEHOLDER' })
                  )}
                </div>
              )}
              {showArrow && (
                <DropdownArrowIcon
                  className='mt-1'
                  color={
                    checkIfValueInsideOptions(options, value)
                      ? activeArrowColor
                      : inActiveArrowColor
                  }
                />
              )}
            </Listbox.Button>
            <Transition
              as={Fragment}
              leave='transition ease-in duration-100'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <Listbox.Options
                className={clsx(
                  'absolute z-10 mt-2 w-full bg-other-white border border-primary-evinyGreen4 overflow-auto transition-all',
                  dropDownClassName
                )}
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={String(option?.id)}
                    className={({ active }) =>
                      `${active ? '' : ''}
                          cursor-pointer select-none relative px-4 py-2 transition duraiton-300 hover:bg-primary-evinyGreen4 hover:bg-opacity-25`
                    }
                    value={option}
                  >
                    {({ active }) => (
                      <>
                        <span className={`${active ? '' : ''} block truncate text-left`}>
                          {option?.label}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
            <div ref={bottomOfSelectRef} />
            {showError && (
              <ErrorMessage
                component='div'
                className='absolute bottom-0 left-0 -mb-4 text-other-errorRed text-inputLabel'
                name={name}
              />
            )}
          </div>
        );
      }}
    </Listbox>
  );
};

export default Select;
